body #main #vrtx-main-content .feed-podcast .items li a.item-title {
  background: url('@{imagePath}audio-podcast/play.svg') no-repeat left top;
  background-size: 30px;
  padding-left: 44px;
  margin-bottom: 20px;

  &.pod-open {
    background-image: url('@{imagePath}audio-podcast/close.svg');
  }

  &:not(:hover) {
    text-decoration-color: transparent;
  }
}

.feed-podcast .published-date { margin-top: 10px; }

.feed-podcast .items li .podcastPlayer {
  width: 400px;
  margin: 14px 0px;
}

.vrtx-feed .items li .item-enclosures {
  display: none;
}

/* IE & Edge browsers */
_:-ms-lang(x), .feed-podcast .items li .podcastPlayer { width: auto; }
_:-ms-fullscreen, :root .feed-podcast .items li .podcastPlayer {  width: auto; height: 50px; }

/* Microsoft Edge Browser 12+ (All) - @supports method */
@supports (-ms-ime-align:auto) {
  .feed-podcast .items li .podcastPlayer {
    width: auto;
    height: 50px;
  }
}

/* Microsoft Edge Browser 15+ - @supports method */
@supports (-ms-ime-align:auto)
and (-webkit-text-stroke:initial) {
  .feed-podcast .items li .podcastPlayer {
    width: auto;
    height: 50px;
  }
}

@media @responsiveBreakpoint {
  .not-for-ansatte #main .feed-podcast .items li .podcastPlayer {
    width: 100%;
  }
}
