@import "../components/tooltip.less";

// TODO: need some cleanup

#vrtx-blog-listing #vrtx-additional-content {
  .vrtx-tag-cloud {
    ul {
      margin: 0;
    }
  }
  .comments-title,
  .vrtx-tag-cloud-title {
    .mixin-h2;
    color: @textColor;
    margin-bottom: 20px;
    display: inline-block;
  }
  .vrtx-tag-cloud + .vrtx-recent-comments .comments-title {
    margin-top: 60px;
  }
}

body.blog {
  #main {
    max-width: none;
    min-width: 0;
    padding-left: 0;
    padding-right: 0;

    #right-main,
    #total-main {
      padding-left: 0;
      max-width: none;
      min-width: 0;
    }

    #left-main {
      // browser or less removes 0px and causes max() function to fail, so use tiny value
      // if grid width is less than @gridWidth, sidebar is set to zero left
      left: ~"max(0.01px, calc(calc(calc(100vw - @{gridWidth}) / 2)))";
    }

    #breadcrumbs,
    #vrtx-content,
    #vrtx-content ~ .grid-container .row,
    .grid-container.full-width-picture-bg .row {
      max-width: @gridWidth;
      min-width: @gridMinWidth;
      padding-right: @contentGapRight;
      padding-left: @sidebarWidth;
      margin-left: auto;
      margin-right: auto;
      position: relative;
    }

    .row {
      padding-left: ~"calc(@sidebarWidth + 15px)";
    }

    .grid-container {
      width: 100vw;
      margin-left: ~"calc(calc(calc(100vw - calc(100%) + 17px) / 2) * -1)";
    }
  }

  #breadcrumbs {
    display: none;
  }

  &#vrtx-article-listing,
  &#vrtx-tagview {
    #vrtx-content {
      #vrtx-main-content {
        > * {
          /* TODO: fix how to remove !important here */
          max-width: none !important;
          margin-left: 0 !important;
          margin-right: 0 !important;
        }
      }
    }
  }

  &#vrtx-blog-listing {
    #vrtx-content {
      display: flex;
      flex-direction: row;

      #vrtx-main-content {
        order: 1;
        flex: 3 3 100px;
        padding-right: 100px;

        &,
        > * {
          min-width: 0;
          /* TODO: fix how to remove !important here */
          max-width: none !important;
          margin-left: 0 !important;
          margin-right: 0 !important;
        }
      }
      #vrtx-additional-content {
        order: 2;
        flex: 1 1 100px;
      }
    }
  }

  &.banner-center-fixed .grid-container.full-width-picture-bg {
    background-position: 50% 50% !important; // normal: 0 50%
  }

  .blog-title {
    margin-bottom: 60px;
    padding-bottom: 15px;

    a {
      font-size: 7.4rem;
      line-height: 8.4rem;
      color: #000;
    }
  }

  .vrtx-recent-comments {
    .more-url {
      display: none;
    }
    .published-date {
      display: block;
    }
    .item-description {
      margin-bottom: 15px;
    }
  }

  &#vrtx-tagview {
    .vrtx-resources .vrtx-resource {
      .vrtx-title {
        a {
          color: @textColor;
        }
      }
    }
  }

  &#vrtx-blog-listing {
    h1 {
      display: none;
    }

    .vrtx-resources .vrtx-resource {
      a.vrtx-image {
        float: none;
        width: auto;
        margin-top: 0;
        margin-right: 0;
        margin-bottom: 20px;

        ~ * {
          margin-left: 0;
        }
        + * {
          margin-top: 25px;
        }
      }
      .vrtx-number-of-comments-add-event-container {
        margin: 30px 0;
      }
      .published-date,
      .author-published-byline {
        margin: 20px 0;
      }

      .vrtx-read-more {
        margin-top: 20px;
      }
    }
  }

  #main {
    max-width: none;
    padding-right: 0;
    padding-left: 0;

    .grid-container.blog-about {
      margin-bottom: -105px;
      margin-top: 60px;
      clear: both;
    }

    .grid-container.full-width-picture-bg {
      &:hover {
        cursor: pointer;

        .vrtx-box-content a {
          .textUnderline(@textColorUnderline);
        }
      }
      // Want to keep height
      .vrtx-frontpage-box .vrtx-frontpage-box-picture {
        width: 0;
        padding: 0;
        height: 300px;
      }

      .vrtx-box-content {
        font-size: 5.5rem;
        line-height: 6.5rem;
        font-weight: bold;
        background: none;
        padding: 0;
        margin: 0;
        bottom: 50px;
        position: absolute;

        &,
        a {
          color: #fff;
        }

        a {
          .textUnderline(#fff);

          &:hover,
          &:focus {
            text-decoration: none;
          }
        }
      }
    }

    // main post byline
    h1 + .author-published-byline {
      margin-bottom: 40px;
      margin-top: -40px;
    }

    #vrtx-content {
      .author-published-byline {

        /* TODO: fix how to remove !important here */

        max-width: none !important;
        margin-left: 0 !important;
        margin-right: 0 !important;

        &.has-author-image {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          gap: 10px;

          .author-published {
            flex-basis: 250px; // works like min-width
            flex-grow: 1; // takes up all available width
          }
        }
      }
    }
    .author-image {
      flex-shrink: 0;

      img {
        display: block;
        width: 60px;
        height: 60px;
        object-fit: cover;
        border-radius: 100%;
      }
    }

    .vrtx-authors,
    .vrtx-date-info {
      display: none;
    }

    #vrtx-main-user + .vrtx-date-info + .vrtx-social-components,
    #vrtx-main-user
      + .vrtx-authors
      + .vrtx-date-info
      + .vrtx-social-components {
      margin: 40px 0 0 0;
      padding: 20px 0 0 0;
      border-top: 6px solid #eaeaea;
    }

    .grid-container.blog-recent-posts {
      z-index: 2;
      position: relative;
      margin-top: 40px;

      .vrtx-feed {
        a.item-title {
          font-size: 2rem;
          line-height: 2.8rem;
        }
      }
    }

    .vrtx-tags-scope-up {
      display: none;
    }
  }

  &.banner-title-shadow {
    #main {
      .grid-container.full-width-picture-bg {
        .vrtx-box-content {
          &,
          a {
            text-shadow: 0 2px 25px rgba(25, 25, 25, 0.6);
          }
        }
      }
    }
  }

  &.banner-title-black {
    #main {
      .grid-container.full-width-picture-bg {
        .vrtx-box-content {
          &,
          a {
            color: #000;
          }
          a {
            text-decoration-color: #000;
          }
        }
      }
    }
    &.banner-title-shadow {
      #main {
        .grid-container.full-width-picture-bg {
          .vrtx-box-content {
            &,
            a {
              text-shadow: 0 2px 25px rgba(200, 200, 200, 0.6);
            }
          }
        }
      }
    }
  }

  // Tags, feed

  &#vrtx-tagview .vrtx-resources .vrtx-resource,
  #main .grid-container.blog-recent-posts .vrtx-feed {
    .published-separator {
      display: none;
    }
    .published {
      display: block;
    }
    .tooltip {
      padding: 10px 25px 25px;
    }
  }
}

/* RESPONSIVE */

@media only screen and (max-height: 900px) {
  body.blog.has-banner-lower #main .grid-container.full-width-picture-bg {
    height: 200px;
  }
  body.blog.has-banner-lower #main {
    .content-over-image-left,
    .content-over-image-right {
      &.full-width-picture {
        &,
        &.vrtx-more-false {
          .vrtx-frontpage-box-picture {
            height: 200px;
          }
        }
      }
    }
  }
}

@media @desktopSmallOnlyBreakpoint {
  body.blog #main #breadcrumbs,
  body.blog #main #vrtx-content,
  body.blog #main #vrtx-content ~ .grid-container .row,
  body.blog #main .grid-container.full-width-picture-bg .row {
    min-width: @responsiveBreakpointPx;
  }
}

@media @responsiveBreakpoint {
  body.blog {
    &#vrtx-blog-listing {
      #vrtx-content {
        display: block;

        #vrtx-main-content {
          flex: auto;
          padding-right: 0;
        }
      }
      #main #total-main #vrtx-main-content.vrtx-empty-additional-content,
      #main #total-main #vrtx-main-content.vrtx-hide-additional-content-true,
      #main #total-main #vrtx-main-content:last-child,
      #vrtx-content #vrtx-additional-content {
        width: 100%;
        flex: auto;
      }
    }

    #breadcrumbs,
    #vrtx-content {
      padding-left: 15px;
      padding-right: 15px;
    }

    .tooltip {
      max-width: 90%;
    }

    .blog-title {
      padding-top: 15px;

      a {
        font-size: 5.5rem;
        line-height: 1.2;
      }
    }

    #main {
      #left-main {
        left: 0;
      }

      #breadcrumbs,
      #vrtx-content,
      .row,
      #vrtx-content ~ .grid-container .row,
      .grid-container.full-width-picture-bg .row {
        max-width: none;
        min-width: 0;
        padding-right: 0;
        padding-left: 0;
        margin-left: 0;
        margin-right: 0;
        position: relative;
      }

      #breadcrumbs,
      #vrtx-content {
        padding-left: 15px;
        padding-right: 15px;
      }

      .grid-container {
        width: auto;
        margin-left: 0;
      }
      #right-main,
      #total-main {
        .grid-container.full-width-picture-bg {
          background: none !important;
          height: auto;
          margin-bottom: 50px;
          position: static;

          .vrtx-frontpage-box.vrtx-frontpage-box-picture {
            &.content-over-image-left,
            &.content-over-image-right {
              .vrtx-frontpage-box-picture {
                display: none;
              }
              .vrtx-box-content {
                position: static;
                padding: 15px;
                width: auto;

                &,
                a {
                  color: #000;
                  text-shadow: none;
                  font-weight: normal;
                }
                a {
                  .textUnderline(@textColorUnderline);
                }
              }
            }
          }
        }

        .grid-container.blog-recent-posts {
          .row {
            padding-left: 15px;
            padding-right: 15px;
          }
          .vrtx-feed {
            .author-published-byline {
              &.has-author-image {
                .published {
                  margin-left: 50px;
                }
              }
            }
          }
        }

        // main post byline
        h1 + .author-published-byline {
          margin-top: -15px;
          margin-bottom: 25px;
        }

        #vrtx-additional-content {
          margin-top: 25px;
        }
      }
    }
  }
}

@media print {
  body.blog {
    #vrtx-content {
      overflow: hidden;
    }

    #main .grid-container .row {
      width: 100%;
    }
    #main .grid-container.full-width-picture-bg {
      background: none !important;
      height: auto !important;
      margin-bottom: 40px;

      .vrtx-frontpage-box {
        .vrtx-frontpage-box-picture {
          display: none;
        }

        .vrtx-box-content {
          position: static;

          &,
          a {
            color: #000;
          }
        }
      }
    }

    &#vrtx-structured-article #main #total-main #vrtx-main-content {
      width: 100% !important;
    }

    &#vrtx-structured-article #main #total-main #vrtx-additional-content {
      width: 100% !important;
      float: none !important;
      margin: 40px 0 !important;
    }
  }
}
