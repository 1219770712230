@import "variables.less";

// styles when modal is open
html.travel-report-expanded {
  &, body {
    overflow: hidden;
  }
}

#travel-report-modal {
  &:not([hidden]) {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 10; // adjust when in actual environment
    display: flex;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    @media @screen-medium {
      padding: 5vh 0;
    }
  }
  &.hidden,
  &[hidden] {
    display: none;
  }
  // tab trap
  ._jsTabTrap {
    position: absolute;
    left: -100000px;
  }
  // modal-head + modal-content common styles
  .modal-head,
  .modal-content {
    width: 1200px;
    @media @screen-medium {
      max-width: 90vw;
    }
    @media @screen-below-medium {
      max-width: 100vw;
    }
  }
  // modal-head
  .modal-head {
    position: fixed;
    z-index: 1;
    display: flex;
    justify-content: flex-end;
    .closebtn {
      background-image: url("data:image/svg+xml,%3Csvg width='44' height='44' viewBox='0 0 44 44' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg opacity='0.8'%3E%3Cpath d='M33 11L11 33' stroke='black' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M11 11L33 33' stroke='black' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/g%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-size: contain;
      &:not(:hover) {
        background-color: white;
      }
      border-radius: 100%;
      border: none;
      padding: 0;
      margin: 1.5rem;
      width: 44px;
      height: 44px;
      &::after {
        display: none;
      }
    }
  }
  // modal-content
  .modal-content {
    overflow: auto;
    background-color: #fff;
    box-shadow: 0 2px 10px rgba(0, 0, 0, .2);
    @media @screen-medium {
      padding: 4rem 4rem 8rem;
    }
    @media @screen-below-medium {
      padding: 4rem 5% 8rem;
    }
    // modal-title
    .modal-title {
      line-height: 1.2;
      @media @screen-medium {
        font-size: 5rem;
        margin: 0 0 8rem 0;
      }
      @media @screen-below-medium {
        font-size: 3.6rem;
        margin: 0 0 6rem 0;
      }
      span {
        display: block;
        font-size: 1.8rem;
        line-height: 1.5;
      }
    }
  }
  // hide launch-button in modal
  .launch-modal {
    display: none;
  }
}
