// Breadcrumbs from level 3

#breadcrumbs {
  display: block;

  .vrtx-breadcrumb-level-no-url,
  .vrtx-breadcrumb-level {
    display: inline-block;
    margin-right: 20px;
    margin-bottom: 10px;
  }

  .vrtx-breadcrumb-active,
  .vrtx-breadcrumb-delimiter {
    display: none;
  }

  .vrtx-no-url,
  a {
    .mixin-tiny;
    color: @textColor;
    position: relative;
    display: inline-block;
    opacity: 0.7;
  }

  .vrtx-no-url {
    text-decoration: none;
  }

  a {
    padding-left: 28px;
    &:before {
      content: "";
      background: url("@{imagePath}arrows/arrow-small-left.svg") no-repeat 0 0;
      background-size: 100% auto;
      height: 1.5em;
      width: 1.5em;
      display: inline-block;
      position: absolute;
      left: 0;
      top: 0.15em;
    }
    .textUnderlineSwitch;
  }

  .vrtx-breadcrumb-before-active {
    margin-bottom: 40px;
  }
}
