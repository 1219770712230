@import 'studies/listings.less';
@import 'studies/documents.less';

// Studies context link
@media @responsiveBreakpoint {
  .vrtx-context-box.vrtx-context-box-linked {
    padding-left: 15px;
    padding-right: 15px;
  }
}
