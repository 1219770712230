/*
 * Toggle more content
 * TODO: missing +/- char
 */

.js .toggle-more {
  margin: 15px 0 30px 0;

  > div:first-child,
  > h2,
  > h3,
  > h4,
  > h5,
  > h6 {
    color: @linkColor;
    padding: 0;
    margin: 0;
    cursor: pointer;
    position: relative;

    &:before {
      /*
      background: url('@{imagePath}icon-chevron-up-red.svg') no-repeat center left;
      background-size: auto 100%;*/
      width: 1em;
      height: 1em;
      content: " ";
      position: absolute;
      top: .25ex;
      left: 0px;
      display: inline-block;
    }
    &:focus,
    &:hover {
      .textUnderline(@linkColor);
    }
    a {
      display: inline-block;
      margin-left: 1em;
      padding-left: 10px;
    }
  }
  .toggle-more-content {
    overflow: hidden;
    padding: 0 0 0 30px;
    .transition(.4s, height, ease);

    >*:last-child {
      &,
      >*:last-child {
        margin-bottom: 0;
      }
    }
    >*:last-child {
      margin-bottom: 10px;
    }
  }

  &.toggle-more-active {
    > div:first-child,
    > h2,
    > h3,
    > h4,
    > h5,
    > h6 {
      &:before {
        /*background-image: url('@{imagePath}icon-chevron-down-red.svg');*/
      }
    }
  }
}

.toggle-more-opts {
  display: inline-block;
  padding: 11px 50px 11px 20px;
  margin: 10px 0 25px 0;
  background: #000;
  color: #fff;
  position: relative;

  .textUnderlineSwitch;

  &:after {
    /*
    background: url('@{root}/images/icon-plus-white.svg') no-repeat center left;
    background-size: auto 100%;*/
    font-size: 1.4em;
    width: .8em;
    height: 1em;
    content: "+";
    position: absolute;
    top: 8px;
    right: 10px;
    display: inline-block;
  }
  &.open:after {
    content: "-";
  }
}
.more-options.hidden {
  display: none;
}

.js #vrtx-internal-organizational-unit-subunits.toggle-more {
  padding: 10px 15px;
  margin: 20px 0 40px 0;
  border: 1px solid @borderColor;
}

.row-toggle {
  text-align: center;

  h2 {
    a {
      display: inline-block;

      &:before {
        display: inline-block;
        height: 1em;
        width: 1em;
        padding-right: 10px;
        /*background: url("@{imagePath}icon-chevron-up-red.svg") 0 .2ex no-repeat;
        background-size: auto 100%;*/
        content: " ";
      }
    }
  }
  &.row-toggle-visible {
    h2 a:before {
      /*background-image: url("@{imagePath}icon-chevron-down-red.svg");*/
    }
  }
}

#main #vrtx-main-content .grid-container .row .row-toggle h2 {
  .baseFontSize;
  margin: 0;
  padding: 0 0 20px 0;
}

#main #vrtx-main-content .grid-container.row-all-colored .row .row-toggle h2 {
  padding-bottom: 40px;
}

.row-toggled-hidden {
  display: none;
}

.togglable {
  position: relative;
  padding-left: 30px;
  &:before {
    content: "+";
    font-size: 1.5em;
    width: 0.7em;
    height: 0.7em;
    position: absolute;
    top: -.3ex;
    left: 0;
  }

  &[aria-expanded="true"] {
    &:before {
      content: "-";
    }
  }
}
