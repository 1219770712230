// Fix for old project (seems like same particpant output is used there)
.vrtx-participant-info {
  .vrtx-participant-role,
  .vrtx-participant-affiliation {
    display: none;
  }
}

#vrtx-structured-project-two, #uio-research-group {
  .vrtx-fullsize-image-title {
    position: relative;

    h1 {
      max-width: 750px;
    }
  }

  .vrtx-middle-image,
  .vrtx-fullsize-image {
    img {
      display: block;
    }
  }

  .vrtx-fullsize-image {
    margin-left: -195px;
    max-width: 1400px;

    img {
      max-height: 500px;
      object-fit: cover;
      width: 100%;
    }
  }

  .vrtx-introduction {
    margin-top: 40px;
  }

  .vrtx-middle-image,
  .event-feed,
  .related-groups {
    max-width: 1000px;
  }

  #main h1 {
    position: absolute;
    bottom: 36px; // line-height on .vrtx-title adds 4px extra
    margin: 0;
    line-height: 0; // remove extra space above short-title

    span {
      outline: 6px solid #FFF;
      background-color: #fff;
      position: relative;
      left: 6px;
    }

    .vrtx-short-title {
      font-size: 4rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      outline-color: #000;
      background-color: #000;
      color: #fff;
      display: table;
      margin-bottom: 1rem;
    }
  
    .vrtx-title {
      font-size: 5rem;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      display: inline;
    }
  }

  #main {
    .vrtx-fullsize-image-title:has(.vrtx-imagetext) {
      h1 {
        bottom: 66px;
      }
    }
  }

  #main .navigation-links {
    ul[class*=resultset]:nth-child(n):last-of-type {
      margin: 0 0 -30px 0;
    }
  }

  .event-feed {
    .vrtx-event-component:not(.with-images) {
      .vrtx-event-component-item {
        flex-direction: row;
        display: flex;

        padding-bottom: 20px;
        border-bottom: 1px solid #aaa;
        margin-bottom: 20px;

        div.vrtx-event-component-title,
        a.vrtx-event-component-title {
          text-decoration-color: transparent;
        }

        a.vrtx-event-component-title {
          &:hover {
            text-decoration-color: #000;
            text-decoration: underline;
            text-underline-offset: 0.2em;
            text-decoration-thickness: 0.05em;
          }
        }

        .vrtx-event-component-misc {
          margin-bottom: 0;
        }

        .vrtx-list-item-content {
          margin-left: 0;
        }
    
        .vrtx-date-icon {
          display: block;
          align-self: flex-start;
          background-color: @orange;
          padding: 16px 18px;
          margin-right: 30px;
        }
        .vrtx-date-icon-start {
          display: flex;
          flex-direction: column;
          align-items: center;
          align-self: flex-start;
    
          >*:first-child {
            font-size: 1.8rem;
            font-weight: 600;
            line-height: 125%; /* 22.5px */
          }
          >*:last-child {
            font-size: 1.4rem;
            font-weight: 400;
            line-height: 125%; /* 17.5px */
          }
        }
        .vrtx-date-icon-separator,
        .vrtx-date-icon-end {
          display: none;
        }
      }
      .vrtx-more {
        font-size: 2rem;
        line-height: 3rem;
        margin-top: 40px;
      }
    }
  }

  #vrtx-publications-wrapper,
  .vrtx-article-body {
    max-width: 690px;
  }

  // If h1 is not inside wrapper (image is not big variant)
  #vrtx-main-content > h1 {
    position: static;
    margin-bottom: 40px;

    span {
      position: static;
      outline: none;
    }

    .vrtx-short-title {
      display: inline-block;
      padding: 6px;
    }
    
    .vrtx-title {
      outline: none;
      display: block;
    }
  }

  .project-status-bar {
    margin-top: 40px;
    margin-bottom: 40px;

    display: inline-block;
    padding: 5px 20px;
    border: 1px solid var(--colorNeutralTint80);
    background: #FFF;

    dt,
    dd {
      display: inline-block;
      margin: 0;
    }

    dt {
      font-weight: 700;
      color: var(--colorNeutralTint20);
    }
    dd {
      margin-right: 40px;
      &:last-child {
        margin-right: 0;
      }
    }
  }

  h1 + .project-status-bar { // margins doesnt cancels eachother out (maybe cause short title inside h1 isn't block)
    margin-top: 0px;
  }

  .vrtx-introduction + .vrtx-middle-image {
    margin-top: -25px;
  }

  .news-feed,
  .event-feed,
  .participants,
  .financing,
  .vrtx-person-list-contact-persons,
  .related-groups {
    h2 {
      margin-bottom: 20px;
    }
  }

  #main .navigation-links,
  .additional-finance-info,
  .collaborators,
  .secondary-content,
  .participants,
  .vrtx-person-list-contact-persons,
  .related-groups,
  .news-feed,
  .financing,
  .event-feed,
  #vrtx-publications-wrapper {
    margin-top: 100px;
  }

  .additional-finance-info,
  .collaborators,
  .financing,
  .secondary-content {
    >h2:first-child {
      margin-bottom: 20px;
    }
  }
  
  .collaborators > div,
  .secondary-content > div,
  .financing > div:not(.financing-info-wrapper),
  .financing-info {
    >*:first-child { margin-top: 0; }
    >*:last-child { margin-bottom: 0; }
  }

  .related-groups {
    padding-top: ~"calc(3.6rem + 20px)";
    position: relative;

    h2 {
      margin-top: 0;
    }

    ul, div, li, h2 {
      display: inline;
    }
    ul {
      margin: 0;
      padding-left: 0;
    }
    li {
      display: inline-block;
    }
    h2 {
      position: absolute;
      top: 0;
      left: 0;
    }
    li {
      margin-right: 40px;
    }
    .vrtx-frontpage-box:after,
    .vrtx-frontpage-box .vrtx-box-content:after {
      display: none;
    }
  }
  #main #vrtx-content .related-groups .vrtx-box-content {
    display: inline;
  }

  .vrtx-person-list-contact-persons,
  .participants {
    ul {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 20px;
      padding-left: 0;

      li {
        &:before {
          display: none;
        }
        margin: 0;

        .vrtx-participant-info,
        .vrtx-contact-person-info {
          >* {
            display: block;
          }
        }
        .vrtx-participant-info >*:not(.vrtx-participant-name),
        .vrtx-contact-person-info >*:not(.vrtx-contact-person-name) {
          color: var(--colorNeutralTint20);
          .mixin-small;
        }
      }  
    }
  }

  .vrtx-person-list-contact-persons ul li,
  .participants ul li > div:not(.vrtx-participant-no-image) {
    min-height: 80px;
    display: flex;
    gap: 20px;

    .vrtx-contact-person-picture,
    .vrtx-participant-picture {
      height: 80px;
      width: 80px;
      border-radius: 100px;
      overflow: hidden;
      flex-shrink: 0;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .vrtx-contact-person-picture:not(:has(img)),
    .vrtx-participant-picture:not(:has(img)) {
      background-color: #E6ECFF;
    }
  }

  #vrtx-publications-wrapper .vrtx-tabs>div:not(.more-options-dropdown) {
    padding-bottom: 0;
  }

  .financing {
    > div:not(.financing-info-wrapper) {
      margin-top: 20px;
    }
  }

  .financing-info-wrapper {
    border: 1px solid var(--colorNeutralTint80);
    padding: 40px;

    display: grid;
    grid-template-rows: 1fr;
    gap: 20px;

    img {
      display: block;
      margin-bottom: 20px;
      max-height: 160px;
    }
  }

  .financing-info-project-number span:first-child {
    color: var(--colorNeutralTint20);
  }

}

@media @desktopSmallBreakpoint {
  #vrtx-structured-project-two, #uio-research-group {
    .vrtx-fullsize-image {
      margin-left: 0;
    }

    #main h1 {
      position: static;
      margin-bottom: 40px;

      span {
        position: static;
      }

      .vrtx-short-title {
        font-size: 1.8rem;
        line-height: 125%;
        display: inline-block;
        padding: 6px;
        outline: none;
      }
    
      .vrtx-title {
        font-size: 3.8rem;
        line-height: 4.8rem;
        outline: none;
        display: block;
      }
    }
  }
}

@media @responsiveBreakpoint {
  #vrtx-structured-project-two, #uio-research-group {
    .vrtx-middle-image-wrapper,
    .vrtx-fullsize-image-wrapper {
      height: 375px;
      overflow: hidden;
      
      img {
        display: block;
        max-width: none;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .project-status-bar {
      display: inline-grid;
      grid-template-columns: auto auto;
      gap: 0 10px;
    }

    #main .navigation-links {
      ul[class*=resultset]:nth-child(n):last-of-type {
        margin: 0 0 -10px 0;
      }
    }

    .vrtx-person-list-contact-persons,
    .participants {
      ul {
        grid-template-columns: 1fr;
      }
    }

    .related-groups {
      li {
        display: block
      }
      .vrtx-related-groups + ul li {
        margin-top: 20px;
      }
    }

    #main .navigation-links,
    .additional-finance-info,
    .collaborators,
    .secondary-content,
    .participants,
    .vrtx-person-list-contact-persons,
    .related-groups,
    .news-feed,
    .financing,
    .event-feed,
    #vrtx-publications-wrapper {
      margin-top: 80px;
    }
  }
}