// Jump links
#hidnav {
  margin: 0;
  list-style-type: none;

  li {
    margin: 0;

    a {
      position: absolute;
      left: -9999px;
      top: auto;
      overflow: hidden;
      width: 1px;
      height: 1px;
      z-index: 99999;

      &:focus {
        left: 0px;
        top: 0px;
        overflow: visible;
        width: auto;
        height: auto;
        padding: 0.5em 1em;
        font-size: 1.15em;
        background-color: #fff;
        color: #000;
        text-decoration: none;
      }
    }
  }
}
