@import "./exchange/travel-report.less";

@exchangeInfoBoxBg: #18191c;
@exchangeInfoBoxColor: #fff;

#vrtx-student-exchange-agreement-info {
  max-width: @h1IntroWidth;
  margin-bottom: 15px;
  padding: 8px 14px 7px 14px;
  border: solid 1px @borderColor;
  display: inline-block;

  h2 {
    display: none;
  }

  * {
    .mixin-small;
    letter-spacing: 0.6px;
    display: inline-block;
    margin: 0;
  }

  h3 {
    font-weight: bold;
    &:after {
      content: ":";
    }
  }
  p {
    font-weight: normal;
    margin-left: .25em;
    margin-right: 15px;
  }
}

.vrtx-student-exchange-agreement-prefix {
  .mixin-h4;
  display: block;
  margin-bottom: 5px;
}

#vrtx-student-exchange-agreement-university-info {
  background: @exchangeInfoBoxBg;
  padding: 0 60px 55px 60px;
  max-width: @h1IntroWidth;

  img {
    width: ~"calc(100% + 120px)";
    margin: 0 0 0 -60px;
    max-width: none;
  }

  h2 {
    margin-top: 55px;
    margin-bottom: 20px;
  }

  *,
  a {
    color: @exchangeInfoBoxColor;
  }

  h3,
  h3 + p {
    display: block;
    margin: 0;
  }

  h3 {
    font-size: 19px;
    line-height: 2.9rem;
    letter-spacing: normal;
    font-weight: bold;
    float: left;
    clear: both;
    &:after {
      content: ":";
    }
    + p {
      float: left;
      font-weight: normal;
      margin-left: .25em;
    }
  }
  hr {
    display: block;
    width: 100%;
    clear: both;
    margin-bottom: 30px;
    border-color: #fff;
  }
  > a {
    display: block;
    clear: both;
  }
}

#vrtx-student-exchange-agreement {
  .vrtx-social-components {
    margin-bottom: 60px;
  }
}

@media @responsiveBreakpoint {

  .vrtx-student-exchange-agreement-prefix {

  }

  #vrtx-student-exchange-agreement-info {
    padding: 5px 10px;
  }

  #main #right-main,
  #main #total-main {
    #vrtx-student-exchange-agreement-university-info {
      width: ~"calc(100vw - 15px)";
      margin-left: -15px;
      margin-right: 0;
      padding: 0 15px 35px;

      h2 {
        margin-top: 30px;
      }

      h3 {
        font-size: 17px;
        line-height: 2.7rem;
      }

      img {
        width: ~"calc(100vw - 15px)";
        margin-left: -15px;
      }
    }
  }
}
