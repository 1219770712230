.vrtx-editor-frontpage {
  #head-wrapper,
  #bottomnav,
  #footer-wrapper,
  #footer-wrapper-back-to-uio {
    opacity: 0.4;
  }

  &#vrtx-frontpage #main #total-main.uio-main .vrtx-edit-grid .vrtx-edit-title {
    margin-left: auto;
    margin-right: auto;
    width: 1050px;
    padding-left: 40px;
    padding-right: 40px;
  }

  &#vrtx-frontpage #main #total-main.uio-main .vrtx-edit-grid #vrtx-frontpage-introduction {
    margin-left: auto;
    margin-right: auto;
    width: 1050px;
    padding-left: 40px;
    padding-right: 40px;
  }

  &#vrtx-frontpage #main #total-main.uio-main .vrtx-edit-grid .vrtx-edit-row {
    margin-left: auto;
    margin-right: auto;
    /* 970+40+40. Expand because of editing padding,
       so that rows/boxes has more view-like width */
    width: 1050px;
  }

  &#vrtx-frontpage #main #total-main.uio-main #breadcrumbs {
    margin-left: auto;
    margin-right: auto;
    width: 1050px;
    padding-left: 40px;
    padding-right: 40px;
  }

  &#vrtx-frontpage #main .sidebar {
    display: none;
  }

  &#vrtx-frontpage #main #total-main.uio-main .vrtx-edit-grid .grid-container {
    margin-top: 0;
    margin-bottom: 0;
    height: auto;
    top: 0;
  }

  &#vrtx-frontpage.right-main #globalnav ul,
  &#vrtx-frontpage.right-main #main,
  &#vrtx-frontpage.right-main #head-wrapper #head {
    /* 970 + 30 */
    width: 1000px;
  }

  &#vrtx-frontpage #main #left-main {
    opacity: 0.4;
    overflow: hidden;
    /* 190-40-40-50+10 */
    width: 100px;
    display: none;
    float: left;
    margin: 0;
  }
  &#vrtx-frontpage #main #left-main.hidden {
    display: none;
  }
  &#vrtx-frontpage #main #right-main {
    margin: 0;
    /* 740+50+40+40 */
    width: 870px;
    min-width: 870px;
    box-shadow: -10px 0 5px -5px #888;
    padding-top: 10px;
    padding-left: 0;
    position: relative;
    bottom: 10px;
    right: 30px;
    float: right;
  }
  &#vrtx-frontpage #main #left-main.hidden-content ~ #right-main {
    box-shadow: none;
  }

  &#vrtx-frontpage #main #right-main #vrtx-content,
  &#vrtx-frontpage #main #right-main #vrtx-main-content {
    /* 740+50+40+40 */
    width: 870px;
  }

  &#vrtx-frontpage #main #right-main #breadcrumbs {
    width: 820px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 40px;
    padding-right: 40px;
  }

  &#vrtx-frontpage #main #right-main .vrtx-edit-grid .vrtx-edit-title {
    /* 740+40+40 */
    width: 820px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 40px;
    padding-right: 40px;
  }

  &#vrtx-frontpage #main #right-main .vrtx-edit-grid #vrtx-frontpage-introduction {
    /* 740+40+40 */
    width: 820px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 40px;
    padding-right: 40px;
  }

  &#vrtx-frontpage #main #right-main .vrtx-edit-grid .vrtx-edit-row {
    /* 740+40+40 */
    width: 820px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 40px;
    padding-right: 40px;
  }

  &#vrtx-frontpage #main {
    width: 970px;
  }
  &#vrtx-frontpage.vrtx-frontpage-full-width.total-main #main {
    width: 100%;
  }

  #right-main .vrtx-edit-grid .vrtx-edit-row {
    margin-right: 0;
  }
  /*
  Not needed in new design

  &#vrtx-frontpage #main .vrtx-edit-grid .vrtx-edit-row .vrtx-edit-box.vrtx-frontpage-box > :first-child {
    padding-top: 0;
  }
  &#vrtx-frontpage #main .vrtx-edit-grid .vrtx-edit-row .vrtx-edit-box.vrtx-frontpage-box > :last-child {
    padding-bottom: 10px;
  }
  */

}
