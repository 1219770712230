.vrtx-contact-supervisor-box {
  padding-bottom: 40px;
  border-bottom: 1px solid #000;
  margin-bottom: 40px;

  .vrtx-contact-supervisor-description,
  .vrtx-contact-supervisor-contact-info {
    float: left;
    width: ~"calc(100% / 2)";
  }
  .vrtx-contact-supervisor-description {
    padding-right: 80px;
    position: relative;

    &:after {
      content: "";
      background: url("@{imagePath}arrows/arrow-big.svg") no-repeat 0 0;
      background-size: 60px auto;
      width: 60px;
      height: 40px;
      position: absolute;
      top: 50%;
      left: ~"calc(100% - 40px)";
    }
  }
  .vrtx-contact-supervisor-contact-info {
    padding-left: 80px;
  }

  &:after {
    .clearfix();
  }
}


@media @responsiveBreakpoint {

  .vrtx-contact-supervisor-box {
    .vrtx-contact-supervisor-description,
    .vrtx-contact-supervisor-contact-info {
      float: none;
      width: auto;
      padding-left: 0;
      padding-right: 0;
    }
    .vrtx-contact-supervisor-description {
      padding-bottom: 80px;
      &:after {
        .transform(rotate(90deg));
        top: ~"calc(100% - 60px)";
        left: ~"calc(50% - 40px)";
      }
    }
  }
}
