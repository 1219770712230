.vrtx-courseplan-table {
  width: 100%;

  td.small {
    white-space: nowrap;
    width: 10%;
  }
  td.large {
    width: 30%;
  }
}

.vrtx-courseplan-common         { background-color: #F9F9F9; }
.vrtx-courseplan-specialization { background-color: #F5F2DF; }
.vrtx-courseplan-optional       { background-color: #C8DEB1; }
.vrtx-courseplan-thesis         { background-color: #D0EAFC; }
