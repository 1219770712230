div[data-puzzel-chat] h1,
#vrtx-frontpage div[data-puzzel-chat] h1 {
  /*font-size: 24px;
  line-height: inherit;*/
  font-size: 24px;
  line-height: unset;
  margin-bottom: unset;
  color: unset;
}

div[data-puzzel-chat] h2,
#vrtx-frontpage div[data-puzzel-chat] h2 {
  font-size: 16px;
  line-height: inherit;
}

div[data-view='bubble'] {
  display: none;
}

div[data-puzzel-chat] button {
  text-align: center;
}

div[data-puzzel-chat] form button {
  width: auto;
}

div[data-puzzel-chat] input[type=email] {
  font-size: unset;
  max-width: none;
}

[data-puzzel-chat] {
  font-family: 'Open Sans', sans-serif;
  z-index: 9999;
}


body .puzzle-chat-closed,
body .puzzle-chat-open {
  position: fixed;
  top: calc(100% - 100px);
  right: 20px;
  height: 50px;
  width: 150px;
  max-width: none;
  padding: 0;
  border: none;
  z-index: 1;
}

body .contactinfo-puzzle-chat-closed .icon,
body .contactinfo-open-puzzle-chat .icon,
body .puzzle-chat-closed .icon,
body .puzzle-chat-open .icon {
  display: block;
  width: 50px;
  height: 50px;
  background: url("/vrtx/dist/resources/uio2/css/images/chatbot/chatbot.svg") no-repeat 0 0;
  background-size: auto 100%;
  position: absolute;
  top: 0;
  right: 0;
}

body .contactinfo-open-puzzle-chat:focus,
body .contactinfo-open-puzzle-chat:hover,
body .puzzle-chat-open:focus,
body .puzzle-chat-open:hover {
    background: transparent;
  }

body .contactinfo-puzzle-chat-closed .icon:before,
body .contactinfo-open-puzzle-chat .icon:before,
body .puzzle-chat-closed .icon:before,
body .puzzle-chat-open .icon:before {
    display: none;
  }

.contactinfo-puzzle-chat-closed .text,
.contactinfo-open-puzzle-chat .text,
.puzzle-chat-closed .text,
.puzzle-chat-open .text {
  background: #fff;
  display: block;
  padding: 9px 15px;
  margin-right: -1px;
  margin-top: -1px;
  font-size: 1.6rem;
  border: 1px solid #555;
  border-radius: 50px;
}

.contactinfo-puzzle-chat-closed,
.contactinfo-open-puzzle-chat {
  height: 50px;
  width: 150px;
  max-width: none;
  padding: 0;
  border: none;
}

body .contactinfo-puzzle-chat-closed,
body .puzzle-chat-closed {
  display: inline-block;
  font-size: 2.2rem;
  line-height: 3.1rem;
  width: 160px;
}
body .contactinfo-puzzle-chat-closed .icon,
body .puzzle-chat-closed .icon {
  background-image: url("/vrtx/dist/resources/uio2/css/images/chatbot/chatbot-closed.svg");
}

body .contactinfo-puzzle-chat-closed {
  position: relative;
}
body .contactinfo-puzzle-chat-closed .text {
  padding-right: 60px;
}
