#vrtx-frontpage {
  .feature-img-left  .vrtx-featured-item-1,
  .feature-img-right  .vrtx-featured-item-1,
  .feature-full-width  .vrtx-featured-item-1,
  .content-over-image-left,
  .content-over-image-right,
  .featured-one-big-two-column .vrtx-featured-item-1 {
    h2 {
      font-size: 5.5rem;
      line-height: 6.5rem;
    }
  }
}

// Default feature, img left or right
.feature-img-left .vrtx-featured-item-1,
.feature-img-right .vrtx-featured-item-1,
.content-over-image-left,
.content-over-image-right,
.featured-one-big-two-column .vrtx-featured-item-1 {
  .vrtx-featured-item-picture,
  &.vrtx-frontpage-box-picture .vrtx-frontpage-box-picture {
    float: left;
    width: 50%;
    padding-right: 40px;

    img {
      width: 100%;
    }
  }
  h2 {
    a {
      .textUnderlineSwitch;
    }
    margin-top: 30px;
    margin-bottom: 60px;
  }
  .vrtx-box-sub-content >*:first-child {
    margin-top: 0;
  }
  .vrtx-box-content {
    font-size: 2.8rem;
    line-height: 1.5;
    padding-left: 40px;
  }
  .vrtx-date,
  .vrtx-sticktitle {
    font-size : 1.7rem;
    line-height: 2.3rem;
    color: #333;
    margin: 0 0 5px 0;

    + h2 {
      margin-top: 0;
    }
  }

  // Order
  .vrtx-box-content {
    display: flex;
    flex-direction: column;

    .vrtx-sticktitle {
      order: 1;
    }
    .vrtx-date {
      order: 2;
    }
    h2 {
      order: 3;
    }
    .vrtx-box-sub-content {
      order: 4;
    }
  }
  .vrtx-more {
    display: flex;
    padding-left: 40px;
  }
}

// img right
.feature-img-right .vrtx-featured-item-1,
.featured-big-box-content-right .vrtx-featured-item-1,
.content-over-image-right {
  .vrtx-featured-item-picture,
  &.vrtx-frontpage-box-picture .vrtx-frontpage-box-picture {
    float: right;
    padding-left: 40px;
    padding-right: 0;
  }
  .vrtx-box-content {
    padding-left: 0;
    padding-right: 40px;
  }
}

// full width img
#main .feature-full-width .vrtx-featured-item-1 {
  position: relative;
  float: none;
  padding: 0;
  width: ~"calc(100% - 20px)";

  &.vrtx-frontpage-box-picture .vrtx-frontpage-box-picture {
    float: none;
    margin: 0;
    // line-height: 0;

    img {
      display: block; // removed bottom spacing (previously fixed by line-height)
      width: 100%;
    }
  }
  .vrtx-featured-item-picture {
    a {
      display: block;
      // line-height: 0;
    }

    img {
      display: block; // removed bottom spacing (previously fixed by line-height)
      width: 100%;
      object-fit: cover;
      max-height: 800px;
    }
  }
  h2 {
    a {
      color: @textColor;
    }
    margin-bottom: 50px;
  }
  .vrtx-box-sub-content >*:first-child {
    margin-top: 0;
  }
  .vrtx-box-content {
    font-size: 2.8rem;
    line-height: 3.6rem;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 15% 50px 15% 0;
    width: auto;
    height: 100%;
  }
  .vrtx-date {
    font-size : 1.7rem;
    line-height: 2.3rem;
    color: #333;
    margin: 0 auto 20px auto;
  }
  .vrtx-box-content h2,
  .vrtx-date,
  .vrtx-box-sub-content {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }

  .vrtx-box-sub-content {
    width: 80%;
  }

  .vrtx-date,
  .vrtx-box-content h2,
  .vrtx-box-sub-content * {
    background: #fff;
  }

  // Order
  .vrtx-box-content {
    display: flex;
    flex-direction: column;

    .vrtx-date {
      order: 1;
    }
    h2 {
      order: 2;
    }
    .vrtx-box-sub-content {
      order: 3;
    }
  }
}

#vrtx-frontpage #main .feature-full-width-grid-container {
  .vrtx-frontpage-box {
    > h2,
    > .vrtx-box-content >*:not(:first-child),
    .vrtx-more,
    .featured-row {
      /*
      margin-left: 200px;
      max-width: 1270px;
      min-width: 860px;
      padding-right: 50px;
      */
    }
    .vrtx-featured-item-1 .vrtx-featured-item-picture {
      width: 100vw;
      margin-left: ~"min(-200px, calc(calc(calc(100vw - calc(100%) + 195px)/ 2) * -1))";
      min-width: 1050px;
    }
    .featured-row {
      &:before,
      &:after {
        .clearfix();
      }
    }
  }
}

/* Featured small items */
#main .featured-one-big-two-column,
#main .feature-img-left,
#main .feature-img-right,
#main .feature-full-width {
  &.hide-featured-after-first .featured-row .vrtx-featured-item:nth-child(n+1) { display: none; }
  &.hide-featured-after-third .featured-row .vrtx-featured-item:nth-child(n+3) { display: none; }
  &.hide-featured-after-fifth .featured-row .vrtx-featured-item:nth-child(n+5) { display: none; }

  .featured-row {
    clear: both;

    &:after {
      .clearfix();
    }

    .vrtx-featured-item {
      margin-top: 60px;

      .vrtx-box-content {

        .vrtx-date,
        .vrtx-sticktitle {
          color: #333;
          margin-bottom: 5px;
        }
        h2 {
          margin: 0;
          font-size: 3.1rem;
          line-height: 3.9rem;

          a {
            .textUnderlineSwitch;
          }
        }
      }

      .vrtx-date {
        .baseFontSize;
        color: #333;
        margin-bottom: 10px;
      }

      &.vrtx-featured-item-picture {
        .vrtx-featured-item-picture {
          margin-bottom: 20px;
        }
      }
      .vrtx-more {
        display: none;
      }
    }
  }

  &.featured-one-big-three-column {
    .featured-row .vrtx-featured-item {
      .vrtx-box-content {
        h2 {
          font-size: 1.8rem;
          line-height: 2.9rem;
          margin-bottom: 0;
        }

        .vrtx-box-sub-content {
          display: none;
        }
      }
    }
  }
}

// Special feature class (not sure if will remain in new design)
.bigger-box-content-text {
  clear: both;
  padding-top: 40px;
  border-top: 1px solid @borderColor;
  margin-top: 40px;
  font-size: 2.3rem;
  line-height: 3.1rem;
  font-family: @secondaryFontStack;

  *:first-child {
    margin-top: 0;
  }
}

@media only screen and (max-width: 1260px) {
  #vrtx-frontpage .content-over-image-left h2,
  #vrtx-frontpage .content-over-image-right h2,
  #vrtx-frontpage .feature-full-width .vrtx-featured-item-1 h2,
  #vrtx-frontpage .feature-img-left .vrtx-featured-item-1 h2,
  #vrtx-frontpage .feature-img-right .vrtx-featured-item-1 h2,
  #vrtx-frontpage .featured-one-big-two-column .vrtx-featured-item-1 h2 {
    font-size: 4.2rem;
    line-height: 5.4rem;
  }
  .content-over-image-left .vrtx-box-content,
  .content-over-image-right .vrtx-box-content,
  .feature-img-left .vrtx-featured-item-1 .vrtx-box-content,
  .feature-img-right .vrtx-featured-item-1 .vrtx-box-content,
  .featured-one-big-two-column .vrtx-featured-item-1 .vrtx-box-content {
    font-size: 2.3rem;
    line-height: 3.1rem;
  }
}

@media @desktopSmallBreakpoint {
  #vrtx-frontpage {
    #main {
      .vrtx-frontpage-box {
        &.feature-img-left,
        &.feature-img-right,
        &.feature-full-width,
        &.content-over-image-left,
        &.content-over-image-right,
        &.featured-one-big-two-column {
          .vrtx-featured-item {
            .featured-row {
              h2 {
                font-size: 2.2rem;
                line-height: 2.9rem;
              }
            }
          }
        }
        &.feature-full-width .vrtx-featured-item-1,
        &.feature-img-left .vrtx-featured-item-1,
        &.feature-img-right .vrtx-featured-item-1,
        &.content-over-image-left,
        &.content-over-image-right,
        &.featured-one-big-two-column .vrtx-featured-item-1 {
          .vrtx-box-content {
            font-size: 2.4rem;
            line-height: 3.2rem;

            h2 {
              font-size: 42px;
              line-height: 54px;
            }
          }
        }
      }
    }
  }
}

@media @responsiveBreakpoint {

  #vrtx-frontpage {
    #main {
      .content-over-image-left,
      .content-over-image-right,
      .feature-img-left .vrtx-featured-item-1,
      .feature-img-right .vrtx-featured-item-1,
      .featured-one-big-two-column .vrtx-featured-item-1 {
        margin-left: 0;
      }
      .feature-img-left .vrtx-featured-item-1,
      .feature-img-right .vrtx-featured-item-1,
      .featured-one-big-two-column .vrtx-featured-item-1 {
        margin-bottom: 40px;
      }

      .content-over-image-left,
      .content-over-image-right,
      .feature-img-left .vrtx-featured-item-1,
      .feature-img-right .vrtx-featured-item-1,
      .feature-full-width .vrtx-featured-item-1,
      .featured-one-big-two-column .vrtx-featured-item-1 {
        .vrtx-box-content {
          display: flex;
        }
        .vrtx-more {
          padding-left: 15px;
        }
      }

      .feature .row .vrtx-frontpage-box .featured-row,
      .feature .row .vrtx-frontpage-box .vrtx-more,
      .feature .row .vrtx-frontpage-box .vrtx-box-content,
      .feature .row .vrtx-frontpage-box>.vrtx-box-content>:not(:first-child),
      .feature .row .vrtx-frontpage-box>h2 {
        width: auto;
        margin-left: 0;
        max-width: none;
        min-width: 0;
        padding-right: 0;
      }

      .vrtx-frontpage-box {
        &.feature-img-left,
        &.feature-img-right,
        &.feature-full-width,
        &.content-over-image-left,
        &.content-over-image-right,
        &.featured-one-big-two-column {
          padding-left: 0;
          padding-right: 0;

          > h2,
          .vrtx-more {
            padding-left: 15px;
            padding-right: 15px;
          }

          .vrtx-featured-item {
            padding: 0 15px;
            margin-top: 20px;

            &:first-child {
              margin-top: 0;
            }

            .featured-row {
              h2 {
                font-size: 2.2rem;
                line-height: 2.9rem;
              }
            }
          }

          > .vrtx-box-content {
            >*:not(:first-child) {
              padding-left: 15px;
              padding-right: 15px;
            }
            >*:nth-child(2) {
              margin-top: 20px;
            }
          }
        }

        &.feature-full-width .vrtx-featured-item-1,
        &.feature-img-left .vrtx-featured-item-1,
        &.feature-img-right .vrtx-featured-item-1,
        &.content-over-image-left,
        &.content-over-image-right,
        &.featured-one-big-two-column .vrtx-featured-item-1 {
           padding-left: 0;
           padding-right: 0;

          .vrtx-frontpage-box-picture,
          .vrtx-featured-item-picture {
            width: 100%;
            padding-right: 0;

            img {
              width: 100%;
            }
          }
          .vrtx-box-content {
            background: transparent;
            position: static;
            clear: left;
            width: auto;
            padding-left: 15px;
            padding-right: 15px;
            font-size: 2.4rem;
            line-height: 3.2rem;

            h2 {
              font-size: 42px;
              line-height: 54px;
            }
          }
        }

        &.feature-img-left,
        &.feature-img-right,
        &.content-over-image-left,
        &.content-over-image-right,
        &.featured-one-big-two-column{
          > h2 {
            margin-bottom: 15px;
          }
        }

        &.feature-img-left .vrtx-featured-item-1,
        &.feature-img-right .vrtx-featured-item-1,
        &.content-over-image-left,
        &.content-over-image-right,
        &.featured-one-big-two-column .vrtx-featured-item-1 {
          .vrtx-box-content {
            margin-top: 40px;
          }
          .vrtx-featured-item-picture {
            float: none;
            margin: 0;
            padding: 0;
          }
        }

        &.feature-full-width .vrtx-featured-item-1 {
          .vrtx-featured-item-picture {
            margin-left: 0;
            min-width: 0;
          }
          .vrtx-box-content {
            background: transparent;
            position: static;
            clear: left;
            width: auto;
            padding: 20px 15px 60px;

            .vrtx-date,
            h2,
            .vrtx-box-sub-content {
              background: transparent;
              margin: 0;
              text-align: left;
            }
            .vrtx-date + h2 {
              margin-top: 10px;
            }
            .vrtx-box-sub-content {
              margin-top: 10px;
            }
            .vrtx-box-sub-content p {
              background: transparent;
            }
          }
        }

        &.feature-full-width
        &.content-over-image-left,
        &.content-over-image-right,
        &.featured-one-big-two-column {
          &:first-child {
            margin-top: -15px;
          }
        }

        &.feature-full-width .vrtx-box-content,
        &.content-over-image-left .vrtx-box-content,
        &.content-over-image-right .vrtx-box-content,
        &.featured-one-big-two-column .vrtx-featured-item-1:last-child .vrtx-box-content {
          > h2 {
            margin-bottom: 0;
          }
        }

        &:not(.featured-one-big-two-column) .vrtx-featured-item:only-child {
          .vrtx-featured-item-picture ~ * {
            margin-left: 0;
          }
        }
      }
    }
  }
}