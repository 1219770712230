#main .vrtx-listing-filter-custom {
  li.vrtx-listing-filter-parameter {
    &:first-child {
      margin-bottom: 15px;
    }
  }
  margin-bottom: 15px;
}

#main .vrtx-listing-filter-custom-horizontal {
  li.vrtx-listing-filter-parameter {
    display: inline-block;
    margin-right: 20px;

    a {
      padding-left: 0;
    }

    &:first-child {
      margin-right: 40px;
      margin-bottom: 5px;
    }
  }
}

#main .vrtx-listing-filter-custom-status {
  margin: 0 0 -40px;
}
