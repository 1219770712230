// Not sure where to place this yet, depends on usage..
.media-portrait-right,
.media-portrait-left {
  display: grid;
  grid-template-columns: max-content auto;
  gap: 60px;
  max-width: 1000px;
  align-items: center;
  justify-items: start;

  img {
    max-width: 315px;
  }
  .vrtx-media-player {
    margin: 0;
  }
  blockquote {
    margin: 0;
    padding: 0;
  }
  .quote-cite {
    .mixin-h4;
    font-family: @mainFontStack;
  }
}

.media-portrait-right {
  grid-template-columns: auto max-content;
}
.media-portrait-right .vrtx-media-player {
  order: 1;
}
  
@media @responsiveBreakpoint {
  .media-portrait-left,
  .media-portrait-right {
    grid-template-columns: auto;
    gap: 20px;
    align-items: center;
    justify-items: left;

    img {
      max-width: 100%;
    }
  }
  .media-portrait-right .vrtx-media-player {
    order: 0;
  }
}

// -----

h1 {
  max-width: @h1IntroWidth;
}

.vrtx-introduction,
#vrtx-frontpage #vrtx-frontpage-introduction,
#vrtx-frontpage .vrtx-introduction,
.display-as-introduction p,
.vrtx-organizational-unit-introduction {
  .mixin-introduction;
  max-width: @h1IntroWidth;
}

// Introduction image text
.vrtx-imagetext {
  max-width: @h1IntroWidth;
}

.vrtx-introduction,
#vrtx-frontpage #vrtx-frontpage-introduction,
#vrtx-frontpage .vrtx-introduction,
.vrtx-organizational-unit-introduction,
.vrtx-introduction-image {
  margin-bottom: 75px;
}

.vrtx-organizational-unit-introduction,
.vrtx-introduction {
  p:first-child { margin-top: 0;    }
  p:last-child  { margin-bottom: 0; }
}

.vrtx-introduction + .vrtx-introduction-image:not(.small-intro-image),
.vrtx-introduction-image + .vrtx-introduction {
  margin-top: -30px;
}

.vrtx-byline {
  .mixin-h4;
  margin-top: 30px;
  margin-bottom: 50px;

  abbr {
    text-decoration: none;
  }

  .vrtx-number-of-comments-add-event-container {
    .baseFontSize;
    margin-top: 5px;
  }
}

.vrtx-introduction + .vrtx-byline {
  margin-top: -50px;
}

.vrtx-authors,
.vrtx-date-info {
  clear: both;
  margin-top: 60px;
  border-top: 1px solid #000;
  padding-top: 40px;
  color: #434344;
}

.vrtx-authors {
  display: block;
}

.vrtx-authors + .vrtx-date-info {
  margin-top: 10px;
  padding-top: 0;
  border-top: none;
}

.vrtx-tags {
  display: block;
  margin: 40px 0;
  clear: left;
}

// When unpublished resource
p + .vrtx-social-components {
  margin-top: 60px;
}

// Responsive

@media @responsiveBreakpoint {

  .vrtx-introduction,
  #vrtx-frontpage #vrtx-frontpage-introduction,
  #vrtx-frontpage .vrtx-introduction,
  .vrtx-organizational-unit-introduction,
  .vrtx-introduction-image {
    margin-bottom: 40px;
  }

  .vrtx-introduction + .vrtx-introduction-image:not(.small-intro-image),
  .vrtx-introduction-image + .vrtx-introduction {
    margin-top: -10px;
  }

  .vrtx-introduction+.vrtx-byline {
    margin-top: -10px;
  }

  h1+.vrtx-introduction-image {
    margin-top: 0;
  }
  .vrtx-introduction-image {
    max-width: 100% !important;  // override js intro scaling based on width

    &:not(.small-intro-image) {
      margin-left: 0;
    }
  }
}
