@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 300;
  font-stretch: 100%;
  src: url("@{fontPath}opensans/v27/memtYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWqWuU6F.woff2") format('woff2');
}
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  src: url("@{fontPath}opensans/v27/memtYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWqWuU6F.woff2") format('woff2');
}
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  src: url("@{fontPath}opensans/v27/memtYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWqWuU6F.woff2") format('woff2');
}
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  src: url("@{fontPath}opensans/v27/memtYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWqWuU6F.woff2") format('woff2');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url("@{fontPath}opensans/v27/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS-muw.woff2") format('woff2');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("@{fontPath}opensans/v27/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS-muw.woff2") format('woff2');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("@{fontPath}opensans/v27/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS-muw.woff2") format('woff2');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("@{fontPath}opensans/v27/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS-muw.woff2") format('woff2');
}

@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 100;
  src: url("@{fontPath}lato/v22/S6u-w4BMUTPHjxsIPx-oPCI.woff2") format('woff2');
}
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 300;
  src: url("@{fontPath}lato/v22/S6u_w4BMUTPHjxsI9w2_Gwft.woff2") format('woff2');
}
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 400;
  src: url("@{fontPath}lato/v22/S6u8w4BMUTPHjxsAXC-q.woff2") format('woff2');
}
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 700;
  src: url("@{fontPath}lato/v22/S6u_w4BMUTPHjxsI5wq_Gwft.woff2") format('woff2');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 100;
  src: url("@{fontPath}lato/v22/S6u8w4BMUTPHh30AXC-q.woff2") format('woff2');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("@{fontPath}lato/v22/S6u9w4BMUTPHh7USSwiPGQ.woff2") format('woff2');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("@{fontPath}lato/v22/S6uyw4BMUTPHjx4wXg.woff2") format('woff2');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("@{fontPath}lato/v22/S6u9w4BMUTPHh6UVSwiPGQ.woff2") format('woff2');
}

@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 100;
  src: url("@{fontPath}raleway/v26/1Ptsg8zYS_SKggPNyCg4TYFq.woff2") format('woff2');
}
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 200;
  src: url("@{fontPath}raleway/v26/1Ptsg8zYS_SKggPNyCg4TYFq.woff2") format('woff2');
}
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 300;
  src: url("@{fontPath}raleway/v26/1Ptsg8zYS_SKggPNyCg4TYFq.woff2") format('woff2');
}
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 400;
  src: url("@{fontPath}raleway/v26/1Ptsg8zYS_SKggPNyCg4TYFq.woff2") format('woff2');
}
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 600;
  src: url("@{fontPath}raleway/v26/1Ptsg8zYS_SKggPNyCg4TYFq.woff2") format('woff2');
}
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 700;
  src: url("@{fontPath}raleway/v26/1Ptsg8zYS_SKggPNyCg4TYFq.woff2") format('woff2');
}
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 100;
  src: url("@{fontPath}raleway/v26/1Ptug8zYS_SKggPNyC0ITw.woff2") format('woff2');
}
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 200;
  src: url("@{fontPath}raleway/v26/1Ptug8zYS_SKggPNyC0ITw.woff2") format('woff2');
}
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 300;
  src: url("@{fontPath}raleway/v26/1Ptug8zYS_SKggPNyC0ITw.woff2") format('woff2');
}
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  src: url("@{fontPath}raleway/v26/1Ptug8zYS_SKggPNyC0ITw.woff2") format('woff2');
}
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  src: url("@{fontPath}raleway/v26/1Ptug8zYS_SKggPNyC0ITw.woff2") format('woff2');
}
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  src: url("@{fontPath}raleway/v26/1Ptug8zYS_SKggPNyC0ITw.woff2") format('woff2');
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 100;
  src: url("@{fontPath}roboto/v29/KFOiCnqEu92Fr1Mu51QrEzAdLw.woff2") format('woff2');
}
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 300;
  src: url("@{fontPath}roboto/v29/KFOjCnqEu92Fr1Mu51TjASc6CsQ.woff2") format('woff2');
}
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  src: url("@{fontPath}roboto/v29/KFOkCnqEu92Fr1Mu51xIIzI.woff2") format('woff2');
}
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 700;
  src: url("@{fontPath}roboto/v29/KFOjCnqEu92Fr1Mu51TzBic6CsQ.woff2") format('woff2');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  src: url("@{fontPath}roboto/v29/KFOkCnqEu92Fr1MmgVxIIzI.woff2") format('woff2');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url("@{fontPath}roboto/v29/KFOlCnqEu92Fr1MmSU5fBBc4.woff2") format('woff2');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url("@{fontPath}roboto/v29/KFOmCnqEu92Fr1Mu4mxK.woff2") format('woff2');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url("@{fontPath}roboto/v29/KFOlCnqEu92Fr1MmWUlfBBc4.woff2") format('woff2');
}
@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 100;
  src: url("@{fontPath}robotoslab/v22/BngMUXZYTXPIvIBgJJSb6ufN5qU.woff2") format('woff2');
}
@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 300;
  src: url("@{fontPath}robotoslab/v22/BngMUXZYTXPIvIBgJJSb6ufN5qU.woff2") format('woff2');
}
@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 400;
  src: url("@{fontPath}robotoslab/v22/BngMUXZYTXPIvIBgJJSb6ufN5qU.woff2") format('woff2');
}
@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 700;
  src: url("@{fontPath}robotoslab/v22/BngMUXZYTXPIvIBgJJSb6ufN5qU.woff2") format('woff2');
}

@font-face {
  font-family: 'Dosis';
  font-style: normal;
  font-weight: 400;
  src: url("@{fontPath}dosis/v25/HhyaU5sn9vOmLzloC_U.woff2") format('woff2');
}
@font-face {
  font-family: 'Dosis';
  font-style: normal;
  font-weight: 500;
  src: url("@{fontPath}dosis/v25/HhyaU5sn9vOmLzloC_U.woff2") format('woff2');
}
@font-face {
  font-family: 'Dosis';
  font-style: normal;
  font-weight: 800;
  src: url("@{fontPath}dosis/v25/HhyaU5sn9vOmLzloC_U.woff2") format('woff2');
}
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 400;
  src: url("@{fontPath}nunito/v22/XRXX3I6Li01BKofIMNaDRs4.woff2") format('woff2');
}
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 700;
  src: url("@{fontPath}nunito/v22/XRXX3I6Li01BKofIMNaDRs4.woff2") format('woff2');
}
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  src: url("@{fontPath}nunito/v22/XRXV3I6Li01BKofINeaB.woff2") format('woff2');
}
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  src: url("@{fontPath}nunito/v22/XRXV3I6Li01BKofINeaB.woff2") format('woff2');
}

@import url('special-ritmo.less');

@specialExtraWideWidth: 970px;
@specialWideWidth: 800px;
@specialNormalWidth: 660px;

@specialHeaderLogoBg: rgba(255, 255, 255, 0.5); // rgba(0, 0, 0, 0.5);

@specialColorOrange: #e42b19; // #ee7266;
@specialDarkGrey: #c1c1c2;
@specialColorGrey: #ebebec;

@specialColorDarkBlue: #0a265a;
@specialColorLightOrange: #efd1ca;

@specialColorGreen: #92cdc9;
@specialColorDarkGreen: #5c8589;

@specialColorLightBlue: #2d8ee9;
@specialColorBrown: #cca9a5;
@specialColorLightBrown: #ebdedd;

@specialColor: @specialColorOrange;
@specialFooterBg: @specialColorGrey;
//@specialAlternateRow: @specialColorLightBrown;

@specialBlurEffect: 0 2px 25px;
@specialWhiteShadow: rgba(200, 200, 200, .7);
@specialBlackShadow: rgba(25, 25, 25, .7);

html.special-page-config,
html.special-page-config-header-minimal {
  scroll-behavior: smooth;

  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  #head-admin-menu-wrapper {
    position: relative;
    z-index: 1;
  }

  // Top logo bar
  #bottomnav,
  #hidnav a[href='\#bottomnav'] {
    display: none;
  }

  body.sidebar-menu-wrapper-visible {
    #total-main {
      display: none;
    }
    #head-wrapper {
      min-height: 160px;
    }
  }
  /*
  .uio-app-line-top {
    background: #000;
    height: 39px;

    .uio-logo {
      display: none;
    }

    .uio-logo-wrapper {
      width: @specialExtraWideWidth;
      margin: 0 auto;
    }
    .uio-app-name {
      .font-size(17);
      margin-top: -1px;
      margin-bottom: 0px;
    }
    .uio-app-name .uio-acronym {
      .font-size(17);
      padding-right: 0.75em;
      background-position: 100% 0.5ex;
      background-size: 0.4em 0.85em;
    }
    .uio-app-name .uio-host {
      .font-size(16);
      margin-left: -1px;
      font-weight: bold;
    }
  }*/

  // Main grid

  #main {
    margin: 0 0 40px 0;
    width: 100%;
  }

  /*
  #main .row-all-colored,
  .vrtx-frontpage-box.grey-box, .vrtx-frontpage-box.grey-box-light, .vrtx-frontpage-box.grey-clip-box {
    background: @specialAlternateRow;
  }
  */

  #head-wrapper {
    background: #fff;
    min-height: 174px;
  }

  #main #left-main {
    top: -50px;
    z-index: 6;
  }
  #breadcrumbs {
    z-index: 5;
    top: -50px;
  }

  //body:not(.ritmo):not(.vortex-test\.uio\.no) {
  body:not(.ritmo) {
    #head-wrapper {
      width: 100%;

      .uio-app-line-top {
        //background: @specialHeaderLogoBg;
        //padding: 20px 0 0px 0;
      }
    }
    #main #vrtx-main-content .grid-container.special-logo-video-grid-container {
      top: 0;
    }
    #footer-wrapper #footers .footer-content-wrapper .footer-title .footer-uio-logo {
      content:url("/vrtx/dist/resources/uio2/css/images/footer/uio-logo-no-black.svg");
    }
    &.en {
      #footer-wrapper #footers .footer-content-wrapper .footer-title .footer-uio-logo {
        content:url("/vrtx/dist/resources/uio2/css/images/footer/uio-logo-en-black.svg");
      }
    }
    #footer-wrapper {
      background: @specialFooterBg;

      &,
      a,
      *,
      h2 {
        color: #000;
      }
      a {
        //.textUnderlineHover(#000);
      }
      #footers {
        .vrtx-login-manage-component.vrtx-dropdown-component.vrtx-dropdown-component-toggled {
          //background-image: url("@{imagePath}arrows/arrow-small.svg");

          .vrtx-login-manage-link {
            background-image: url("@{imagePath}footer/dropdown-black.png");

            &:focus,
            &:hover {
              background-image: url("@{imagePath}footer/dropdown-black-hover.png");
            }
          }
        }
      }
    }

    #vrtx-main-content .special-logo-video-grid-container + .grid-container .vrtx-frontpage-box:first-child,
    #vrtx-main-content > .grid-container:first-child .vrtx-frontpage-box:first-child {
      .vrtx-box-content > p:first-child {
        &:first-letter {
          font-size: 40px;
          font-weight: 500;
          line-height: 1;
          font-family: "Roboto Slab", serif;
          color: @specialColor; // #555;
        }
      }
    }
  }

  &.special-page-config-header-minimal {
    #head-wrapper {
      background: rgba(255,255,255,0.8);
      min-height: 80px;
    }
    #main {
      position: static;
    }
    #left-main,
    #breadcrumbs {
      display: none;
    }
    .uio-app-line-top {
      display: none;
    }
  }

  /*
  &.special-page-config-header-minimal {
    #breadcrumbs,
    #head-wrapper > *:not(.uio-app-line-top),
    #hidnav a[href='\#globalnav'] {
      display: none;
    }
    //body:not(.ritmo):not(.vortex-test\.uio\.no) {
    body:not(.ritmo) {
      #head-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        background: transparent;
        z-index: 2;
      }
    }
  }
  */

  .vrtx-toc-header {
    display: none;
  }

  #main #total-main #vrtx-content,
  #main #total-main #vrtx-main-content.vrtx-empty-additional-content, #main #total-main #vrtx-main-content.vrtx-hide-additional-content-true, #main #total-main #vrtx-main-content:last-child {
    width: 100%;
  }

  #main .grid-container {
    position: relative;

    .vrtx-frontpage-box {
      position: static;
    }
  }

  #vrtx-frontpage #main .row-all-colored:not(.row-all-no-margin-bottom):not(.row-all-hidden-boxes)+.row-all-colored:not(.row-all-hidden-boxes),
  #vrtx-frontpage #vrtx-main-content:first-child .row-all-colored:first-child,
  #vrtx-frontpage h1.hidden:first-child+#vrtx-main-content .row-all-colored:first-child {
    margin-top: -60px;
  }

  #main .vrtx-back {
    background: #000;
    margin: 0;

    a {
      display: block;
      margin: 0 auto;
      width: @specialExtraWideWidth;
      color: #fff;
      padding: 10px 0;
    }
  }

  h1,
  #vrtx-frontpage-introduction,
  .vrtx-frontpage-box:not(.half-box-left):not(.half-box-right):not(.third-box-left):not(.third-box-middle):not(.third-box-right) {
    max-width: @specialWideWidth;
    margin-left: auto;
    margin-right: auto;
  }

  .vrtx-frontpage-box:not(.half-box-left):not(.half-box-right):not(.third-box-left):not(.third-box-middle):not(.third-box-right) .vrtx-box-content {
    width: @specialNormalWidth;
    margin: 0 auto;
  }

  .vrtx-box-content p {
    margin-top: 0px;
    margin-bottom: 40px;
  }

  .vrtx-frontpage-box {
    font-size: 22px;
    line-height: 1.6;
  }

  .special-lead p,
  .vrtx-introduction {
    font-size: 26px;
    line-height: 1.6;
    font-weight: 300;
  }

  .grid-container,
  .uio-main {
    ol,
    ul {
      margin-top: 0;
      margin-bottom: 40px;

      ol,
      ul {
        margin-top: 5px;
        margin-bottom: 15px;
      }
    }
    ul {
      li {
        &:before {
          top: .2em
        }
      }
    }
  }

  // Image gallery

  .vrtx-container, .vrtx-image-listing-include-container-description, .vrtx-imagetext, .vrtx-img-container, figcaption {
    font-size: 18px;
    line-height: 1.4;
    color: #444;
  }

  /*
  #vrtx-main-content .vrtx-image-listing-include .thumbs-next-row {
    margin-top: -120px;
  }
  */

  .vrtx-image-listing-include-container-title {
    font-weight: 300;
  }

  #main .vrtx-image-listing-include .vrtx-image-listing-include-container-description {
    p {
      margin-bottom: 20px;
    }
  }

  .grey-box {
    .vrtx-container, .vrtx-image-listing-include-container-description, .vrtx-imagetext, .vrtx-img-container, figcaption {
      font-size: 18px;
      line-height: 1.4;
      color: #000;
    }
  }

  // Accordion

  #main #vrtx-content .ui-accordion.ui-widget .ui-accordion-header {
    margin: 0;
  }

  // Typography

  #vrtx-frontpage-introduction .vrtx-introduction,
  #vrtx-frontpage-introduction .vrtx-introduction-image:last-child {
    p {
      margin-bottom: 40px;
    }
  }

  #main #vrtx-main-content {
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    color: #000;

    .introduction-div, .vrtx-frontpage-box.introduction .vrtx-box-content, .vrtx-introduction, .vrtx-introduction-big, .vrtx-organizational-unit-introduction {
      p {
        font-family: 'Roboto', sans-serif;
      }
    }
    p, li {
      font-family: 'Roboto', sans-serif;
    }
    p.quoteby {
      font-family: "Roboto Slab", serif;
    }
  }

  h1 {
    font-size: 75px;
    line-height: 1.13;
    margin-bottom: 40px;
    margin-top: 80px;
    font-family: "Roboto Slab", serif;
  }

  #main h2 {
    color: @specialColor;
  }

  #main #vrtx-content {
    h1, h2, h3, caption {
      font-weight: 500;
    }

    h2, h3, h4, h5, h6, caption {
      line-height: 1.13;
      font-family: "Roboto Slab", serif;
    }

    h2 {
      font-size: 40px;
      margin-bottom: 30px;
    }

    .half-box-left,
    .half-box-right {
      h2 {
        font-size: 25px;
        margin-bottom: 30px;
      }
    }
    h3, caption {
      font-size: 25px;
      margin-bottom: 30px;
    }

    .vrtx-frontpage-box.special-big-title > h2 {
      font-size: 55px;
      margin-top: 40px;

      &:before {
        content: " ";
        display: block;
        position: absolute;
        z-index: 1;
        /*top: 7.3rem;*/
        top: 95px;
        left: -.3em;
        width: .16em;
        height: .16em;
        background: @UiORed;
        border-radius: .3em;
        margin-right: .6em;
        z-index: 1;
        margin-top: 4px;
        -webkit-transition: width 1s ease-in-out;
        transition: width 1s ease-in-out;
      }
    }

    .vrtx-frontpage-box:not(.special-big-title) > h2 {
      max-width: @specialNormalWidth;
      margin-left: auto;
      margin-right: auto;
    }

    .vrtx-frontpage-box.special-big-title.grey-box > h2 {
      &:before {
        top: 135px;
      }
    }
  }

  blockquote {
    font-size: 23px;
    font-style: italic;
    line-height: 1.6;
    position: relative;
    border-left: none;
    padding-left: 60px;

    &:before {
      content: "\201C";
      display: block;
      font-size: 100px;
      font-style: normal;
      color: @specialColor;
      position: absolute;
      left: 0;
      top: 0;
      line-height: .9;
      border-left: none;
      margin: 0;
      height: auto;
    }
    &:after {
      display: none;
    }
  }

  p.quoteby {
    font-style: italic;
    text-align: right;
    display: block;

    &:before {
      content: "\2014";
      margin-right: 1ex;
    }
  }

  // Animations

  h1,
  #vrtx-frontpage-introduction,
  #main #vrtx-main-content .grid-container:not(.special-pinned-grid-container):not(.special-pinned-video-grid-container):not(.special-logo-video-grid-container),
  #main #vrtx-main-content .special-images-delay img,
  .item-inview {
    opacity: 0;
    transform: translateY(3rem);

    &.inview {
      opacity: 1;
      transform: translateY(0);
      transition: transform 0.6s ease-in-out, opacity 0.6s ease-in-out;
    }

    &.fade-enter-active,
    &.fade-leave-active {
      transition: opacity 2s;
    }
    &.fade-enter,
    &.fade-leave-to {
      opacity: 0;
    }
    &.fadeup-enter-active,
    &.fadeup-leave-active {
      transition: transform 1s, opacity 1s;
    }
    &.fadeup-enter,
    &.fadeup-leave-to {
      opacity: 0;
      transform: translateY(3rem);
    }
  }

  #main #vrtx-main-content .grid-container.special-logo-video-grid-container {
    opacity: 0;
    &.inview {
      opacity: 1;
      transition: opacity 0.6s ease-in-out;
    }

    &.fade-enter-active,
    &.fade-leave-active {
      transition: opacity 2s;
    }
    &.fade-enter,
    &.fade-leave-to {
      opacity: 0;
    }
    &.fadeup-enter-active,
    &.fadeup-leave-active {
      transition: opacity 1s;
    }
    &.fadeup-enter,
    &.fadeup-leave-to {
      opacity: 0;
    }
  }

  // Pinning

  #main {
    .special-pinned-video-wrapper,
    .special-pinned-wrapper {
      position: relative;
      min-width: 1000px;
      clear: both;
    }
  }

  #vrtx-frontpage {
    #main .special-pinned-video-grid-container,
    #main .special-pinned-grid-container {
      .row {
        min-width: 0;
        max-width: none;
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  #main .special-pinned-video-grid-container,
  #main .special-pinned-grid-container {
    position: static;
    //border: 1px solid green;

    &.row-all-colored:last-child {
      margin-bottom: 60px;
    }
    &:last-child {
      .vrtx-box-content {
        margin-bottom: 0;
      }
    }

    .row,
    .vrtx-frontpage-box {
      max-width: 100%;
      width: 100%;
      margin-left: 0;
      margin-right: 0;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: row;
      flex-direction: row;
    }
    .vrtx-frontpage-box {
      flex:2;
      //border: 1px solid orange;
    }

    .row {
      width: auto;
      flex:1;
    }

    .vrtx-frontpage-box .vrtx-frontpage-box-picture {
      position: fixed;
      will-change: transform;
      top: 25vh;
      height: 50vh;
      width: 50%;
      margin: 0;
      padding: 0 80px;
      float: none;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: row;
      flex-direction: row;
      flex:1;
      -webkit-box-align: top;
      -ms-flex-align: top;
      align-items: top;
      visibility: hidden;
      //border: 1px solid blue;

      .vrtx-media-player,
      img {
        max-height: 50vh;
        max-width: 100%;
        object-fit: contain;
        margin: 0 auto;
      }

      &.visible {
        visibility: visible;
        //border: 1px solid black;
      }
    }
    .vrtx-box-content {
      width: 50%;
      margin: 0 0 50vh 50%;
      padding: 5% 80px 0 80px;
      float: right;
      z-index: 10;
    }
  }

  #main {
    .special-pinned-video-grid-container,
    .special-pinned-grid-container {
      h2 {
        color: #222;
      }
    }
  }

  #main .special-pinned-video-wrapper.visible-sticky,
  #main .special-pinned-wrapper.visible-sticky {
    .special-pinned-video-grid-container,
    .special-pinned-grid-container {
      //border: 1px solid red;
      .vrtx-frontpage-box .vrtx-frontpage-box-picture {
        position: static;
      }
      .vrtx-box-content {
        margin-left: 0;
      }
    }
    &.visible-sticky-ie11 {
      .special-pinned-video-grid-container,
      .special-pinned-grid-container {
        .vrtx-frontpage-box .vrtx-frontpage-box-picture {
          position: static;
        }
      }
    }
  }

  #main #vrtx-main-content .grid-container {
    &.inview {
      .vrtx-frontpage-box.special-big-title > h2:before {
        width: 50%;
      }
    }
  }

  // Pinned big / fullwidth

  #main .special-pinned-big-wrapper {
    position: relative;
    min-width: 1000px;
  }

  h1,
  #vrtx-frontpage-introduction {
    position: relative;
    z-index: 1;
  }

/*
  .pinnedDarkBackground() {
    content: "";
    position: absolute;
    top: -5%;
    left: -5%;
    width: 110%;
    height: 110%;
    opacity: .4;
    background: #000;
    border-radius: 50px;
    overflow: visible;
    z-index: -1;
    box-shadow: 0px 0px 20px 8px #000;
  }
  */

  h1,
  #vrtx-frontpage-introduction {
    position: relative;
  }

  body.special-pinned-big-wrapper-inview {
    background: #000;
  }

  #main #vrtx-main-content .grid-container.special-pinned-big-grid-container {
    position: static !important;
    opacity: 1 !important;
    transform: none !important;
    background: transparent !important;

    .row {
      padding-left: 0;
      padding-right: 0;
      min-width: 0;
      max-width: none;
    }

    &.special-logo-video-grid-container {
      .vrtx-box-content {
        display: none;
      }
    }

    .vrtx-frontpage-box {
      .vrtx-frontpage-box-picture  {
        position: fixed;
        top: 0;
        left: 0;
        will-change: transform;
        will-change: opacity;
        opacity: 0;
        .transition(0.5s, opacity, ease-in-out);
        margin: 0;
        float: none;
        overflow: hidden;

        iframe {
          position: absolute;
          top: 0;
          left: 0;
        }

        iframe,
        img {
          max-height: none !important;
          object-fit: cover;
          width: 100% !important;
          height: 100% !important;
        }
      }

      &.special-pinned-big-media-aligned-top {
        .vrtx-frontpage-box-picture {
          iframe,
          img {
            object-position: center top;
          }
        }
      }
      &.special-pinned-big-media-aligned-bottom {
        .vrtx-frontpage-box-picture {
          iframe,
          img {
            object-position: center bottom;
          }
        }
      }
      &.special-pinned-big-media-aligned-left {
        .vrtx-frontpage-box-picture {
          iframe,
          img {
            object-position: left center;
          }
        }
      }
      &.special-pinned-big-media-aligned-left-top {
        .vrtx-frontpage-box-picture {
          iframe,
          img {
            object-position: left top;
          }
        }
      }
      &.special-pinned-big-media-aligned-left-bottom {
        .vrtx-frontpage-box-picture {
          iframe,
          img {
            object-position: left bottom;
          }
        }
      }
      &.special-pinned-big-media-aligned-right {
        .vrtx-frontpage-box-picture {
          iframe,
          img {
            object-position: right center;
          }
        }
      }
      &.special-pinned-big-media-aligned-right-top {
        .vrtx-frontpage-box-picture {
          iframe,
          img {
            object-position: right top;
          }
        }
      }
      &.special-pinned-big-media-aligned-right-bottom {
        .vrtx-frontpage-box-picture {
          iframe,
          img {
            object-position: right bottom;
          }
        }
      }

      .vrtx-box-content {
        width: 800px;
        margin: 70vh auto 0 auto;
        position: relative;
        font-size: 26px;
        line-height: 1.6;
        font-weight: 300;
        z-index: 1;
      }
    }

    .vrtx-frontpage-box {
      .vrtx-media-player {
        margin: 0;
      }
    }

    &:last-child {
      .vrtx-frontpage-box .vrtx-box-content {
        margin-bottom: 70vh;
      }
    }

    &,
    &.special-logo-video-grid-container {
      &,
      .row,
      .vrtx-frontpage-box,
      .vrtx-frontpage-box .vrtx-frontpage-box-picture,
      .vrtx-frontpage-box .vrtx-media-player iframe {
        width: 100% !important;
        height: 100% !important;
      }
    }

    &.visible {
      .vrtx-frontpage-box {
        .vrtx-frontpage-box-picture  {
          opacity: 1;
        }
      }
    }

    &.sticky-top:not(.special-logo-video-grid-container) {
      .vrtx-frontpage-box {
        .vrtx-frontpage-box-picture {
          opacity: 1;
          position: absolute;
          left: 0;
          top: 0;
          width: 100% !important;
          height: 100vh !important;
        }
      }
    }

    &.sticky-bottom {
      .vrtx-frontpage-box {
        .vrtx-frontpage-box-picture {
          opacity: 1;
          position: absolute;
          left: 0;
          top: auto;
          bottom: 0;
          width: 100% !important;
          height: 100vh !important;
        }
      }
    }
  }

  &.special-page-config {
    #main #vrtx-main-content .grid-container.special-pinned-big-grid-container {
      &.special-logo-video-grid-container {
        position: absolute !important;
      }
    }
  }

  // Special classes

  body.special-page-logo-video-top { // Only used on Ritmo with 400px height logo
    h1 {
      margin-top: 460px;
    }
  }

  .special-image {
    .vrtx-frontpage-box-picture {
      margin-left: auto !important;
      margin-right: auto !important;
      width: @specialNormalWidth;
      float: none;
    }
  }

  #main .special-image-two-column {
    width: auto;

    .vrtx-frontpage-box-picture,
    .vrtx-box-content {
      width: 50%;
    }

    .vrtx-frontpage-box-picture {
      float: left;
      margin-left: -85px;

      img[src$='.svg'] {
        min-width: 100%;
      }
    }
    .vrtx-box-content {
      margin-right: -85px;
    }
  }

  #main .special-extra-wide-content {
    &:not(.special-lead),
    &:not(.special-lead) .vrtx-box-content {
      width: @specialExtraWideWidth;
      max-width: @specialExtraWideWidth;
    }
    .vrtx-box-content h3,
    > h2 {
      width: @specialWideWidth;
      margin-left: auto;
      margin-right: auto;
    }
    &.special-lead .vrtx-box-content > *:nth-child(2) {
      width: @specialExtraWideWidth;
      margin-left: -85px;
    }
  }

  #main .grid-container .special-lead {
    margin-bottom: 40px;

    .vrtx-box-content {
      width: 100%;
    }
  }

  //body:not(.ritmo):not(.vortex-test\.uio\.no) {
  body:not(.ritmo) {
    #main #vrtx-content .vrtx-frontpage-box.special-lead {
      margin-bottom: 0;

      >h2 {
        max-width: 800px;
      }
    }
  }

  #vrtx-frontpage #main .special-image-fullwidth-grid-container {
    .row {
      margin-left: 0;
      margin-right: 0;
      width: auto;
      max-width: none;
      min-width: 0;
      padding: 0;

      .vrtx-frontpage-box {
        width: 100%;
        max-width: 100%;
      }

      .vrtx-frontpage-box-picture {
        margin: 0;
        float: none;

        img {
          width: 100%;
        }
      }
    }
  }

  #vrtx-frontpage {
    #main .row-all-colored:not(.row-all-no-margin-bottom):not(.row-all-hidden-boxes)+.row-all-colored:not(.row-all-hidden-boxes),
    #vrtx-main-content:first-child .row-all-colored:first-child,
    h1.hidden:first-child+#vrtx-main-content .row-all-colored:first-child {
      margin-top: -60px;
    }
  }

  #main .vrtx-image-listing-include .toggle-fullscreen-container {
    display: none;
  }

  #main .special-content-fullwidth-grid-container {
    .row {
      .vrtx-image-listing-include .vrtx-image-listing-include-container-description {
        /*margin-left: auto;
        margin-right: auto;*/
        max-width: @specialWideWidth;
      }

      .vrtx-frontpage-box,
      .vrtx-box-content {
        width: 100%;
        max-width: 100%;
      }

      .vrtx-box-content {
        >h3,
        >p {
          width: @specialNormalWidth;
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
  }

  // Banner

  #main #vrtx-main-content .grid-container.special-logo-video-grid-container {
    position: absolute;
    top: 120px;
    height: 400px;
    z-index: -1;

    .row {
      max-width: none;
      min-width: 0;
      padding: 0;
    }

    .vrtx-box-content {
      width: 100%;
      text-align: center;
    }

    video {
      max-width: 730px;
      height: auto;
      margin: 0 auto;
    }

    img {
      display: none;
      max-width: 100%;
      max-height: 400px;
    }

    .vrtx-media-player, .vrtx-media-player-no-flash {
      margin: 0;
    }
  }

  #main #vrtx-main-content .grid-container.special-logo-image-grid-container,
  #main #vrtx-main-content .grid-container.special-logo-video-wide-grid-container {
    height: auto;

    .row {
      min-width: 0;
      padding: 0;
    }

    .vrtx-frontpage-box,
    .row {
      max-width: none;
      width: auto;
      margin-left: 0;
      margin-right: 0;
    }
    .vrtx-frontpage-box {
      height: auto;
    }

    .vrtx-box-content {
      p,
      li {
        font-size: 26px;
        line-height: 1.6;
        font-weight: 300;
      }
    }
  }

  #main #vrtx-main-content .grid-container.special-logo-image-grid-container {
    .vrtx-frontpage-box-picture {
      margin: 0;
      float: none;
      height: auto;
    }
    img {
      display: block;
      width: 100%;
      max-width: 100%;
      max-height: 1080px;
      min-width: 1000px;
    }

    .vrtx-box-content {
      width: 800px;
    }
  }

  #main #vrtx-main-content .grid-container.special-logo-video-wide-grid-container {
    iframe {
      min-width: 1000px;
    }
    .vrtx-media-player, .vrtx-media-player-no-flash {
      margin: 0;
    }
    video {
      max-width: none;
      width: 1920px;
      .translate(0, -50%);
    }

    .vrtx-box-content {
      width: 100%;

      >*:nth-child(2) {
        width: 800px;
        position: relative;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  // Figure list

  #main .figure-list-five-column,
  #main .figure-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    -ms-flex-direction: row;
    flex-direction: row;
    flex: 0 1 auto;

    figure {
      display: block !important; // override
      margin-top: 0;
      margin-right: 0;
      width: ~"calc(100% / 4)";

      &:nth-child(4n+1) {
        clear: left;
        padding-left: 0;
        padding-right: 20px;
      }
      &:nth-child(4n+2),
      &:nth-child(4n+3) {
        clear: none;
        padding-left: 10px;
        padding-right: 20px;
      }
      &:nth-child(4n+4) {
        clear: none;
        padding-left: 10px;
        padding-right: 0;
      }

      /* !important to override if set on figure */
      &.image-right,
      &.image-left {
        float: none !important;
        max-width: none !important;
      }

      figcaption {
        display: block;
        clear: left;
        float: left;

        > *:first-child {
          display: block;
          font-weight: 700;
          margin-bottom: 5px;
        }
        > strong:first-child {
          color: #444;
        }
      }
    }

    &:not(.figure-list-no-scale) figure {
      img {
        width: 100%;
      }
    }

    &.figure-list-big-title {
      figure {
        figcaption {
          font-size: 16px;

          > *:first-child {
            font-size: 20px;
            margin-bottom: 10px;
          }
        }
      }
    }
    &.figure-list-circle {
      figure {
        img {
          -o-object-fit: cover;
          object-fit: cover;
          -o-object-position: 50% 50%;
          object-position: 50% 50%;
          border-radius: 50%;
          border: 1px solid #444;
          max-height: 200px;
          max-width: 200px;

          &[src*='person-no-image.png'] {
            transform: scale(0.7);
          }
        }
      }
    }
    &.figure-list-no-scale {
      figure img {
        max-width: none;
      }
    }
  }

  #main .figure-list-five-column {
    figure {
      width: ~"calc(100% / 5)";

      &:nth-child(5n+1) {
        clear: left;
        padding-left: 0;
        padding-right: 20px;
      }
      &:nth-child(5n+2),
      &:nth-child(5n+3),
      &:nth-child(5n+4) {
        clear: none;
        padding-left: 10px;
        padding-right: 20px;
      }
      &:nth-child(5n+5) {
        clear: none;
        padding-left: 10px;
        padding-right: 0;
      }
    }
  }
}

html.special-page-config,
html.special-page-config-header-minimal {
  .special-page-special-pinned-big-top {
    &.special-page-logo-intro-over {
      h1,
      #vrtx-frontpage-introduction .vrtx-introduction {
        color: #fff;
      }
      &.special-page-logo-box-shadow {
        h1,
        #vrtx-frontpage-introduction {
          box-shadow: 0px 0px 30px 20px rgba(0,0,0,.4);
          background: rgba(0,0,0,0.4);
        }
      }
      &.special-page-logo-text-shadow {
        h1,
        #vrtx-frontpage-introduction .vrtx-introduction {
          text-shadow: @specialBlurEffect @specialBlackShadow;
        }
      }
      &.special-page-logo-black-text-over {
        h1,
        #vrtx-frontpage-introduction .vrtx-introduction {
          color: #000;
        }
        &.special-page-logo-box-shadow {
          h1,
          #vrtx-frontpage-introduction {
            box-shadow: 0px 0px 30px 20px rgba(255,255,255,.4);
            background: rgba(255,255,255,0.4);
          }
        }
        &.special-page-logo-text-shadow {
          h1,
          #vrtx-frontpage-introduction .vrtx-introduction {
            text-shadow: @specialBlurEffect @specialWhiteShadow;
          }
        }
      }
    }
    &.special-page-logo-h1-over {
      h1 {
        color: #fff;
      }
      &.special-page-logo-box-shadow {
        h1 {
          box-shadow: 0px 0px 30px 20px rgba(0,0,0,.4);
          background: rgba(0,0,0,0.4);
        }
      }
      &.special-page-logo-text-shadow {
        h1 {
          text-shadow: @specialBlurEffect @specialBlackShadow;
        }
      }
      &.special-page-logo-black-text-over {
        h1 {
          color: #000;
        }
        &.special-page-logo-box-shadow {
          h1 {
            box-shadow: 0px 0px 30px 20px rgba(255,255,255,.4);
            background: rgba(255,255,255,0.4);
          }
        }
        &.special-page-logo-text-shadow {
          h1 {
            text-shadow: @specialBlurEffect @specialWhiteShadow;
          }
        }
      }
    }
  }
  #main .special-pinned-big {
    h2,
    .vrtx-box-content {
      color: #fff;
    }
    a,
    h2 a {
      color: #fff;
      .textUnderline(#fff);

      &:hover,
      &:focus {
        text-decoration: none;
      }
    }
    &.special-logo-box-shadow {
      .vrtx-box-content {
        box-shadow: 0px 0px 30px 20px rgba(0,0,0,.4);
        background: rgba(0,0,0,0.4);
      }
    }
    &.special-logo-text-shadow {
      h2,
      .vrtx-box-content,
      a,
      h2 a {
        text-shadow: @specialBlurEffect @specialBlackShadow;
      }
    }
    &.special-logo-black-text-over {
      h2,
      .vrtx-box-content {
        color: #000;
      }
      a,
      h2 a {
        color: #000;
        .textUnderline(@textColorUnderline);

        &:hover,
        &:focus {
          text-decoration: none;
        }
      }
      &.special-logo-box-shadow {
        .vrtx-box-content {
          box-shadow: 0px 0px 30px 20px rgba(255,255,255,.4);
          background: rgba(255,255,255,0.4);
        }
      }
      &.special-logo-text-shadow {
        h2,
        .vrtx-box-content,
        a,
        h2 a {
          text-shadow: @specialBlurEffect @specialWhiteShadow;
        }
      }
    }
  }
}

// Desktop

@media @desktopBreakpoint {

  html.special-page-config,
  html.special-page-config-header-minimal {

    .special-page-logo-intro-over {
      h1,
      #vrtx-frontpage-introduction .vrtx-introduction {
        color: #fff;
      }
      &.special-page-logo-box-shadow {
        h1,
        #vrtx-frontpage-introduction {
          box-shadow: 0px 0px 30px 20px rgba(0,0,0,.4);
          background: rgba(0,0,0,0.4);
        }
      }
      &.special-page-logo-text-shadow {
        h1,
        #vrtx-frontpage-introduction .vrtx-introduction {
          text-shadow: @specialBlurEffect  @specialBlackShadow;
        }
      }
      &.special-page-logo-black-text-over {
        h1,
        #vrtx-frontpage-introduction .vrtx-introduction {
          color: #000;
        }
        &.special-page-logo-box-shadow {
          h1,
          #vrtx-frontpage-introduction {
            box-shadow: 0px 0px 30px 20px rgba(255,255,255,.4);
            background: rgba(255,255,255,0.4);
          }
        }
        &.special-page-logo-text-shadow {
          h1,
          #vrtx-frontpage-introduction .vrtx-introduction {
            text-shadow: @specialBlurEffect @specialWhiteShadow;
          }
        }
      }
      &.special-page-logo-text-left#vrtx-frontpage.total-main #main {
        h1,
        #vrtx-frontpage-introduction {
          padding-right: ~"calc(20px + 600px)";
        }
      }

      &.special-page-logo-text-right#vrtx-frontpage.total-main #main {
        h1,
        #vrtx-frontpage-introduction {
          padding-left: ~"calc(220px + 600px)";
        }
      }
    }
    .special-page-logo-h1-over {
      h1 {
        color: #fff;
      }
      &.special-page-logo-box-shadow {
        h1 {
          box-shadow: 0px 0px 30px 20px rgba(0,0,0,.4);
          background: rgba(0,0,0,0.4);
        }
      }
      &.special-page-logo-text-shadow {
        h1 {
          text-shadow: @specialBlurEffect @specialWhiteShadow;
        }
      }
      &.special-page-logo-black-text-over {
        h1 {
          color: #000;
        }
        &.special-page-logo-box-shadow {
          h1 {
            box-shadow: 0px 0px 30px 20px rgba(255,255,255,.4);
            background: rgba(255,255,255,0.4);
          }
        }
        &.special-page-logo-text-shadow {
          h1 {
            text-shadow: @specialBlurEffect @specialWhiteShadow;
          }
        }
      }
      &.special-page-logo-text-left,
      &.special-page-logo-text-right {
        h1 {
          overflow: hidden;
        }
        h1 span {
          display: block;
          width: 50%;
        }
      }
      &.special-page-logo-text-left {
        h1 span {
          float: left;
        }
      }

      &.special-page-logo-text-right {
        h1 span {
          float: right;
        }
      }
    }
    #main .special-logo-image:not(.special-logo-video),
    #main .special-logo-video-wide:not(.special-logo-video) {
      h2,
      .vrtx-box-content {
        color: #fff;
      }
      a,
      h2 a {
        color: #fff;
      }
      &.special-logo-box-shadow {
        .vrtx-box-content {
          box-shadow: 0px 0px 30px 20px rgba(0,0,0,.4);
          background: rgba(0,0,0,0.4);
        }
      }
      &.special-logo-text-shadow {
        h2,
        .vrtx-box-content,
        a,
        h2 a {
          text-shadow: @specialBlurEffect @specialBlackShadow;
        }
      }
      &.special-logo-black-text-over {
        h2,
        .vrtx-box-content {
          color: #000;
        }
        a,
        h2 a {
          color: #000;
          .textUnderline(@textColorUnderline);

          &:hover,
          &:focus {
            text-decoration: none;
          }
        }
        &.special-logo-box-shadow {
          .vrtx-box-content {
            box-shadow: 0px 0px 30px 20px rgba(255,255,255,.4);
            background: rgba(255,255,255,0.4);
          }
        }
        &.special-logo-text-shadow {
          h2,
          .vrtx-box-content,
          a,
          h2 a {
            text-shadow: @specialBlurEffect @specialWhiteShadow;
          }
        }
      }
    }
  }
}
/*
@media only screen and (max-width: 1024px) {
  html.special-page-config,
  html.special-page-config-header-minimal {
    #main .special-image-two-column {
      .vrtx-frontpage-box-picture {
        margin-left: 0;
      }
      .vrtx-box-content {
        padding-left: 40px;
        margin-right: 0;
      }
    }
  }
}
*/

.debug {
  position: fixed;
  top: 0;
  left: 0;
  width: 200px;
  height: 200px;
  background: white;
  z-index: 3;
}

html.special-page-config,
html.special-page-config-header-minimal {
  #main #vrtx-content #vrtx-main-content {
    .special-logo-text-left .vrtx-box-content,
    .special-logo-text-right .vrtx-box-content {
      width: 50%;
    }

    .special-logo-text-left .vrtx-box-content {
      float: left;
    }

    .special-logo-text-right .vrtx-box-content {
      float: right;
    }
  }
}

@media @desktopSmallOnlyBreakpoint {
  /*
  html.special-page-config,
  html.special-page-config-header-minimal {
    .sidebar-menu-wrapper-visible {
      #main #left-main {
        margin-top: 0 !important;
        position: fixed;
      }
    }
    #main .special-pinned-big-wrapper {
      min-width: 0;
    }
  }
  */

  html.special-page-config #vrtx-frontpage #main .special-pinned-grid-container .row,
  html.special-page-config #vrtx-frontpage #main .special-pinned-video-grid-container .row,
  html.special-page-config-header-minimal #vrtx-frontpage #main .special-pinned-grid-container .row,
  html.special-page-config-header-minimal #vrtx-frontpage #main .special-pinned-video-grid-container .row,
  html.special-page-config #main #vrtx-main-content .grid-container.special-pinned-big-grid-container .vrtx-frontpage-box .vrtx-box-content,
  html.special-page-config-header-minimal #main #vrtx-main-content .grid-container.special-pinned-big-grid-container .vrtx-frontpage-box .vrtx-box-content {
    padding-left: 25px;
    padding-right: 25px;
  }
}

// Responsive

@media @desktopSmallBreakpoint {

  html.special-page-config,
  html.special-page-config-header-minimal {
    #main #left-main {
      top: 30px;
    }
  }

  html.special-page-config,
  html.special-page-config-header-minimal {

    #footer-wrapper-back-to-uio {
      padding-top: 25px;
    }
    body.sidebar-menu-wrapper-visible {
      #head-wrapper {
        min-height: 0;
      }
    }
    #breadcrumbs {
      top: -10px;
    }
    #head-wrapper {
      min-height: 0;

      .uio-app-line-top {
        height: 27px;
        padding-left: 15px;
        padding-right: 15px;

        .uio-logo-wrapper {
          width: auto;
          margin: 0;
        }
        /*
        .uio-app-name {
          font-size: 11px;
          line-height: 24px;
          line-height: 2.4rem;
          margin-top: 0;
          margin-bottom: 0px;
        }
        .uio-app-name .uio-acronym {
          font-size: 13px;
          padding-right: 0.7em;
          background-position: 100% .5ex;
        }
        .uio-app-name .uio-host {
          font-size: 10px;
          font-size: 1rem;
          margin-left: -1px;
        }
        */
      }
    }

    #vrtx-frontpage #main {
      padding-top: 0;
    }

    #main .vrtx-image-listing-include {
      left: 0 !important;
      width: 100% !important;
      .vrtx-image-listing-include-container-description {
        margin-top: 15px;
      }
    }

    #main h1 {
      font-size: 45px;
      margin-bottom: 20px;
    }
    body:not(.special-page-logo-video-top) #main h1 {
      margin-top: 20px;
    }
    .vrtx-frontpage-box {
      font-size: 20px;
    }
    blockquote {
      font-size: 21px;
    }
    .vrtx-introduction,
    .special-lead p {
      font-size: 24px;
    }
    #main #vrtx-main-content {
      h2 {
        font-size: 28px;
        margin-bottom: 25px;
      }

      .half-box-left,
      .half-box-right {
        h2 {
          font-size: 23px;
          margin-bottom: 15px;
        }
      }
      h3 {
        font-size: 23px;
        margin-bottom: 15px;
      }

      .vrtx-frontpage-box.special-big-title>h2 {
        font-size: 38px;

        &:before {
          top: 84px;
        }
      }
      .vrtx-frontpage-box.special-big-title.grey-box>h2:before {
        top: 100px;
      }
      .grid-container:not(.row-last-colored)+.grid-container:not(.row-first-colored) {
        .vrtx-frontpage-box.special-big-title>h2:before {
          top: 98px;
        }
      }
    }

    .grid-container,
    .uio-main {
      ol,
      ul {
        margin-top: 0;
        margin-bottom: 30px;

        ol,
        ul {
          margin-top: 5px;
          margin-bottom: 10px;
        }
      }
    }

    #main #vrtx-main-content .grid-container.special-logo-video-grid-container:not(.special-logo-video-wide-grid-container) {
      video {
        display: none;
      }
      img {
        display: block;
      }
    }

    #main #vrtx-main-content .grid-container {
      &.special-logo-video-wide-grid-container {
        iframe {
          min-width: 0;
        }
        .vrtx-box-content >*:nth-child(2) {
          padding: 15px;
        }
      }
      &.special-logo-image-grid-container {
        img {
          min-width: 0;
          max-width: 100%;
          min-height: 100px;
          max-height: none;
        }
        .vrtx-box-content {
          padding: 15px;
        }
      }
    }

    body.special-page-logo-video-wide-top h1,
    body.special-page-logo-image-top h1 {
      margin-top: 120px;
    }

    #vrtx-frontpage-introduction .vrtx-introduction:last-child p {
      margin-bottom: 0;
    }

    #main {
      h1 {
        margin-bottom: 15px;
      }
      .vrtx-box-content p {
        margin-bottom: 15px;
      }
      .grid-container {
        margin-bottom: 40px;
      }
      .special-pinned-video-wrapper,
      .special-pinned-wrapper,
      .special-pinned-big-wrapper {
        min-width: 0;
      }
      .special-pinned-video-grid-container,
      .special-pinned-grid-container {
        .row,
        .vrtx-frontpage-box {
          display: block;
        }

        .vrtx-frontpage-box .vrtx-frontpage-box-picture {
          width: 100%;
          padding: 0;
          margin: 0 0 30px 0;
          position: static;
          min-width: 75vw;
          visibility: visible;
          height: auto;
          display: block;
        }
        .vrtx-box-content {
          width: 100%;
          position: relative;
          display: block;
          margin: 0;
          padding: 0;
        }
      }
      .special-image-two-column {
        .vrtx-frontpage-box-picture {
          width: auto;

          img {
            min-width: 0;
          }
        }
        .vrtx-box-content {
          padding-left: 0;
        }
      }
      .figure-list,
      .figure-list-five-column {
        figure:nth-child(n) {
          width: 100%;
          float: none;
          clear: left;
          margin: 0 0 10px 0;
          padding: 0;

          >*:first-child {
            width: 30% !important;
            float: left;
            display: block;
            margin: 0;
          }
          figcaption {
            width: 70%;
            padding-left: 15px;
            clear: none;
            float: right;
            margin: 0;
          }
        }
      }

      .vrtx-box-content,
      .vrtx-box-content > *:nth-child(2),
      .grid-container,
      .vrtx-frontpage-box,
      .special-pinned .vrtx-frontpage-box-picture,
      h2,
      .row {
        width: 100% !important;
        max-width: 100% !important;
        margin-left: 0;
        margin-right: 0;
      }

      .special-content-fullwidth-grid-container .row .vrtx-box-content>p,
      .special-content-fullwidth-grid-container .row .vrtx-box-content>h3,
      .special-extra-wide-content.special-lead .vrtx-box-content > h3,
      .special-extra-wide-content.special-lead .vrtx-box-content > *:nth-child(2) {
        width: auto;
        margin-left: 0;
        margin-right: 0;
      }

/*
      #vrtx-main-content .grid-container.special-pinned-big-grid-container {
        &,
        &.special-logo-video-grid-container {
          &,
          .row,
          .vrtx-frontpage-box,
          .vrtx-frontpage-box .vrtx-frontpage-box-picture,
          .vrtx-frontpage-box .vrtx-media-player iframe {
            height: auto !important;
          }
        }
        .vrtx-frontpage-box {
          .vrtx-frontpage-box-picture {
            .vrtx-media-player {
              position: static !important;
              max-width: 100% !important;
            }
          }
        }
        &.special-logo-video-grid-container {
          .vrtx-frontpage-box {
            .vrtx-frontpage-box-picture {
              .vrtx-media-player {
                width: 100% !important;
                height: 800px !important;
              }
            }
          }
        }
        &:not(.special-logo-video-grid-container) .vrtx-frontpage-box {
          .vrtx-frontpage-box-picture {
            opacity: 1;
            position: static;

            .vrtx-media-player {
              position: static !important;
              max-width: 100% !important;
            }

            iframe {
              position: static;
            }
          }
          .vrtx-box-content {
            margin: 10px 0 60px 0;
          }
        }
      }
*/
      #vrtx-main-content .grid-container {
        &.special-logo-video-grid-container,
        &.special-logo-image-grid-container,
        &.special-logo-video-wide-grid-container,
        &.special-image-fullwidth-grid-container {
          .vrtx-frontpage-box {
            padding-left: 0;
            padding-right: 0;
          }
        }
      }

      // Special pinned big
      .special-pinned-big-wrapper {
        margin-bottom: 40px;
      }
      #vrtx-main-content .grid-container.special-pinned-big-grid-container {
        .vrtx-frontpage-box {
          .vrtx-frontpage-box-picture  {
            max-width: none !important;

            .vrtx-media-player:not([id]) {
              display: none;
            }
            .iframe-wrapper-responsive {
              overflow: visible;
            }

            iframe,
            img {
              width: 100% !important;
              height: 100% !important;
            }
          }
          .vrtx-box-content {
            width: 100% !important;
          }
        }

        &:last-child {
          .vrtx-frontpage-box .vrtx-box-content {
            margin-bottom: 70vh;
          }
        }

        &,
        &.special-logo-video-grid-container {
          &,
          .row,
          .iframe-wrapper-responsive,
          .vrtx-frontpage-box,
          .vrtx-frontpage-box .vrtx-frontpage-box-picture,
          .vrtx-frontpage-box .vrtx-media-player iframe {
            width: 100% !important;
            height: 100% !important;
          }
        }
      }
    }

    #vrtx-frontpage #main {
      .grid-container.row-last-colored+.row-first-colored,
      .grid-container:not(.row-last-colored)+.grid-container:not(.row-first-colored),
      .vrtx-frontpage-box.grey-box-light:not(.responsive-hide)+.grey-box-light,
      .vrtx-frontpage-box.grey-box:not(.responsive-hide)+.grey-box,
      .vrtx-frontpage-box.grey-clip-box:not(.responsive-hide)+.grey-clip-box,
      .vrtx-frontpage-box.red:not(.responsive-hide)+.red,
      .vrtx-frontpage-box:not(.grey-box):not(.grey-clip-box):not(.grey-box-light):not(.red):not(.responsive-hide)+.vrtx-frontpage-box:not(.grey-box):not(.grey-clip-box):not(.grey-box-light):not(.red) {
        border-top: none;
      }
    }
  }
}
