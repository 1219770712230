#vrtx-master-listing {
  #vrtx-table-supervisors,
  #vrtx-table-students {
    width: 25%;
  }

  .vrtx-table-creation-time,
  .vrtx-table-scope {
    width: 1%;
    white-space: nowrap;
  }
/*
  .vrtx-table-supervisors,
  .vrtx-table-students,
  .vrtx-table-persons {
    ul,
    ul li {
      margin: 0;
      display: inline;

      &:before {
        display: none;
      }
    }
  }
  */
}
