// Adjust some frontpage editing things in new design
html.embedded-frontpage #vrtx-frontpage {
  #main #right-main, #main #total-main {
    padding-left: 0;
  }
  #left-main,
  #head-admin-menu-wrapper,
  .sidebar-menu-toggle {
    display: none;
  }
  .vrtx-edit-undo-hint button,
  .shortcuts button,
  .vrtx-frontpage-box__overlay,
  .edit-row-title-wrapper button,
  .vrtx-edit-title button,
  .admin-box-row-menu button {
    max-width: none;
    &:after {
      display: none;
    }
  }
  .vrtx-edit-grid .vrtx-edit-button,
  .vrtx-edit-undo-hint button,
  .edit-row-title-wrapper button,
  .vrtx-edit-title button,
  .admin-box-row-menu button {
    width: auto;
  }
  .edit-row-title-wrapper input,
  .vrtx-frontpage-editor .vrtx-edit-titleinput,
  .vrtx-frontpage-editor .vrtx-edit-grid input {
    width: auto;
    max-width: none;
  }

  #main {
    #vrtx-frontpage-introduction,
    h1 {
      padding-right: 0;
    }
  }
  #main .grid-container.sub-header-container {
    overflow: hidden;

    .row {
      padding-left: 0 !important;
    }
  }

  &.uio-test\.uio\.no .vrtx-frontpage-box.sub-header-box-big.vrtx-frontpage-box-picture .vrtx-frontpage-box-picture,
  &.uio-test\.uio\.no .vrtx-frontpage-box.sub-header-box.vrtx-frontpage-box-picture .vrtx-frontpage-box-picture,
  &.www\.uio\.no .vrtx-frontpage-box.sub-header-box-big.vrtx-frontpage-box-picture .vrtx-frontpage-box-picture,
  &.www\.uio\.no .vrtx-frontpage-box.sub-header-box.vrtx-frontpage-box-picture .vrtx-frontpage-box-picture {
    top: auto !important;
    bottom: 0;
  }
  &.uio-test\.uio\.no .vrtx-frontpage-box.sub-header-box,
  &.www\.uio\.no .vrtx-frontpage-box.sub-header-box {
    margin-left: 0;
  }
  #main {
    #breadcrumbs,
    h1,
    .row {
      min-width: 0;
      padding-left: 0;
      padding-right: 0;
    }
  }
  &.total-main #main #vrtx-frontpage-introduction,
  #footer-wrapper #footers,
  #head-wrapper #head {
    min-width: 0;
    padding-left: 0;
  }
  #footer-wrapper #footers {
    padding-left: 15px;
    padding-right: 15px;
  }
}
