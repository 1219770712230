/* Filters, is has some selectors for program filters also to not duplicate too much cause
it doesn't have same ids/classnames (but it will be replaced soon by another filter type) */

#vrtx-listing-filters {
  margin-bottom: 60px;

  ul {
    margin: 0;
    padding-left: 0;
  }

  &:after {
    .clearfix();
  }
}

#main {
  .vrtx-listing-filter-parameter,
  #vrtx-program-filters li {
    .mixin-small;
    margin-bottom: 15px;
  }
  #vrtx-program-filters li,
  .vrtx-listing-filter-parameter {
    &:before {
      display: none;
    }
    line-height: 3rem;
    margin-left: 0;

    a {
      .checkbox-style();

      &[aria-checked='true'] {
        .checkbox-style-checked();
      }
    }
    &[aria-checked='true'],
    &.selected,
    &.vrtx-listing-filter-parameter-selected {
      a {
        .checkbox-style-checked();
      }
    }
  }
}

#main {
  .listing-filters-hidden {
    display: none;
  }
  .vrtx-listing-filters-expand {
    display: block;
    margin-bottom: 30px;
  }
}

.vrtx-paging-feed-wrapper + #vrtx-listing-filter-status {
  margin-top: 10px;
}

#vrtx-listing-filter-no-results {
  margin: 10px 0 15px 0;
}

@media @responsiveBreakpoint {

  #vrtx-listing-filters,
  #vrtx-program-filters {
     display: none !important;

     &.responsive-hidden-before-ready {
       display: block !important;
     }
   }

   .vrtx-listing-filters-expand {
     display: none !important;
   }

   .listing-filters-hidden {
     display: block !important;
   }

   #main {
     #vrtx-program-filters,
     #vrtx-program-filters-topic,
     #vrtx-program-filters-type,
     #vrtx-program-filters-topic div.only-fagomraader,
     #vrtx-program-filters-type div.only-typer,
     #vrtx-program-filters h2,
     ul.vrtx-programs,
     ul.vrtx-programs li,
     ul.vrtx-programs h2,
     ul.vrtx-programs .introduction,
     #vrtx-program-links,
     #vrtx-listing-filters h2,
     #vrtx-listing-filters .vrtx-listing-filters-section {
       float: none;
       width: auto;
     }
   }

   #vrtx-listing-filter-results {
     li {
       margin-bottom: 30px;

       &:last-child {
         margin-bottom: 0;
       }
     }
   }

   #vrtx-listing-filters {
     background: none;
     margin-bottom: 10px;

     .vrtx-listing-filters-section {
       margin: 0 0 10px 0;
       padding-left: 0;
       padding-right: 0;

       &:last-child {
         margin-bottom: 0;
       }
     }
   }
}
