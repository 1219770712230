.info-link-wrapper {
  background: #18191C;
  padding: 10px 20px;

  *,
  a {
    color: #fff;
  }
  a {
    .textUnderline(#fff);

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
  .info-link-close {
    height: 1em;
    width: 1em;
    display: inline-block;
    margin-left: 5px;
    text-decoration: none;
    .transform(rotate(45deg));
    transform-origin: 4px 100%;

    &:before {
      content: "+";
      height: 100%;
      width: 100%;
      font-size: 2.8rem;
      line-height: 0;
    }

    &:hover,
    &:focus {
      &:before {
        color: #999;
      }
    }
  }
}
