@import "../../../../vrtx/groups/all/src/resources/css/tabs.less";

// New

.vrtx-tabs {
  margin: 20px 0;
  .mixin-small;
  position: relative;

  > ul {
    margin: 0;
    display: flex;
    overflow: visible !important;

    li {
      &:before {
        display: none;
      }
      
      border: none;
      position: relative;
      top: 1px;
      margin: 1px .3em 0 0;
      white-space: nowrap;
      background: transparent;

      &[aria-selected="true"] {
        background: transparent;
        border-bottom: 1px solid #fff;
        z-index: 1;

        a {
          background-color: #272727;
          color: #ffffff;

          &:after {
            position: absolute;
            left: 50%;
            margin-left: -15px;
            bottom: -10px;
            display: block;
            content: " ";
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 12px 15px 0;
            border-color: #272727 transparent transparent;
          }
        }
      }
      a {
        color: #000000;
        background-color: #d9d9d9;
        padding: 14px 15px 12px 15px;
        display: block;
      }
    }
  }
  > div:not(.more-options-dropdown) {
    border: none;
    padding: 30px 0;

    >*:first-child {
      margin-top: 0;
    }
    >*:last-child {
      margin-bottom: 0;
    }
    &[aria-hidden="true"] {
      display: none;
    }
  }

  p.vrtx-more-external-publications {
    margin: 15px 0 0 0;
    padding: 0;
    border-top: none;
  }

  .vrtx-publication-summary {
    display: block;
    text-indent: 0;

    a.vrtx-publication-summary {
      display: inline-block;
      margin-top: 5px;

      &:before {
        content: "+";
        font-size: 1.1em;
        width: 0.7em;
        height: 0.7em;
        margin: 0 5px -2px 0;
        display: inline-block;
      }

      &.visible {
        &:before {
          content: "-";
        }
      }
    }
    p {
      max-width: 100%;
      margin-top: 20px;
    }
  }
}


// Old

.ui-helper-clearfix:after {
  clear: both;
}

.ui-helper-clearfix:after,
.ui-helper-clearfix:before {
  content: "";
  display: table;
  border-collapse: collapse;
}

#vrtx-publications-wrapper {
  clear: left;

  .ui-tabs,
  .vrtx-tabs {
    > div:not(.more-options-dropdown) {
      li {
        &:before {
          display: none;
        }

        margin: 15px 0;
        padding-bottom: 15px;
        border-bottom: 3px solid #eaeaea;
        padding-left: 35px;
        text-indent: -35px;

        &:last-of-type {
          border-bottom: none;
        }
      }
    }
  }
}

.ui-tabs.ui-widget.ui-widget-content {
  margin: 20px 0;
  .mixin-small;

  .ui-tabs-nav {
    margin: 0;

    li {
      &:before {
        display: none;
      }
      border: none;
      float: left;
      position: relative;
      top: 1px;
      margin: 1px .3em 0 0;
      white-space: nowrap;
      background: transparent;

      &.ui-state-focus,
      &.ui-state-hover {
        background: #ccc;
      }
      &.ui-state-active {
        background: transparent;
        border-bottom: 1px solid #fff;
        z-index: 1;

        a {
          background-color: #272727;
          color: #ffffff;

          &:after {
            position: absolute;
            left: 50%;
            margin-left: -15px;
            bottom: -10px;
            display: block;
            content: " ";
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 12px 15px 0;
            border-color: #272727 transparent transparent;
          }
        }
      }
      a {
        color: #000000;
        background-color: #d9d9d9;
        padding: 14px 15px 12px 15px;
        display: block;
      }
    }
  }
  .ui-tabs-panel {
    border: none;
    padding: 30px 0;

    >*:first-child {
      margin-top: 0;
    }
    >*:last-child {
      margin-bottom: 0;
    }
  }

  p.vrtx-more-external-publications {
    margin: 15px 0 0 0;
    padding: 0;
    border-top: none;
  }
}

.ui-tabs.ui-widget.ui-widget-content.tighter {
  .ui-tabs-nav {
    li {
      a {
        padding: 12px 10px 10px 10px;
      }
    }
  }
}

#main .vrtx-publications {
  margin-left: 0;

  li {
    &:before {
      display: none;
    }

    margin: 15px 0;
    padding-bottom: 15px;
    border-bottom: 3px solid #eaeaea;
    padding-left: 35px;
    text-indent: -35px;

    &:last-of-type {
      border-bottom: none;
    }
  }
}

#vrtx-publication-tabs {
  ul {
    margin: 0;

    &.ui-tabs-nav {
      overflow: visible; // UIOWEB-489
    }
  }

  .ui-tabs-panel li {
    &:before {
      display: none;
    }

    margin: 15px 0;
    padding-bottom: 15px;
    border-bottom: 3px solid #eaeaea;
    padding-left: 35px;
    text-indent: -35px;

    &:last-of-type {
      border-bottom: none;
    }
  }
  .vrtx-publication-summary {
    display: block;
    text-indent: 0;

    a.vrtx-publication-summary {
      display: inline-block;
      margin-top: 5px;

      &:before {
        content: "+";
        font-size: 1.1em;
        width: 0.7em;
        height: 0.7em;
        margin: 0 5px -2px 0;
        display: inline-block;
      }

      &.visible {
        &:before {
          content: "-";
        }
      }
    }
    p {
      max-width: 100%;
      margin-top: 20px;
    }
  }
}

@media only screen and (min-width: 1251px) {
  .vrtx-tabs {
    .more-options-dropdown,
    .toggle-more-options-dropdown {
      display: none;
    }
  }
}

@media only screen and (max-width: 1250px) {
  #main {
    .vrtx-tabs,
    .ui-tabs {
      padding: 0;
    }
    .vrtx-tabs,
    .vrtx-tabs > div:not(.more-options-dropdown),
    .ui-tabs,
    .ui-tabs-panel {
      border-left: none;
      border-right: none;
    }
    .vrtx-tabs > ul > li,
    .ui-tabs-nav li {
      display: none;
      max-width: 35%;
      font-weight: bold;

      &[aria-selected="true"],
      &.ui-tabs-active {
        border-left: none;
        display: block;
        max-width: 61%;
      }
      &.toggle-more-options-dropdown {
        display: block;
        border: none;
        border-top: 1px solid #eee;
        font-weight: normal;

        a {
          padding-right: 28px;
          background: #d9d9d9 url("@{imagePath}responsive/svg/icon-triangle-closed.svg") no-repeat 100% 11px;
        }
      }
      a {
        padding: 12px 15px 8px 15px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        word-wrap: normal;
      }
    }

    /* More options dropdown */

    .ui-tabs .more-options-dropdown {
      left: 15px !important;
    }

   .more-options-dropdown {
      display: none;
      position: absolute;
      top: 0px;
      background: #fff;
      border: 1px solid #000;
      z-index: 2;
      width: 100%;
      max-width: 320px;
      margin-top: 15px;

      a {
        word-break: normal;
      }
      ul {
        padding: 0;
        margin: 0;
        width: 100%;
        float: left;
      }
      li {
        &:before {
          display: none;
        }

        padding: 0;
        margin: 0;

        &.selected {
          display: none;
        }
        a {
          padding: 1.1ex 1.1ex 1.1ex;
          display: block;
        }
      }

      &.opened {
        display: block;
      }
    }

    .more-options-dropdown-title {
      background: #272727;
      color: #fff;
      padding: 0.5ex 1.1ex;
      display: block;
      font-weight: bold;
      width: 100%;
      float: left;
    }
  }
  .en #main .vrtx-tabs > ul > li,
  .en #main .ui-tabs-nav li {
    max-width: 44%;

    &.ui-tabs-active {
      max-width: 52%;
    }
  }
}

@media only screen and (max-width: 1250px) and (min-width: @responsiveBreakpointPx) {
  #main .ui-tabs .more-options-dropdown {
    left: auto !important;
  }
}

@media only screen and (max-width: @responsiveBreakpointPx) {
  #main .ui-tabs .more-options-dropdown {
    left: 15px !important;
  }
  #main .vrtx-tabs .more-options-dropdown {
    left: 0 !important;
  }
}
