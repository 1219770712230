@stepGuideBgColor: #ebedfc;
@stepGuideHoverColor: #D7DAF0;
@stepGuideActiveColor: #000;

// horisontal / default
body .main .uio-main {
  ul.steps-guide {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 30px 10px;
    overflow: visible !important;

    > li {
      padding: 40px 20px 40px 20px;
      margin: 0px;
    }
  }
  ul.steps-guide,
  ul.steps-guide-vertical {
    padding-top: 30px;
    max-width: 1000px;
    counter-reset: step;
    > li {
      background: @stepGuideBgColor;
      position: relative;

      span.steps-title,
      a {
        display: inline;
        .mixin-h5;
        color: #000000;
        padding-bottom: 3px;
        .textUnderline(@textColorUnderline);
      }
      &::before  {
        width: 40px;
        height: 40px;
        top: -22px;
        left: 40px;
        counter-increment: step;
        content: counter(step);
        line-height: 40px;
        text-align: center;
        background: #fff;
        border-radius: 50%;
        font-weight: bold;
        position: absolute;
        border: 3px solid @stepGuideBgColor;
        font-size: 2rem;
      }
      ul {
        margin: 0;
        width: 100%;
        > li  {
          padding: 0;
          margin: 30px 0 0 0;
          width: 100%;
          overflow: hidden;
          background: transparent;
          &:first-child {
            margin-top: 10px;
          }
          a  {
            .baseFontSize;
            line-height: 1;
          }
          &::before {
            display: none;
            width: 0px;
            height: 0px;
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
      &:hover,
      &:focus {
        background: @stepGuideHoverColor;
        &::before  {
          border: 3px solid @stepGuideHoverColor;
        }
      }
      /* markert */
      &.steps-guide-active {
        &::before  {
          border: 3px solid @stepGuideActiveColor;
          background: @stepGuideActiveColor;
          color: #fff;
        }
      }
      a.wrap {
        width: 100%;
        height: 100%;
        height: calc(100% + 20px);
        position: absolute;
        left: 0;
        top: -20px;
      }
    }
    &.only-links {
      li {
        margin-top: 0px;
        margin-bottom: 20px;
        a:before {
          background-size: 70% auto;
        }
      }
    }
  }
}
body .main .uio-main ul.steps-guide-five-columns {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  max-width: 1200px;
}
body .main .uio-main ul.steps-guide-vertical {
  display: block;
  padding-left: 32px;
  > li {
    width: 100%;
    max-width: ~"calc(@{bodytextContentWidth} - 32px)";
    display: block;
    float: none;
    padding: 26px 20px 28px 40px;
    margin: 0 0 10px 0;
    margin-left: 0;
    margin-right: 0;
    &:first-child {
      margin-top: 0px;
    }
    a {
      .mixin-h5;
    }
    a.wrap {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
    &::before  {
      left: -6px;
      top: 20px;
      counter-increment: step;
      content: counter(step);
    }
  }
}
@media @responsiveBreakpoint {
  body .main .uio-main {
    ul.steps-guide,
    ul.steps-guide-vertical  {
      display: block;
    }
    ul.steps-guide:not(.steps-guide-vertical) {
      li {
        margin-bottom: 30px;
      }
    }
  }
}
