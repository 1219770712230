.vrtx-media-player-no-flash,
.vrtx-media-player {
  margin: 20px 0;
}

.fullscreen() {
  width: 100% !important;
  height: 100% !important;
  max-height: 100% !important;
}
audio {
  max-width: 100%;
}

.vrtx-media-player-print { display: none; }
.vrtx-media-player-no-flash {
  background-color: #fff;
  position: relative;

  video {
    max-width: 100%;
  }

  img {
    width: 507px !important;
    height: auto !important;
    max-height: 322px;
  }

  // Fullscreen
  video:-webkit-full-screen { .fullscreen(); }
  video:-moz-full-screen    { .fullscreen(); }
  video:-ms-fullscreen      { .fullscreen(); }
  video:fullscreen          { .fullscreen(); }

  .playbutton {
    position: absolute;
    top: 90px;
    left: 195px;
    width: 115px;
    height: 106px;
    display: block;
    background: url('/vrtx/__vrtx/static-resources/themes/default/icons/video-playbutton.png') no-repeat center center;
  }

  .vrtx-media:hover .playbutton,
  .playbutton:hover {
    background-image: url('/vrtx/__vrtx/static-resources/themes/default/icons/video-playbutton-hover.png');
  }
}

.vrtx-media-ref {
  margin: 20px 0 10px 0;
}

.vrtx-media-player,
.vrtx-media-ref {
  iframe {
    margin: 0;
    max-width: 100%;
  }
}

.iframe-wrapper-responsive {
  > iframe[src^="https://vrtx.uio.no/videojs/"] {
    max-width: 100%;
  }
}

a.vrtx-media {
  clear: left;
  display: block;
  margin: 10px 0 0 0;

  img {
    max-height: 279px;
    height: auto;
    width: auto;
  }
}

.responsive-media-player-padding-restored {
  padding-bottom: 0 !important;
}

/* New functions */

.vrtx-media-button-holder {
  display: inline-flex;
}

.vrtx-media-player-parallel-buttons {
  margin-top: 15px;

  a {
    padding-right: 20px;

    &:before {
      background-size: 100% auto;
      display: inline-block;
      content: "";
      width: 1.3em;
      height: 1.3em;
      position: relative;
      margin-right: 5px;
    }
  }
}

.vrtx-media-player-download-button a::before {
  background: url("@{imagePath}media/download.svg") no-repeat;
  top: .3ex;
}

#vrtx-media-player-embed-button a::before {
  background: url("@{imagePath}media/embed.svg") no-repeat;
  top: .5ex;
  right: .5ex;
}

.embed-video-box {
  padding: 20px 20px 10px;
  position: absolute;
  display: none;
  z-index: 1;
  background: #2771bb;
  margin-left: -110px;
  width:450px;
  margin-top: 20px;
  white-space: normal;
  .mixin-small;
  text-align: left;
}

.embed-video-box:before {
  top: -30px;
  left: 45%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(0, 0, 0, 0);
  border-bottom-color: #2771bb;
  border-width: 15px;
  margin-left: -15px;
}

.embed-video-dimensions {
  margin: 0 0 10px 0;
  padding: 5px 5px 5px 5px;
  text-align: center;
}

.embed-video-dimensions label {
  color:#fff;
  font-size: 2rem;
}

.embed-video-dimensions input {
  margin-right:10px;
  width: 100px;
}

#embed-iframe {
  margin: 20px 0 20px 0;
  width: 100%;
  max-width: 100%;
}

.build-embed-function-link {
  cursor: pointer;
}

@media @responsiveBreakpoint {

  .responsive-media-player {
    position: relative;
    padding-top: 25px;
    height: 0;
    clear: both;

    iframe,
    object {
      position: absolute;
      top: 0;
      left: 0;
      width: 100% !important;
      height: 100% !important;
    }
  }

  .vrtx-media-button-holder {
    display:block;
  }

  .vrtx-media-player-parallel-buttons {
    width: 300px;
  }

  .embed-video-box {
    right: 0;
    width: 85vw;
    min-width: 275px;
    max-width: 350px;
    margin-right: 6vw;
  }

  .embed-video-box:before {
    left: 15%;
  }

  #embed-iframe {
    max-width: 310px;
    width:75vw;
  }

  .embed-video-dimensions {
    display:grid;
    grid-template-columns: auto auto;
    align-items: center;
    text-align: center;
  }

  .embed-video-dimensions label {
    display:inline-block;
  }

  .embed-video-dimensions input {
    padding: 5px 5px 5px 5px;
    text-align: center;
  }

  #vrtx-media-player-embed-button a::before {
      right: 0;
  }

}
