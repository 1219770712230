#vrtx-course-description h1 {
  margin-bottom: 80px;
}

#vrtx-course-description .vrtx-facts {
  max-width: @h1IntroWidth;
  margin-bottom: 15px;
  padding: 8px 14px 7px 14px;
  border: solid 1px @borderColor;
  display: inline-block;
  // Clear inline block
  float: left;
  clear: both;

  h2 {
    display: none;
  }

  *,
  h3 {
    .mixin-small;
    display: inline-block;
    margin: 0;
  }

  h3,
  dt {
    font-weight: bold;
    &:after {
      content: ":";
    }
  }
  p,
  dd {
    font-weight: normal;
    margin-left: .25em;
    margin-right: 15px;
  }
  dd li {
    &:before {
      display: none;
    }
    &:after {
      content: ",";
    }
    &:last-child:after {
      content: "";
    }
  }

  .vrtx-fact-info {
    display: inline;

    .vrtx-fact-info-content {
      display: inline;
    }
    .vrtx-fact-info-content * {
      display: inline;
    }
  }
}

#vrtx-course-toc {
  float: left;
  clear: both;
  //width: ~"calc(100% - calc(calc(100%) - @{h1IntroWidth}))";
  width: ~"calc(100vw - 220px)";
  max-width: ~"min(1000px, calc(100vw - 245px))";

  h2 {
    margin-top: 45px; // +15px facts=60px total, because float-clear-fix
  }

  ul {
    margin-bottom: 0;
    li {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

#vrtx-course-semesters {
  padding: 50px;
  background: @green;
  max-width: @h1IntroWidth;

  p {
    margin-top: -5px;
  }
  ul {
    margin-bottom: 0;
    li:last-child {
      margin-bottom: 10px;
    }
  }
}
#toggle-program-option-list-inactive,
#vrtx-course-more-semesters-toggle {
  border-top: 1px solid #9b9b9b;
  border-bottom: 1px solid #9b9b9b;
  margin: 25px 0 0 0;
  padding: 8px 0;
  display: block;
  color: @textColor;
  position: relative;
  text-decoration: none;
  clear: left;

  &:hover,
  &:focus {
    .textUnderline(@textColor);
  }

  &:before  {
    display: none;
  }

  &:after {
    .mixin-tiny;
    width: .6em;
    height: .4em;
    content: "+";
    display: block;
    position: absolute;
    right: 0;
    top: 50%;
    line-height: 0;
  }

  &.toggle-program-list-inactive-visible {
    &:after {
      content: "-";
    }
  }
}
#vrtx-course-more-semesters-toggle {
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
}
#vrtx-course-more-semesters {
  ul {
    margin-top: 10px;
  }
}

@media @desktopSmallBreakpoint {
  #vrtx-course-toc {
    width: ~"calc(100vw - 40px)";
    max-width: ~"calc(100vw - 40px)";
  }
}

@media @responsiveBreakpoint {

  #vrtx-course-description .vrtx-facts {
    padding: 5px 10px;
  }
  #vrtx-course-toc {
    width: auto;
  }
}
