#vrtx-collection {
  #main .vrtx-resources {
    margin-top: -1ex;

    .vrtx-resource {
      padding-bottom: 20px;
      margin-bottom: 20px;
      position: relative;

      &:last-child {
        padding-bottom: 0;
        border-bottom: none;
      }

      .vrtx-icon {
        background: url('@{vortexStaticResources}file-big.png') no-repeat top left;
        width: 36px;
        height: 55px;
        float: left;
        display: block;
        margin: 1ex 20px 0 0;

        &.binary   { background-image: url('@{vortexStaticResources}file-binary-big.png'); }
        &.dmg      { background-image: url('@{vortexStaticResources}file-dmg-big.png'); }
        &.zip      { background-image: url('@{vortexStaticResources}file-zip-big.png'); }
        &.jar      { background-image: url('@{vortexStaticResources}file-jar-big.png'); }
        &.pdf      { background-image: url('@{vortexStaticResources}file-pdf-big.png'); }
        &.doc      { background-image: url('@{vortexStaticResources}file-doc-big.png'); }
        &.xls      { background-image: url('@{vortexStaticResources}file-xls-big.png'); }
        &.ppt      { background-image: url('@{vortexStaticResources}file-ppt-big.png'); }
        &.text     { background-image: url('@{vortexStaticResources}file-txt-big.png'); }
        &.image    { background-image: url('@{vortexStaticResources}file-image-big.png'); }
        &.audio    { background-image: url('@{vortexStaticResources}file-audio-big.png'); }
        &.video,
        &.videoref { background-image: url('@{vortexStaticResources}file-video-big.png'); }

        ~ * {
          margin-left: 60px;
        }
      }

      .vrtx-resource-open-webdav {
        color: @linkColor;
      }

      .vrtx-resource-locked-webdav,
      .vrtx-resource-open-webdav {
        top: 2px;
        position: absolute;
        .baseFontSize;
        display: none;
      }

      > * {
        display: block;
      }
    }
  }
}

/* Regular listing component */

#main .vrtx-collection-listing-component {
  text-align: right;

  table,
  h3 {
    text-align: left;
  }

  h3 + table {
    margin-top: 0;
  }

  table {
    border: none;
    table-layout: fixed;
    border-collapse: separate;
  }

  tr {
    th {
      background: transparent;
      border: none;
      white-space: nowrap;
      padding-left: 0px;

      &.vrtx-collection-listing-title         { width: 40%; padding-left: 15px; }
      &.vrtx-collection-listing-modified-by   { width: 30%; }
      &.vrtx-collection-listing-last-modified { width: 25%; }
    }

    td {
      border: none;
      overflow: hidden;
      padding-left: 0px;
      padding-top: 3px;
      padding-bottom: 3px;
      border-top: 1px solid transparent;
      border-bottom: 1px solid transparent;
      position: relative;

      &.first-col {
        padding-left: 15px;
        border-left: 1px solid transparent;
      }
      &.last-col {
        border-right: 1px solid transparent;
      }
      &.vrtx-collection-listing-title {
        span {
          display: block;
          padding-left: 30px;
        }
      }
      &.vrtx-collection-listing-last-modified-by a {
        color: @textColor;
      }
    }

    &.even td {
      border-color: #f0f0f0;
      background-color: #f9f9f9;
    }
    &:hover,
    &:focus {
      td {
        background-color: #ecf3f9;
        border-color: #c7dadf;
      }
    }
  }
  a.vrtx-icon {
    background: url('@{vortexStaticResources}file-small.gif') no-repeat center left;
    height: 22px;
    width: 30px;
    float: left;
    display: block;

    &.binary   { background: url('@{vortexStaticResources}file-binary-small.gif') no-repeat center left; }
    &.dmg      { background: url('@{vortexStaticResources}file-dmg-small.png') no-repeat center left; }
    &.zip      { background: url('@{vortexStaticResources}file-zip-small.png') no-repeat center left; }
    &.jar      { background: url('@{vortexStaticResources}file-jar-small.png') no-repeat center left; }
    &.doc      { background: url('@{vortexStaticResources}file-doc-small.gif') no-repeat center left; }
    &.pdf      { background: url('@{vortexStaticResources}file-pdf-small.gif') no-repeat center left; }
    &.xls      { background: url('@{vortexStaticResources}file-xls-small.gif') no-repeat center left; }
    &.text     { background: url('@{vortexStaticResources}file-txt-small.gif') no-repeat center left; }
    &.ppt      { background: url('@{vortexStaticResources}file-ppt-small.gif') no-repeat center left; }
    &.image    { background: url('@{vortexStaticResources}file-image-small.gif') no-repeat center left; }
    &.audio    { background: url('@{vortexStaticResources}file-audio-small.gif') no-repeat center left; }
    &.video,
    &.videoref { background: url('@{vortexStaticResources}file-video-small.gif') no-repeat center left; }

    ~ *:not(.vrtx-icon) {
      margin-left: 30px;
      display: block;
    }
  }
}
