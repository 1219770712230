.open-day-heading {
  &:before {
    content: "";
    background-color: black;
    height: 40px;
    width: 40px;
    display: block;
    margin-left: -60px;
    border: 2px solid var(--colorNeutralTint80);
  }
  padding-left: 60px;
}

// TODO: probably need some UiO2 cleanup of seperation between color variables and where its used
.checked-opn .open-day-heading:before {
  background-color: #B60000;
}
.checked-jus .open-day-heading:before {
  background-color: #E6ECFF;
}
.checked-mat .open-day-heading:before {
  background-color: #2EC483;
}
.checked-sam .open-day-heading:before {
  background-color: #CEFFDF
}
.checked-hel .open-day-heading:before {
  background-color: #3E31D6;
}
.checked-his .open-day-heading:before {
  background-color: #FB6666;
}
.checked-med .open-day-heading:before {
  background-color: #FFFEA7;
}
.checked-spr .open-day-heading:before {
  background-color: #B60000;
}
.checked-hon .open-day-heading:before {
  background-color: #FFFFFF;
}
.checked-est .open-day-heading:before {
  background-color: #86A4F7;
}
.checked-rel .open-day-heading:before {
  background-color: #FFEBD9;
}
.checked-ped .open-day-heading:before {
  background-color: #FEA11B;
}

#main #vrtx-main-content .open-day-heading {
  h2 {
    margin-top: -40px;
    margin-bottom: 10px;
  }
}

@media @desktopBreakpoint {
  .open-day-heading {
    &:before {
      position: relative;
      top: 4px;
    }
  }
}
