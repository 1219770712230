// Animation keyframes

.keyframes(pulsate, {
  0%   { .transform(scale(0.5, 0.5)); opacity: 0.5; }
  50%  { opacity: 0.7;                              }
  100% { .transform(scale(1, 1)); opacity: 1;       }
});

.keyframes(pulse1, {
  0%   { .transform(scale(0.1, 0.1)); opacity: 0; }
  50%  { opacity: 1;                              }
  100% { .transform(scale(1, 1)); opacity: 0;     }
});

.keyframes(sonarEffect, {
  0% {
    opacity: 0.5;
  }
  40% {
    opacity: 0.8;
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1), 0 0 7px 5px #fff, 0 0 0 6px rgba(0, 0, 0, 0.2);
  }
  100% {
    opacity: 0;
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1), 0 0 7px 5px #fff, 0 0 0 6px rgba(0, 0, 0, 0.2);
    .transform(scale(1.6));
  }
});

/* Sonar effect: http://tympanus.net/Development/IconHoverEffects/#set-8

&:after {
  content: ' ';
  position: absolute;
  width: 39px;
  height: 39px;
  top: 2px;
  left: 2px;
  .transform(scale(0.9));
  .borderRadius(50%);
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1), 0 0 7px 5px #fff, 0 0 0 6px rgba(0, 0, 0, 0.2);
  opacity: 0;
}

&:hover,
&:focus {
  &:after {
    .animation(sonarEffect, .5s, ease-out);
  }
}
*/
