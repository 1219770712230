// Banner to highlight test-hosts
body[class*="uio-test.uio.no"] {
  &::before {
    content: "Dette er et test-nettsted";
    display: block;
    text-align: center;
    padding: 15px;
    border: 5px dashed #0007;
    position: relative;
    z-index: 10;
    font-size: 30px;
    background: orange;
  }
  &.en::before {
    content: "This is a test-website";
  }
}

@media @responsiveBreakpoint {
  body[class*="uio-test.uio.no"] {
    &::before {
      font-size: 18px;
    }
  }
}