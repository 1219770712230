html {
  font-size: 62.5%; // makes 0.1rem = 1px (browser normally use zoom scaling,
                    //                     but support font scaling also with font-size/line-height in rem)
  scroll-behavior: smooth;
}

body {
  font-family: @mainFontStack;
  color: @textColor;
  .baseFontSize;
}

* {
  .boxSizing (@border: border-box);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.small-text {
  .mixin-small;
}

p + p.small-text {
  margin-top: -10px;
}

ul {
  list-style-type: none;
}

.main {
  ul, ol {
    margin: 10px 0 25px 0;
    padding-left: 2em;

    ul, ol {
      margin-bottom: 0;
    }

    li {
      > p:first-child {
        margin-top: 0;
      }
      > p:last-of-type {
        margin-bottom: 0;
      }
    }
  }
  li {
    margin-bottom: 10px;
  }
  ul li {
    position: relative;

    &:last-child {
      margin-bottom: 0;
    }
    &:before {
      content: '\25A0';
      position: absolute;
      top: .7ex;
      margin-left: -1.8em;
      font-size: .6em;
      text-align: left;
      display: inline-block;
      width: 20px;
      height: 2em;
    }

    li {
      &:before {
        content: '\25A1';
      }
      li {
        &:before {
          content: '\25C7';
        }
      }
    }

    &.success:before {
      content: @listSuccessChar;
      color: @listSuccessColor;
      font-size: 1.6rem;
    }
  }
  ul,
  ol {
    p + ul,
    p + ol {
      margin-top: 0;
    }
  }
}

#vrtx-program-listing .vrtx-programs-inactive ul,
#vrtx-program-frontpage .vrtx-program-options ul,
#vrtx-program-links ul,
#vrtx-program-option .vrtx-program-title-box ul,
#vrtx-course-toc ul,
#vrtx-course-semesters ul,
#vrtx-semester .vrtx-teacher-list-participants,
#vrtx-semester-resource-links ul,
.main .only-links {
  &:not(.right):not(.thirds-middle):not(.thirds-right) {
    padding-left: 0;
  }
}

// Custom only links (not on frontpage)
#vrtx-program-listing .vrtx-programs-inactive,
#vrtx-program-frontpage .vrtx-program-options,
#vrtx-program-links,
#vrtx-program-option .vrtx-program-title-box,
#vrtx-course-toc,
#vrtx-course-semesters ul,
#vrtx-semester .vrtx-teacher-list-participants,
#vrtx-semester-resource-links,
// Regular on frontpage
.main ul.only-links {
  li {
    margin-left: 0;
    margin-bottom: 10px;

    &:before {
      display: none;
    }
    a:not(.button):not(.button-large) {
      //color: @textColor;
      padding-left: 34px;
      display: inline-block;

      &:before {
        content: "";
        background: url("@{imagePath}arrows/arrow-big.svg") no-repeat 0 0;
        background-size: 100% auto;
        height: 1em;
        width: 1em;
        display: inline-block;
        position: absolute;
        left: 0;
        top: .7ex;
        transition: left .3s;
      }

      // text-decoration: none;
      .textUnderlineSwitch;
      &:hover,
      &:focus {
        //padding-left: 40px;
        //color: @linkColor;
        //.textUnderlineHover(@linkColor);
        &:before {
          //background-image: url("@{imagePath}arrows/arrow-big-blue.svg");
          left: .8ex;
        }
      }
    }
  }
}

#vrtx-program-listing .vrtx-programs-inactive,
#vrtx-program-frontpage .vrtx-program-options,
#vrtx-program-links,
#vrtx-program-option .vrtx-program-title-box,
#vrtx-course-toc,
#vrtx-course-semesters ul,
#vrtx-semester .vrtx-teacher-list-participants,
#vrtx-semester-resource-links,
.main ul.only-links:not(.animation-off) {
  li {
    a:not(.button):not(.button-large) {
      .transition(padding, .2s, ease-in-out);
    }
  }
}

dl dl {
  margin: 10px 0;
}

dt {
  font-weight: 700;
}
dd {
  margin: 0 0 10px 0;
}

blockquote {
  .mixin-introduction;
  margin: 80px 0 80px 0;
  font-family: @secondaryFontStack;
  padding-left: 60px;

  > *:first-child {
    margin-top: 0;
  }
  > *:last-child {
    margin-bottom: 0;
  }

  &.blockquote-large {
    font-size: 4.5rem;
    line-height: 5.3rem;
    padding-left: 0;
    width: var(--content-width);
    margin-left: ~"calc(calc(calc(calc(var(--content-width) - calc(100%))/ 2)) * -1)";
  }

  cite {
    .baseFontSize;
    font-family: @mainFontStack;
    margin-top: 40px;
    display: block;
    font-style: normal;
  }
}

blockquote.blockquote-small {
  .baseFontSize;
  margin: 30px 0 70px 50px;
  border-left: 2px solid #000;
  font-family: @secondaryFontStack;

  > *:first-child {
    margin-top: 10px;
  }
  > *:last-child {
    margin-bottom: 10px;
  }

  // Align line at left side with text
  &:before,
  &:after {
    content: " ";
    display: block;
    border-left: 2px solid #fff;
    margin-left: -52px;
    height: 1px;
  }
  &:before {
    margin-bottom: -.7ex;
  }
  &:after {
    margin-top: -.7ex;
  }

  cite {
    font-size: 1.9rem;
    line-height: 2.8rem;
    font-family: @mainFontStack;
    margin-top: 20px;
    display: block;
    font-style: italic;
  }
}

hr {
  width: 100%;
  clear: left;
  border-bottom: 1px solid @borderColor;
  margin: 40px 0;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
}

h1 {
  .mixin-h1;
  text-wrap: balance;
}

.vrtx-toc-header,
h2 {
  .mixin-h2;
}

.display-as-h3,
.vrtx-toc-style-like-h3 .vrtx-toc-header,
h3,
caption {
  .mixin-h3;
}

.display-as-h4,
.vrtx-toc-style-like-h4 .vrtx-toc-header,
h4 {
  .mixin-h4;
}

h5 {
  .mixin-h5;
}

h6,
small {
  .mixin-small;
}

h1                   { margin-bottom: 40px; }
h2, .vrtx-toc-header { margin-top: 60px; margin-bottom: 25px; }
h3                   { margin-top: 30px; margin-bottom: 15px; }
h4, h5               { margin-top: 25px; margin-bottom: 10px; }

h2 + h3,
h3 + h4,
h4 + h5 {
  margin-top: 0;
}

p {
  margin: 10px 0 25px;
}

a {
  color: @textColor;
  .textUnderline;

  &:focus,
  &:hover {
    text-decoration-color: transparent;
  }

  &[name] {
    color: @textColor;
    text-decoration: none;
  }
}

code {
  white-space: normal; // Remove?
}

pre {
  background-color: #eceff8;
  margin: 15px 0;
  padding: 15px 15px 15px 16px;
  white-space: pre;
  overflow: auto;
  overflow-y: hidden;
  border: 2px dashed #DBE0F1;
  border-left: 6px solid #DBE0F1;
  font-family: monospace, monospace;
  color: #222;
  .mixin-tiny;

  &.shell,
  &.shell-green {
    background: #151515;
    border: none;
    color: #eee;
  }
  &.shell-green {
    color: #2EC483;
  }
}

pre code {
  font-family: inherit;
  white-space: inherit;
}

sub,
sup {
  position: relative;
  font-size: 0.813em;
  line-height: 0;
  height: 0;
}

sup { bottom: 1ex; }
sub { top: 0.8ex;  }

// Responsive only

@media @responsiveBreakpoint {

  h1             { margin-bottom: 20px; }
  h2, h3, h4, h5 { margin-top: 40px; margin-bottom: 10px; }

  p,ul,ol {
    margin-bottom: 20px;
  }
  p + p.small-text {
    margin-top: -10px;
  }
  .main {
    p + ul,
    p + ol {
      &,
      + ol.right,
      + ul.right,
      + ul.thirds-middle,
      + ol.thirds-middle,
      + ul.thirds-right,
      + ol.thirds-right {
        margin-top: -10px;
      }
    }
    ul,
    ol {
      p + ul,
      p + ol {
        margin-top: 0;
      }
    }
  }
}

// Map fix (move to proper place)
#main .you-are-here-wrapper {
  margin-left: ~"calc(calc(calc(100%) - 690px - calc(calc(100%) - 1040px))/ 2)" !important;
  margin-right: auto !important;
}
@media @responsiveBreakpoint {
  #main .you-are-here-wrapper {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
