// CSS for disted component: all/src/components/menu/language.js

@headerLanguageTooltipBg: #312D28;

#header-language {
  position: relative;
  margin-right: 40px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: min-content;

  > span,
  > a {
    border: 1px solid transparent;
    display: block;
    padding: 0 7px;
    font-size: 1.6rem;
    line-height: 2.4rem;
    text-transform: uppercase;
  }

  > span {
    border-color: #000;
  }
  > a {
    text-decoration: none;
    display: block;
  }

  #header-language-tooltip {
    background: @headerLanguageTooltipBg;
    padding: 20px 80px 16px 20px;
    position: absolute;
    top: 30px;
    right: 0;
    color: #fff;
    font-size: 1.6rem;
    line-height: 2.5rem;
    min-width: 380px;

    #header-language-tooltip-close {
      height: 1.1em;
      width: 1.1em;
      display: inline-block;
      position: absolute;
      top: 9px;
      right: 9px;
      background: url("/vrtx/dist/resources/images/close.svg") no-repeat 0 0;
      background-size: 100% auto;
    }

    >*:not(:last-child):not(:nth-last-child(2)) {
      font-weight: bold;
    }

    a {
      color: #fff;
      text-decoration-color: #fff;

      &:hover,
      &:focus {
        text-decoration: none;
      }
    }

    display: none;
    &.visible {
      display: block;
    }

    p {
      margin-top: 0;
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

body.en #header-language {
  #header-language-tooltip {
    min-width: 420px;
  }
}

@media @responsiveBreakpoint {
  #header-language {
    display: none;
  }
  body.sidebar-menu-wrapper-visible {
    #header-language {
      display: grid;
    }
  }
  body.en #header-language {
    #header-language-tooltip {
      min-width: 0;
    }
  }
  #header-language {
    margin-right: 10px;
    #header-language-tooltip {
      padding-right: 30px;
      width: ~"calc(100vw - 30px)";
      max-width: ~"min(calc(100vw - 30px), 380px)";
      min-width: 0;
      right: -47px;
    }
  }
}

/* Hide if still has language in #left-main */
#left-main .sidebar-services-language-menu li.language,
.sidebar-menu-wrapper-visible #main #left-main .sidebar-services-language-menu,
.sidebar-menu-wrapper-visible #main #left-main .sidebar-services-language-menu .language,
body:not(.sidebar-menu-wrapper-visible) #left-main .language-switch-link {
  display: none;
}
#left-main .language-switch-link,
/* Hide document language switch link as it is copy/pasted into head menu instead if exists */
#vrtx-person-change-language-link,
#vrtx-program-option-frontpage-change-language-link,
#vrtx-program-frontpage-change-language-link,
#vrtx-fs-studieprogram-frontpage-change-language-link,
#vrtx-fs-studieretning-frontpage-change-language-link,
#vrtx-hvordan-soke-change-language-link,
#vrtx-hvordan-soke-tilpasset-change-language-link,
#vrtx-oppbygning-change-language-link,
#vrtx-organizational-unit-change-language-link,
#vrtx-internal-organizational-unit-corresponding-folder-link,
#vrtx-change-language-link,
#vrtx-course-navigation {
  display: none;
}