@import 'documents/program.less';
@import 'documents/course.less';
@import 'documents/semester.less';
@import 'documents/exchange.less';
@import 'documents/schedule.less';
@import 'documents/structure.less';

#vrtx-course-description #vrtx-additional-content .vrtx-frontpage-box:first-child,
body:not(#vrtx-frontpage) .vrtx-frontpage-box.grey-box {
  background: #f4f6ff;
  padding: 20px;
}

.vrtx-context-box.vrtx-context-box-linked {
  .mixin-h4;
  margin: 30px 0 10px 0;

  a {
    color: @textColor;
    .textUnderline(@textColorUnderline);

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
}

// Messages
.vrtx-message-listing-create,
.vrtx-message-listing-edit {
  .baseFontSize;
  float: right;
}

.vrtx-messages-header > h2 {
  margin: 0 0 40px 0;
}

.vrtx-messages {
  .vrtx-resource {
    display: flex;
    flex-direction: column;
    margin-bottom: 60px;
  }
  .vrtx-message-line {
    order: 1;
    color: @publishedDateGrey;
    .baseFontSize;
    line-height: 2.3rem;
    margin-bottom: 10px;
  }
  .vrtx-title {
    order: 2;
    .mixin-h4;
    color: @textColor;

    &:hover,
    &:focus {
      color: @linkColor;
    }
  }
  .description {
    order: 3;
    margin-top: 20px;
    .firstLastChildNoMargin();
  }
  .vrtx-read-more {
    order: 4;
    margin-top: 10px;
  }
}

// Contact box
#vrtx-course-group #vrtx-additional-content,
.vrtx-program-contact-box {
  background: #ceffdf;
  padding: 50px;
}
#vrtx-course-group #vrtx-additional-content {
  #vrtx-related-content {
    >*:first-child {
      margin-top: 0;
    }
    >*:last-child {
      margin-bottom: 0;
    }
  }
}

// Tmp fix for admission
#vrtx-structured-article #main #vrtx-main-content.vrtx-hide-additional-content-true {
  width: auto;
}


@media @responsiveBreakpoint {


  #main #vrtx-main-content .vrtx-program-contact-box {
    padding: 30px 15px;
  }

  .vrtx-messages {
    .vrtx-resource {
      padding-bottom: 25px;
      border-bottom: 1px solid @borderColor;
      margin-bottom: 25px;

      &:nth-last-child(2) {
        padding-bottom: 0;
        border-bottom: none;
      }
    }
  }
}
