html.special-page-config,
html.special-page-config-header-minimal {
  //body.vortex-test\.uio\.no,
  body.ritmo {
    .vrtx-frontpage-box {
      font-size: 20px;
      line-height: 1.6;
      color: #444;
    }

    #main {
      font-family: dosis, impact,sans-serif;

      h1 {
        font-size: 95px;
        font-weight: 700;
        margin-bottom: 70px;
      }

      .introduction-div, .vrtx-frontpage-box.introduction .vrtx-box-content, .vrtx-introduction, .vrtx-introduction-big, .vrtx-organizational-unit-introduction {
        p {
          font-family: nunito,sans-serif;
        }
      }

      p, li {
        font-family: nunito,sans-serif;
      }
      p.quoteby {
        font-family: dosis,impact,sans-serif;
      }
    }

    #main blockquote {
      &:before {
        color: #004d61;
      }
      &,
      p {
        font-family: nunito, sans-serif;
      }
    }

    #vrtx-main-content .special-logo-video-grid-container + .grid-container .vrtx-frontpage-box:first-child,
    #vrtx-main-content .special-logo-image-grid-container + .grid-container .vrtx-frontpage-box:first-child,
    #vrtx-main-content > .grid-container:first-child .vrtx-frontpage-box:first-child {
      .vrtx-box-content > p:first-child {
        &:first-letter {
          font-family: dosis,impact,sans-serif;
          color: #004d61;
        }
      }
    }

    #main .special-content-fullwidth-grid-container {
      &,
      .vrtx-frontpage-box {
        background: #000;
      }

      .row {
        *,
        .vrtx-container, .vrtx-image-listing-include-container-description, .vrtx-imagetext, .vrtx-img-container, figcaption,
        a {
          color: #fff;
        }

        .vrtx-image-listing-toggle-thumbnail-view:before {
          background-image: url(/vrtx/dist/resources/uio2/css/images/gallery/thumbnail-view-open-white.svg);
        }
        .vrtx-image-listing-toggle-thumbnail-view.thumbnail-view-close:before {
          background-image: url(/vrtx/dist/resources/uio2/css/images/gallery/thumbnail-view-close-white.svg);
        }
        .vrtx-image-listing-show-fullscreen-wrapper a:before {
          background-image: url(/vrtx/dist/resources/uio2/css/images/gallery/fullscreen-open-white.svg);
        }
      }
    }

    .special-lead p,
    .vrtx-introduction {
      font-size: 26px;
      font-weight: 700;
    }

    #main #vrtx-content {
      h1, h2, h3, h4, h5, h6 {
        font-family: dosis, impact,sans-serif;
      }
      h1, h2, h3 {
        font-weight: 700;
      }
      h2 {
        font-size: 45px;
        margin-bottom: 30px;
      }

      .half-box-left,
      .half-box-right {
        h2 {
          font-size: 35px;
          margin-bottom: 30px;
        }
      }
      h3 {
        font-size: 35px;
        margin-bottom: 30px;
      }
      .vrtx-frontpage-box.special-big-title {
        >h2 {
          font-size: 70px;

          &:before {
            top: 118px;
          }
        }
        /*
        &.grey-box {
          >h2:before {
            top: 158px;
          }
        }
        */
      }
    }

    #main {
      .special-pinned-video-grid-container,
      .special-pinned-grid-container {
        h2 {
          color: #004d61;
        }
      }
    }

    .special-lead p,
    .vrtx-introduction,
    h1, #main h2, #main h3, #main h4, #main h5, #main h6,
    #main a {
      color: #004d61;
    }
    #main a {
      .textUnderline(rgba(0, 77, 97, 0.6));
      -webkit-transition: all .2s ease-in-out;
      transition: all .2s ease-in-out;
      font-weight: 700;

      &:hover,
      &:focus {
        text-decoration: none;
      }
    }
  }
}


// Responsive

@media @responsiveBreakpoint {

  html.special-page-config,
  html.special-page-config-header-minimal {
    //body.vortex-test\.uio\.no,
    body.ritmo {
      #main h1 {
        font-size: 45px;
        margin-bottom: 20px;
      }
      .vrtx-container,
      .vrtx-image-listing-include-container-description,
      .vrtx-imagetext, .vrtx-img-container,
      figcaption,
      .vrtx-box-content {
        font-size: 16px;
        line-height: 1.4;
        width: 100%;
      }

      .vrtx-introduction,
      .special-lead p {
        font-size: 19px;
      }
      #main #vrtx-main-content {
        h2 {
          font-size: 26px;
          margin-bottom: 15px;
        }
        .half-box-left,
        .half-box-right {
          h2 {
            font-size: 22px;
            margin-bottom: 15px;
          }
        }
        h3 {
          font-size: 22px;
          margin-bottom: 15px;
        }
        .vrtx-frontpage-box.special-big-title>h2 {
          font-size: 32px;

          &:before {
            top: 70px;
          }
        }
        .vrtx-frontpage-box.special-big-title.grey-box>h2:before {
          top: 90px;
        }
        blockquote {
          font-size: 19px;
        }
        .grid-container:not(.row-last-colored)+.grid-container:not(.row-first-colored) {
          .vrtx-frontpage-box.special-big-title>h2:before {
            top: 88px;
          }
        }
      }
    }
  }
}
