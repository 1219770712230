#vrtx-mystudies-price-calculator {
  label {
    display: block;
    margin-bottom: 5px;
  }
  input {
    width: 150px;
    margin-bottom: 25px;
    &:focus {
      outline: revert;
    }
  }
  #vrtx-mystudies-price-calculator-result {
    font-weight: bold;
 }
}

