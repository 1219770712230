#vrtx-calendar-listing {
  position: relative;

  .vrtx-hide-additional-content-true {
    h1,
    #vrtx-change-language-link {
      margin-right: 205px;
    }
    #vrtx-change-language-link + h1 {
      margin-right: 0;
    }
    + #vrtx-additional-content {
      position: absolute;
      top: 0;
      right: 0;
      width: auto;
      margin: 0;
    }
  }
}

#vrtx-event-calendar {
  position: relative;
  display: inline-block;
  padding-bottom: 0 !important; /* Override Vortex default */

  #datepicker {
    display: none; // avoid flickering at load
    position: absolute;
    //right: 0;
    z-index: 2;
    margin-top: -1em;
    width: 20em;
    background: #fff;
    padding: 15px;
    border: 1px solid #000;

    /* Override Vortex default */
    .ui-datepicker {
      padding: 0;
      background: none;
      margin: 0;
      width: auto;
      border: none;

      .ui-widget-header {
        background: none;
        position: static;
        padding: 0;
        font-weight: normal;
        border: none;
      }
    }
    .ui-state-disabled {
      opacity: 1;
      filter: Alpha(Opacity=100);
    }

    .ui-datepicker-title {
      text-align: center;

      a {
        color: @linkColor; /* Override Vortex default */
      }
    }

    .ui-datepicker-next,
    .ui-datepicker-prev {
      display: block;
      position: static; /* Override Vortex default */
      width: 1.5em;
      height: 1.5em;
      background: transparent url("@{imagePath}arrows/arrow-small.svg") no-repeat center left;
      background-size: 1.5em auto;

	  &:focus,
	  &:hover {
	    outline: 1px dotted @textColor;
	  }
	  &:hover {
	    cursor: pointer;
	  }
	  .ui-icon {
	    display: none;
    }
  }

  .ui-datepicker-prev {
    float: left;
    -ms-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  .ui-datepicker-next {
    float: right;
  }

  table {
    margin: 15px 0 0 0;
    border-collapse: separate;
    border: none;
  }

  th,
  td {
    .baseFontSize;
    color: @textColor;
    padding: 0;
    border: none;
    text-align: center;
  }

  th {
    font-weight: normal;
  }

  tbody tr {
    &:first-child td {
      border-width: 1px 1px 1px 0 !important;
    }

    td {
      border: 1px solid #000 !important;
      border-width: 0 1px 1px 0 !important;

      &:first-child {
        border-width: 0 1px 1px 1px !important;
      }

      * {
        display: block;
        padding: .5rem 0 .4rem !important;
        background: none !important;
        border: none !important;
        text-align: center;
      }

      span {
        color: inherit !important;
      }

      a {
        color: @linkColor !important;
        text-decoration: none;
      }

      /* Today */
      &.ui-datepicker-today {
        border-color: #86bae2 !important;
        border-width: 1px 1px 1px 1px !important;

        * {
          background: #d7ebf6 !important;
        }
      }

      /* Active day */
      a:focus,
      a:hover,
      &.state-active a {
        background: @linkColor !important;
        color: #fff !important;
      }

      /* Other month day */
      &.ui-datepicker-other-month {
          span {
            background: #eee !important;
            border: 2px solid #fff !important;
            padding: .4rem 0 .2rem !important;
          }
        }
      }
      &:first-child {
        td.ui-datepicker-other-month {
          border-width: 0 0 1px 0 !important;

          + :not(.ui-datepicker-other-month) {
            border-width: 1px 1px 1px 1px !important;
          }
        }
      }
      &:last-child {
        td.ui-datepicker-other-month {
          border-width: 0 !important;
        }
      }
    }
  }
}

#vrtx-event-calendar-toggle {
  background: @btnPrimaryBg url("@{imagePath}listings/datepicker-toggle.svg") no-repeat 18px 11px;
  color: @btnPrimaryColor;
  padding-left: 60px;
  padding-right: 18px;
  text-decoration: none;

  &:hover,
  &:focus {
    background-color: @btnPrimaryBgHover;;
    color: @btnPrimaryColorHover;
  }

  &:after {
    display: none;
  }
}

@media @responsiveBreakpoint {

  #vrtx-calendar-listing {
    .vrtx-hide-additional-content-true {
      h1,
      #vrtx-change-language-link,
      #vrtx-change-language-link + h1 {
        margin-right: 0;
      }
    }
  }

  #vrtx-event-calendar #datepicker {
    left: 0;
    right: auto;
    max-width: 100%;
    z-index: 1;
  }

  #vrtx-event-calendar {
    display: block;
  }
}
