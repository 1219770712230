#vrtx-listing-filters {

  &:after {
    .clearfix();
  }
}

/* commented out due to UIOWEB-745
#vrtx-listing-filter-results {
  a {
    text-decoration: none;

    &:hover,
    &:focus {
      color: @textColor;
      .textUnderlineHover(@textColor);
    }
  }
}
*/