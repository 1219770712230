#error {
  .searchform {
    clear: both;
    max-width: none;
    input[type="text"],
    input[type="search"] {
      max-width: none;
      width: 100% !important;
    }
  }
}

@media @responsiveBreakpoint {
  .half-box-left,
  .half-box-right {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    float: none;
  }
}
