// nettskjema-cost-code.less

/*
Styling mostly for autocomplete2, to potenially separate that component
*/

// Autocomplete2
#main {
  .vrtx-autocomplete2 {
    label {
      display: block;
      margin-bottom: 5px;
    }
    .vrtx-input-wrapper {
      display: flex;
      border: 1px solid rgba(0, 0, 0, 0.25);
      border-bottom: 2px solid #18191c;
    }
    input,
    button {
      &:focus {
        outline: revert;
      }
    }
    input {
      border: none;
      max-width: 100%;
      @media @responsiveBreakpoint {
        font-size: 1.8rem;
        padding: 10px 0 10px 15px;
      }
    }
    button {
      all: unset;
      flex-shrink: 0;
      width: 70px;
      background: url("@{imagePath}form-elements/x-large.svg") no-repeat center;
      background-size: 20px auto;
      cursor: pointer;
      &::after {
        display: none;
      }
      &:hover {
        background-color: #e6ecff;
      }
      @media @responsiveBreakpoint {
        width: 50px;
        background-size: 16px auto;
      }
    }
    input.empty + button {
      display: none;
    }
  }
  .vrtx-autocomplete2-suggestions {
    position: absolute;
    z-index: 10;
    background-color: white;
    border: 1px solid #18191c;
    border-top: none;
    margin: 0;
    width: 100%;
    max-width: calc(100vw - 30px); // For small screen
    max-height: 40vh;
    overflow: auto !important; // Override very specific :not rule in content-grid.less
    li {
      padding: 10px;
      margin: 0;
      cursor: pointer;
      outline-offset: -5px;
      &:hover {
        background-color: var(--linkColor);
        color: white;
      }
      @media @responsiveBreakpoint {
        font-size: 1.6rem;
      }
      .search-all {
        text-decoration: underline;
      }
    }
  }
}

// Costcode
#main {
  .vrtx-costcode {
    .vrtx-autocomplete2 {
      margin-bottom: 30px;
    }
    // Link to nettskjema
    .button-large {
      width: fit-content;
    }
  }
}
