#main #vrtx-semester-links {
  display: block;

  ul {
    flex: none;
    width: 100%;
    float: none;
    padding: 0;
    margin: 0;
    li {
      a {
        word-break: break-word;
        background: @green;

        &:hover,
        &:focus {
          background: #ceffdf;
        }
      }
    }
  }
}

#vrtx-semester .vrtx-teacher-list-participants {
  li {
    a {
      position: relative; // arrow fix
    }
  }
}

#vrtx-semester-resource-links {
  width: var(--width);
  margin-left: ~"calc(calc(calc(calc(var(--width) - calc(100%)) / 2) + 100px) * -1)";
  background: @boxGreyBg;
  padding-top: 80px;
  padding-bottom: 80px;

  #vrtx-semester-resource-links-inner {
    max-width: @bodytextContentWidth;
    margin-left: auto;
    margin-right: auto;
    position: relative;

    &:after {
      .clearfix();
    }
    h2:first-child {
      margin-top: 0;
    }
    h2:not(:first-child) {
      clear: both;
      float: left;
      margin-top: 80px;
    }
    .left,
    .right {
      margin: 0;
    }
  }
}

@media @desktopSmallOnlyBreakpoint {
  #vrtx-semester-resource-links {
    margin-left: -25px;
    padding-left: 25px;

    #vrtx-semester-resource-links-inner {
      margin-left: 0;
    }
  }
}

@media @responsiveBreakpoint {
  #main ul.left + ul.right {
    margin-top: 20px;
  }
  #vrtx-course-semesters ul li:last-child {
    margin-bottom: 20px;
  }
  #main #vrtx-main-content #vrtx-course-semesters {
    padding: 30px 15px;
    margin-left: -15px;
    margin-right: -15px;
    width: ~"calc(100% + 30px)";
  }
  #vrtx-semester-resource-links {
    width: auto;
    padding: 30px 0;
    margin-left: -15px;
    margin-right: -15px;
    width: ~"calc(100% + 30px)" !important;

    #vrtx-semester-resource-links-inner {
      max-width: none;
      min-width: 0;
      padding-right: 15px;
      padding-left: 15px;
      margin-left: 0;
      margin-right: 0;
    }
  }
}
