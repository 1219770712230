/*
 * Utilities
 *
 */

@import 'reset.less';
@import 'variables.less';
@import 'mixins.less';
@import 'animations.less';

// Screenready only text (UU / accessibility)
#vrtx-frontpage #main #vrtx-content h1.hidden,
.ui-helper-hidden-accessible,
.offscreen-screenreader {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
  display: block;
}

// Desktop only
@media @desktopBreakpoint {

  .desktop-hide {
    display: none;
  }
}

@media @desktopSmallOnlyBreakpoint {
  /* Hyphens for ipad etc.: https://caniuse.com/?search=hyphens */
  *:not(input) {
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
  }
}

// Responsive only
@media @responsiveBreakpoint {

  /* Breaking text with hyphens with fallback to break-word and break-all */
  *:not(input) {
    word-wrap: break-word;
    word-break: normal;
    overflow-wrap: break-word;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
  }

  .responsive-hide,
  .responsive-hidden {
    display: none;
  }
}
