#main .vrtx-context-message-box.uio-info-message.blue {
  background: #3e31d6 url("@{imagePath}banner/info.svg") no-repeat 60px 20px;
  background-size: 35px auto;
  padding: 25px 60px 25px 120px;
  margin-bottom: 40px;

  a {
    .textUnderline(#fff);

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  *,
  a {
    color: #fff;
  }
}

.grid-container.uio-info-message.alert {
  background: #b60000;
  line-height: 0;
  font-size: 0;

  *,
  a {
    color: #fff;
  }

  a {
    text-decoration-color: #fff;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  .col-1-1 {
    >strong {
      background: url("@{imagePath}banner/alert.svg") no-repeat 0 45px;
      background-size: 40px auto;
      margin-left: 195px;
      padding-left: 90px;
      .baseFontSize;
      font-weight: normal;
      display: block;

      &:first-child {
        padding-top: 50px;
      }
      &:last-child {
        padding-bottom: 50px;
      }
    }
  }

  &.info {
    background: #3e31d6;

    .col-1-1 {
      >strong {
        background-image: url("@{imagePath}banner/info.svg");
        background-position: 0 20px;
        background-size: 35px auto;
        &:first-child {
          padding-top: 25px;
        }
        &:last-child {
          padding-bottom: 25px;
        }
      }
    }
  }
}

@media @responsiveBreakpoint {

  #main .vrtx-context-message-box.uio-info-message.blue {
    padding-left: 70px;
    padding-right: 15px;
    background-position: 15px 20px;
    background-size: 35px auto;
  }

  .grid-container.uio-info-message.alert {
    &,
    &.info {
      .col-1-1 {
        >strong {
          background-size: 35px auto;
          padding-left: 50px;
          padding-right: 0;
          background-position: 0 20px;
          margin-left: 0;
          &:first-child {
            padding-top: 25px;
          }
          &:last-child {
            padding-bottom: 25px;
          }
        }
      }
    }
  }
}
