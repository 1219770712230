@max-width: 1000px;

.pre-results {
  display: none;
}

.hits,
.vrtx-person-search-no-hits,
.vrtx-unit-search-no-hits {
  display: block;
  margin: 30px 0 0;
  .mixin-h4;
  max-width: @max-width;
}

.vrtx-search-quick-links,
.vrtx-search-results-container {
  h2 {
    margin-top: 0;
    margin-bottom: 10px;
  }
  .vrtx-search-quick-link,
  .vrtx-search-results {
    margin: 40px 0 0 0;
    max-width: @max-width;
  }
}

.vrtx-search-quick-links {
  .vrtx-search-quick-link {
    .summary {
      .baseFontSize;
      .vrtx-search-restricted {
        color: @red;
      }
    }
    .footer {
      color: @publishedDateGrey;
      .baseFontSize;
    }
  }
}

.vrtx-search-results-container {
  div.vrtx-search-results {
    .result {
      h2 {
        margin-bottom: 10px;

        a {
          .mixin-h2;
        }
      }
      .snippet {
        .baseFontSize;

        .vrtx-search-restricted {
          color: @red;
        }
      }
      .footer {
        color: @publishedDateGrey;
        .baseFontSize;
        margin-top: 5px;
      }
    }
  }
}

#main .vrtx-topuioresult {
  display: block;
  border-bottom: 1px solid @borderColor;
  padding-bottom: 20px;

  &:before {
    display: block;
    content: " ";
    border-top: 1px solid @borderColor;
    padding-top: 20px;
  }
  .result > .url + h2 {
    margin-top: 5px;
  }
  .footer > .vrtx-search-scope {
    margin: 10px 0 0 0;
    li {
      margin: 0;
      &:before {
        display: none;
      }
    }
  }
}

#main .vrtx-has-search-scope {
  .vrtx-search-scope {
    margin: 0;
    padding: 0;
    clear: both;
  }
  .vrtx-paging-feed-wrapper {
    margin-bottom: 20px;
  }
}

.vrtx-additional-search-info {
  display: none;
}

// Search page 2023
#vrtx-search-did-you-mean {
  margin-top: 10px;
}

#vrtx-searchview {
  form.vrtx-big-search {
    display: flex;
    align-items: center;
    max-width: @max-width;
    div {
      width: 100%;
      max-width: @max-width;
    }
    input[type="text"],
    input[type="search"] {
      max-width: calc(100% - 45px); // make room for advanced search button
    }
    button[type=submit] {
      right: 9.2em;
    }
    #vrtx-show-advanced-search {
      white-space: nowrap;
      display: flex;
      align-items: center;
      text-decoration: none;
      &::before {
        content: "";
        background: url(/vrtx/dist/resources/uio2/css/images/form-elements/settings.svg) no-repeat;
        padding-right: 0.25em;
        height: 1.3em;
        width: 1.3em;
        display: block;
      }
    }
  }
}

#vrtx-searchview,
#vrtx-personsearch,
#vrtx-unitsearch {
  h1 {
    margin-bottom: 25px;
  }
  form.vrtx-search-form.vrtx-big-search,
  form.vrtx-big-search,
  .vrtx-person-search-hits,
  .vrtx-unit-search-result-container {
    max-width: @max-width;
  }
  #vrtx-advanced-search-wrapper {
    display: none;
    margin-bottom: 20px;
    overflow: hidden;
    >*:first-child {
      margin-top: 0;
    }
    input[type="text"] {
      width: 390px;
    }
    .vrtx-toggle-advanced-search {
      display: inline-block !important;
      margin-left: 20px;
    }
    #vrtx-advanced-search-submit-hide {
      margin-top: 20px;
      .submit-button-link {
        width: auto;
      }
    }
  }
  
  #vrtx-content #vrtx-additional-content {
    margin-top: 0;
    width: 100%;
    float: none;
    .vrtx-search-facets-box {
      margin-bottom: 20px;
    }
    ul {
      margin: 10px 0 15px;
      li {
        margin-bottom: 15px;
        >a {
          padding: 0;
        }
        .vrtx-search-facets-selected {
          padding: 7px 13px;
        }
      }
    }
  }

  .vrtx-search-main-links-box.vrtx-frontpage-box,
  #vrtx-content #vrtx-additional-content .vrtx-search-main-links-box.vrtx-frontpage-box {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 30px;
    margin-bottom: 30px;
    .mixin-small;
    max-width: @max-width;

    div[id^=vrtx-search-main-link-] a {
      border-radius: 30px;
      background: #EBEDFC;
      padding: 0.5em 1em;
      border: 1px solid transparent;
      .mixin-small;
      text-decoration: none;
      &:after {
        display: none;
      }
      &.selected {
        border-color: black;
      }
    }

    #vrtx-search-more-setting {
      margin-top: -5px;
      button {
        border: none;
        padding: 0.4em 3em 0.35em 1em;
        background-color: #ebedfc;
        .mixin-small;

        &:after {
          background: url(/vrtx/dist/resources/uio2/css/images/arrows/chevron-up.svg) no-repeat center center;
          top: 0.6em;
          right: 1em;
          transform: rotate(180deg);
        }

        &[aria-expanded="true"]:after {
          background: url(/vrtx/dist/resources/uio2/css/images/arrows/chevron-up.svg) no-repeat center center;
          top: 0.6em;
          right: 1em;
          transform: rotate(0deg);
        }
      }
    }
  }

  .vrtx-search-facets-box {
    column-count: 3;
    column-width: 250px;
    .mixin-small;
    column-gap: 40px;
    border-bottom: 1px solid black;
    margin-bottom: 30px;
    .vrtx-search-facets-wrapper {
      overflow: hidden;
      break-inside: avoid;
      .vrtx-search-facets-title {
        font-weight: 700;
        .baseFontSize;
      }
      ul.vrtx-search-facets {
        margin-bottom: 15px;
        overflow: visible;
        display: flex;
        flex-direction: column;
        li {
          margin-bottom: 15px;
          margin-left: 0;
          a {
            padding: 0;
            margin-left: 2px;
          }
          &:before {
            display: none;
          }
          .vrtx-search-facets-selected {
            background: #EBEDFC;
            color: #47474C;
            padding: 7px 13px;
            font-weight: 700;
            display: inline-block;
          }
        }
        li:has(.vrtx-search-facets-selected) {
          order: -1;
        }
      }
    }
  }
  .vrtx-search-open-facets-box {
    display: flex;
    column-gap: 20px;
    margin: 0;
    ul {
      margin: 0;
      li {
        margin-left: 0;
        &:before {
          display: none;
        }
        >a {
          text-decoration: none;
          padding: 6px 11px 6px 32px;
          display: inline-block;
          color: var(--textColor);
          border: 1px solid black;
          background: transparent url(/vrtx/dist/resources/uio2/css/images/form-elements/x.svg) no-repeat left 0.3em center;
        }
      }
    }
  }

  @media @responsiveBreakpoint {
    form.vrtx-big-search {
      #vrtx-show-advanced-search {
        position: static;
        display: none;
      }
    }
  }
}

// Search button position
#vrtx-searchview form.vrtx-big-search button[type=submit] {
  right: 9.2em;
}
#vrtx-searchview.en form.vrtx-big-search button[type=submit] {
  right: 10.8em;
}
@media @responsiveBreakpoint {
  #vrtx-searchview form.vrtx-big-search button[type=submit],
  #vrtx-searchview.en form.vrtx-big-search button[type=submit] {
    right: 3.2em;
  }
}