#main {
  .banner-container {
    padding: 0;
    margin-bottom: 40px;
    line-height: 0;
    position: relative;
    overflow: hidden;
    height: 300px;

    #banner-container-title {
      width: 1100px;
      margin: 0 auto;
      color: #fff;
      position: absolute;
      left: 40px;
      top: 235px;
      font-size: 4.4rem;
      font-weight: bold;
      display: block;
      text-decoration-color: #fff;

      &:focus,
      &:hover {
        text-decoration: none;
      }
    }
  }
  .back-link-container {
    display: none;
    background-color: rgba(44, 44, 44, 0.7);
    position: relative;
    z-index: 2;
    height: 53px;
    margin-bottom: -53px;
    padding: 14px 0 13px;

    .vrtx-back {
      width: 970px;
      margin: 0 auto;

      a {
        color: #fff;
        margin-left: 43px;

        &:hover,
        &:focus {
          text-decoration: none;
        }

        &:before {
          display: inline-block;
          height: .8em;
          width: 1em;
          margin-top: .6ex;
          padding-right: 10px;
          background: url("@{imagePath}arrows/arrow-big-white.svg") 0 0 no-repeat;
          background-size: auto 100%;
          content: " ";
        }
      }
    }
    .vrtx-back {
      display: inline-block;
    }
  }
}

#vrtx-structured-project .banner-container ~ #vrtx-content {
  h1 {
    display: none;

    + h2 {
      margin-top: 0;
    }
  }
}


@media @responsiveBreakpoint {

  #main .banner-container {
    height: auto;
    margin: 0 0 20px 0;
    padding: 0;
    padding-bottom: 15px;
    border-bottom: 4px solid #eaeaea;
    background: none !important;
    position: static;
    line-height: 3.4rem;

    #banner-container-title {
      color: @textColor;
      position: static;
      width: auto;
      text-decoration: none;

      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }
  }

  .back-link-container {
    margin-bottom: 0;
    background: transparent;
    height: auto;

    .vrtx-back {
      width: auto;
      margin: 0 15px;

      a {
        color: @linkColor;
      }
    }
  }

  #vrtx-structured-project .banner-container + #main {
    #vrtx-change-language-link {
      ~ .vrtx-introduction {
        margin-top: 0px;
      }
    }
  }
}
