/*
 * Admonitions (note, hint, warning etc.)
 *
 * This is for important feedback from action scenarios, and is most often put
 * at the start of the document or after the main menu.
 *
 * Possible admonitions and when to use them:
 *
 *  - error     For critical errors?
 *  - warning   When...
 *  - note
 *  - caution
 *  - important
 *  - tip
 *  - hint
 *  - attention
 *  - danger
 *
 * Examples on how the html should look like:
 *
 *  <div class="warning">
 *      <p class="admonition-title">Warning title</p>
 *      A short warning text.
 *  </div>
 *
 *  <div class="error">
 *      <p class="admonition-title">A critical error</p>
 *      <pre>
 *          Error message, code and traceroute, if that should be shown.
 *      </pre>
 *  </div>
 *
 *  <div class="tip">
 *      <p class="admonition-title">A smart tip in several points</p>
 *      <ul>
 *         <li>One</li>
 *         <li>Two</li>
 *         <li>Three</li>
 *      </ul>
 *  </div>
 *
 * Admonitions without 'admonition-title' is also ok. E.g:
 *
 *  <div class="danger">
 *      <p>Stop.</p>
 *  </div>
 *
 * To get smaller admonitions:
 *
 *  <div class="note admonition-small">
 *      ...
 *  </div>
 *
 */

@admonitionsImgsPath: '.././images/admonitions/';

body#vrtx-structured-article {

   div {
     &.admonition,
     &.attention,
     &.error,
     &.hint,
     &.important,
     &.note,
     &.tip,
     &.warning,
     &.caution,
     &.danger {
       background-color: #f8f8f8;
       background-position: 19px 12px ;
       background-repeat: no-repeat;
       color: #000;
       margin: 15px 0;
       padding: 15px 25px 10px 66px;
       min-height: 25px;
       border: 1px solid #d5d5d5;
       .borderRadius(10px);

       > div {
         display: table-cell;
         vertical-align: middle;
         height: 37px;
         width: 100%;
       }

       ul {
         font-size: inherit;
       }

       pre.literal-block {
         font-size: inherit;
         border: 1px solid @borderColor;
       }

       tt.literal {
         color: inherit;
       }
     }

     &.admonition { padding-left: 19px; }
     &.attention  { background-image: url('@{admonitionsImgsPath}attention.png'); }
     &.error      { background-image: url('@{admonitionsImgsPath}error.png');     }
     &.hint       { background-image: url('@{admonitionsImgsPath}hint.png');      }
     &.important  { background-image: url('@{admonitionsImgsPath}important.png'); }
     &.note       { background-image: url('@{admonitionsImgsPath}note.png');      }
     &.tip        { background-image: url('@{admonitionsImgsPath}tip.png');       }
     &.warning    { background-image: url('@{admonitionsImgsPath}warning.png');   }
     &.caution    { background-image: url('@{admonitionsImgsPath}caution.png');   }
     &.danger     { background-image: url('@{admonitionsImgsPath}danger.png');    }
   }

   p.admonition-title {
     font-weight: bold;
     font-size: 20px;
     margin-top: 0px;
   }

   div.admonition-small {
     padding: 5px 25px 10px 60px;
     background-position: 12px 12px;
     min-height: 37px;

     p.admonition-title {
       font-size: 1em;
       margin-bottom: -3px;
       margin-top: 5px;
     }

     p.last {
       margin-bottom: 0px;
     }
   }

   

  pre.code span.hll { background-color: #ffffcc }
  .highlight  { background: #f8f8f8; }
  pre.code span.c { color: #408080; font-style: italic } /* Comment */
  pre.code span.err { border: 1px solid #FF0000 } /* Error */
  pre.code span.k { color: #008000; font-weight: bold } /* Keyword */
  pre.code span.o { color: #666666 } /* Operator */
  pre.code span.ch { color: #408080; font-style: italic } /* Comment.Hashbang */
  pre.code span.cm { color: #408080; font-style: italic } /* Comment.Multiline */
  pre.code span.cp { color: #BC7A00 } /* Comment.Preproc */
  pre.code span.cpf { color: #408080; font-style: italic } /* Comment.PreprocFile */
  pre.code span.c1 { color: #408080; font-style: italic } /* Comment.Single */
  pre.code span.cs { color: #408080; font-style: italic } /* Comment.Special */
  pre.code span.gd { color: #A00000 } /* Generic.Deleted */
  pre.code span.ge { font-style: italic } /* Generic.Emph */
  pre.code span.gr { color: #FF0000 } /* Generic.Error */
  pre.code span.gh { color: #000080; font-weight: bold } /* Generic.Heading */
  pre.code span.gi { color: #00A000 } /* Generic.Inserted */
  pre.code span.go { color: #888888 } /* Generic.Output */
  pre.code span.gp { color: #000080; font-weight: bold } /* Generic.Prompt */
  pre.code span.gs { font-weight: bold } /* Generic.Strong */
  pre.code span.gu { color: #800080; font-weight: bold } /* Generic.Subheading */
  pre.code span.gt { color: #0044DD } /* Generic.Traceback */
  pre.code span.kc { color: #008000; font-weight: bold } /* Keyword.Constant */
  pre.code span.kd { color: #008000; font-weight: bold } /* Keyword.Declaration */
  pre.code span.kn { color: #008000; font-weight: bold } /* Keyword.Namespace */
  pre.code span.kp { color: #008000 } /* Keyword.Pseudo */
  pre.code span.kr { color: #008000; font-weight: bold } /* Keyword.Reserved */
  pre.code span.kt { color: #B00040 } /* Keyword.Type */
  pre.code span.m { color: #666666 } /* Literal.Number */
  pre.code span.s { color: #BA2121 } /* Literal.String */
  pre.code span.na { color: #7D9029 } /* Name.Attribute */
  pre.code span.nb { color: #008000 } /* Name.Builtin */
  pre.code span.nc { color: #0000FF; font-weight: bold } /* Name.Class */
  pre.code span.no { color: #880000 } /* Name.Constant */
  pre.code span.nd { color: #AA22FF } /* Name.Decorator */
  pre.code span.ni { color: #999999; font-weight: bold } /* Name.Entity */
  pre.code span.ne { color: #D2413A; font-weight: bold } /* Name.Exception */
  pre.code span.nf { color: #0000FF } /* Name.Function */
  pre.code span.nl { color: #A0A000 } /* Name.Label */
  pre.code span.nn { color: #0000FF; font-weight: bold } /* Name.Namespace */
  pre.code span.nt { color: #008000; font-weight: bold } /* Name.Tag */
  pre.code span.nv { color: #19177C } /* Name.Variable */
  pre.code span.ow { color: #AA22FF; font-weight: bold } /* Operator.Word */
  pre.code span.w { color: #bbbbbb } /* Text.Whitespace */
  pre.code span.mb { color: #666666 } /* Literal.Number.Bin */
  pre.code span.mf { color: #666666 } /* Literal.Number.Float */
  pre.code span.mh { color: #666666 } /* Literal.Number.Hex */
  pre.code span.mi { color: #666666 } /* Literal.Number.Integer */
  pre.code span.mo { color: #666666 } /* Literal.Number.Oct */
  pre.code span.sa { color: #BA2121 } /* Literal.String.Affix */
  pre.code span.sb { color: #BA2121 } /* Literal.String.Backtick */
  pre.code span.sc { color: #BA2121 } /* Literal.String.Char */
  pre.code span.dl { color: #BA2121 } /* Literal.String.Delimiter */
  pre.code span.sd { color: #BA2121; font-style: italic } /* Literal.String.Doc */
  pre.code span.s2 { color: #BA2121 } /* Literal.String.Double */
  pre.code span.se { color: #BB6622; font-weight: bold } /* Literal.String.Escape */
  pre.code span.sh { color: #BA2121 } /* Literal.String.Heredoc */
  pre.code span.si { color: #BB6688; font-weight: bold } /* Literal.String.Interpol */
  pre.code span.sx { color: #008000 } /* Literal.String.Other */
  pre.code span.sr { color: #BB6688 } /* Literal.String.Regex */
  pre.code span.s1 { color: #BA2121 } /* Literal.String.Single */
  pre.code span.ss { color: #19177C } /* Literal.String.Symbol */
  pre.code span.bp { color: #008000 } /* Name.Builtin.Pseudo */
  pre.code span.fm { color: #0000FF } /* Name.Function.Magic */
  pre.code span.vc { color: #19177C } /* Name.Variable.Class */
  pre.code span.vg { color: #19177C } /* Name.Variable.Global */
  pre.code span.vi { color: #19177C } /* Name.Variable.Instance */
  pre.code span.vm { color: #19177C } /* Name.Variable.Magic */
  pre.code span.il { color: #666666 } /* Literal.Number.Integer.Long */
}