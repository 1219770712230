.vrtx-dropdown-component {
  display: inline-block;
  position: relative;

  .vrtx-dropdown-wrapper {
    display: none;
    position: absolute;
    top: 24px;
    right: 0;
    background: #fff;
    border: 1px solid #c2c2c2;
    border-bottom: none;
    z-index: 1;

    &.open {
      display: block;
    }

    .vrtx-dropdown-title {
      display: none;
    }

    ul {
      margin: 0;

      li {
        margin: 0;
        border-bottom: 1px solid #cbcbcb;

        &:before {
          display: none;
        }
        a {
          color: @textColor;
          padding: 8px 14px 7px 14px;
          display: block;
          white-space: nowrap;
        }
      }
    }
  }
}
