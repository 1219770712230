/* Full width picture */

#main .grid-container.full-width-picture-bg {
  height: 300px;
  position: relative;
  top: 60px;
  margin-bottom: 140px;

  .vrtx-box-content {
    margin-top: 30px;
  }
}

@media @responsiveBreakpoint {

 #vrtx-frontpage #main .grid-container.full-width-picture-bg {
    height: auto;
    background: none !important;
    position: static;
    margin-bottom: @verticalGap;
    width: ~"calc(100vw)";
    margin-left: -15px;
    margin-right: 0;

    .vrtx-frontpage-box {
      padding-left: 0;
      padding-right: 0;
    }

    .vrtx-box-content {
      margin-top: 30px;
    }
  }

}
