.tooltip {
  position: relative;
  height: auto;
  background: #fff;
  border: 3px solid #eaeaea;
  width: 450px;
  margin-top: 20px;
  padding: 20px 30px 30px;
  position: absolute;
  z-index: 2;
  margin-left: -70px;

  p {
    margin-bottom: 5px;

    &.tooltip-title {
      font-weight: bold;
      margin-bottom: 10px;
    }
  }
  &:after,
  &:before {
    content: '';
    position: absolute;
    border-style: solid;
    display: block;
    width: 0;
  }
  &:before {
    border-width: 0 18px 18px;
    border-color: #eaeaea transparent;
    z-index: 0;
    top: -18px;
    left: 107px;
  }
  &:after {
    border-width: 0 15px 15px;
    border-color: #fff transparent;
    z-index: 2;
    top: -15px;
    left: 110px;
  }
}
