// Local/faculty program listing
#main .vrtx-programs.programListing\.searchComponent {
  .vrtx-image {
    display: none;
  }
  .thirds-left,
  .thirds-middle,
  .thirds-right {
    float: none;
    width: auto;

    &:hover,
    &:focus {
      a {
        text-decoration: underline;
        color: @textColor;
      }
    }
  }
  &:first-of-type h2 {
    margin-top: 0;
  }
}

// Program listing (uio and local/faculty)

#vrtx-program-filters,
#vrtx-program-filters-topic,
#vrtx-program-filters-type {
  overflow: hidden;

  &:after,
  &:before {
    .clearfix();
  }
}

#vrtx-program-filters {
  h2 {
    margin-top: 0;
    margin-bottom: 20px;
    .mixin-h3;
  }

  li {
    &:hover {
      cursor: pointer;
    }
  }
  ul {
    margin: 0;
  }
}

.alle-typer,
.alle-fagomraader,
#alle-typer,
#alle-fagomraader {
  float: none;
  width: auto;

  &,
  li {
    margin-bottom: 0;
  }
}

#vrtx-program-filters-topic {
  width: 70%;
  float: left;

  .only-fagomraader {
    overflow: hidden;
  }
}

#vrtx-program-filters-type {
  width: 30%;
  padding-left: 40px;
  float: right;
}

#vrtx-listing-filter-hits,
#vrtx-program-search-found,
#vrtx-program-inactive-search-found {
  display: block;
  margin-top: 60px;
  margin-bottom: 40px;
  .baseFontSize;
}

.vrtx-program-read-more {
  display: none;
}

.vrtx-programs.programListing\.searchComponent,
#main ul.vrtx-programs {
  margin: 20px 0 0 0;
  max-width: none;

  .thirds-left,
  .thirds-middle,
  .thirds-right,
  li {
    &:before {
      display: none;
    }

    width: 100%;
    margin: 0 0 20px 0;
    max-width: 1000px;
    padding: 24px 72px 24px 32px;
    background: #F4F6FF;
    position: relative;

    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 30px;
      right: 42px;
      height: 16px;
      width: 27px;
      background: url("@{imagePath}arrows/arrow-big.svg") no-repeat 0 0;
      background-size: auto 16px;
      opacity: 0;
      .transition(.2s, all, ease);
    }

    &:hover {
      cursor: pointer;

      h2 a {
        text-decoration: underline;
      }
    }

    &:hover,
    &:focus {
      background: #EBEDFC;
      &:after {
        right: 32px;
        opacity: 1;
      }
    }

    h3,
    h2 {
      margin-top: 0;
      margin-bottom: 0;
      .mixin-h4;

      a {
        color: @textColor;
        text-decoration: none;
      }
    }

    .vrtx-program-listing-keywords,
    .introduction {
      margin: 10px 0 0 0;
      .mixin-small;
    }

    .vrtx-program-buttons {
      position: absolute;
      top: 30px;
      right: 0;

      a.button:not(.comment-delete-button):not(#vrtx-commments-delete-all) {
        &,
        &:focus,
        &:hover {
          background: none;
          .baseFontSize;
          color: #888;
          padding: 0;
          margin: 0 0 0 20px;
          font-weight: normal;
        }

        &:focus,
        &:hover {
          .textUnderlineHover(@textColor);
        }
      }
    }

    .vrtx-program-buttons {
      a.vrtx-program-how-search {
        display: none;
      }
    }
  }
}

a#vrtx-program-filters-type-help,
ul.vrtx-programs-inactive.hidden,
#vrtx-program-inactive-search-found.hidden,
ul.vrtx-programs-inactive li.invisible,
ul.vrtx-programs li.invisible {
  display: none !important;
}

/* IT services uses much of same design/classes */
#vrtx-filter-custom-listing #main ul.vrtx-programs li {
  &:after {
    display: none;
  }
  &:hover,
  &:focus {
    &:after {
      display: none;
    }
  }
}

@media @responsiveBreakpoint {
  #main ul.vrtx-programs li {
    .vrtx-program-buttons {
      display: none;
    }
  }
  #vrtx-program-filters {
    h2 {
      margin-bottom: 0;
      .baseFontSize;
    }
    .toggle-more-opts {
      margin: 0 0 15px 0;
    }
  }
  #vrtx-listing-filter-hits,
  #vrtx-program-search-found,
  #vrtx-program-inactive-search-found {
    margin-top: 30px;
    margin-bottom: 20px;
  }

  #vrtx-program-listing #main .vrtx-programs.programListing\.searchComponent,
  #main ul.vrtx-programs {
    .thirds-left,
    .thirds-middle,
    .thirds-right,
    li {
      margin: 20px 0 0 0;
      max-width: none;
      padding: 18px 72px 18px 18px;

      h2,
      h3 {
        .baseFontSize;
      }

      &:after {
        top: 24px;
        right: 28px;
      }
      &:hover,
      &:focus {
        &:after {
          right: 18px;
        }
      }
    }
  }
  #main #vrtx-program-filters ul.left+ul.right {
    margin-top: 15px;
  }

  #main ul.vrtx-programs .introduction,
  .vrtx-programs.programListing\.searchComponent .vrtx-program-listing-keywords {
    display: none;
  }
}
