table.vrtx-person-listing,
table.vrtx-unit-listing {
  table-layout: fixed;

  th,
  td {
    border-color: #9b9b9b;
  }
}

// unit-listing

table.vrtx-unit-listing {
  th {
    &.vrtx-unit-listing-name  { width: 56%; }
    &.vrtx-unit-listing-phone { width: 18%; }
    &.vrtx-unit-listing-email { width: 18%; }
  }
  td {
    &.vrtx-unit-listing-name {
      font-weight: bold;
    }
    &.vrtx-unit-listing-phone,
    &.vrtx-unit-listing-email {
      span {
        display: block;
      }
    }
  }
}

// person-listing

table.vrtx-person-listing {
  th {
    white-space: nowrap;
    &.vrtx-person-listing-name  { width: 38%; }
    &.vrtx-person-listing-phone { width: 18%; }
    &.vrtx-person-listing-email { width: 18%; }
    &.vrtx-person-listing-tags  { width: 26%; }
  }
  td {
    &.vrtx-person-listing-name {
      position: relative;
      padding-left: 150px; // space for img width
      height: 200px; // works like min-height. space for img height.
      a {
        font-weight: bold;
      }
      span {
        display: block;
      }
      img {
        position: absolute;
        left: 0;
        width: 126px;
        height: 168px; // aspect ratio 3/4
        object-fit: cover; // or use contain
        //object-position: left top; // use if object-fit: contain
      }
    }
    &.vrtx-person-listing-phone,
    &.vrtx-person-listing-email {
      span {
        display: block;
      }
    }
    &.vrtx-person-listing-tags {
      .mixin-tiny;
    }
  }
  tr:last-child td {
    border-bottom: 0;
  }
}

.vrtx-tags-container,
.vrtx-departments-tags-container {
  .vrtx-more {
    .mixin-h4;
  }
}

#vrtx-person-listing,
#vrtx-unit-listing {
  .vrtx-additional-content {
    margin-bottom: 60px;
  }
}

//////////////////////////////////
// responsive
//////////////////////////////////

@media @responsiveBreakpoint {

  #main  {
    .vrtx-person-listing,
    .vrtx-unit-listing {
      &,
      tbody,
      tr,
      td,
      caption {
        display: block;
        border: none;
        padding: 0;
      }
      thead {
        display: none;
      }
      caption {
        margin-bottom: 10px;
      }
      td {
        padding: 0;
      }
    }

    // unit-listing

    .vrtx-unit-listing tbody tr {
      margin-bottom: 20px;
    }

    // person-listing

    .vrtx-person-listing tbody tr {
      position: relative;
      padding-left: 100px; // space for image width
      min-height: 120px; // space for image height
      margin-bottom: 40px;
    }
    td {
      &.vrtx-person-listing-name {
        position: static;
        height: auto;
        img {
          width: 80px;
          height: 106.66px; // aspect ratio 3/4
          top: 3px;
        }
      }
      &.vrtx-person-listing-phone,
      &.vrtx-person-listing-email {
        .mixin-tiny;
      }
      &.vrtx-person-listing-email {
        span {
          display: inline;
        }
      }
      &.vrtx-person-listing-tags {
        margin-top: 10px;
      }
    }

    // not sure if/where this is used
    table.vrtx-person-listing.person-listing-hide-imgs a.vrtx-image {
      display: none;
    }
  }
}
