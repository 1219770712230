.clearfix:before, .tidslinje__section:before, .tidslinje__item:before,
.clearfix:after, .tidslinje__section:after, .tidslinje__item:after {
   content: " ";
   display: table; }
 .clearfix:after, .tidslinje__section:after, .tidslinje__item:after {
   clear: both; }

.tidslinje {
 padding-top: 40px;
 padding-bottom: 40px;
 font-size: 16px;
 line-height: 24px; }
 .tidslinje, .tidslinje:before, .tidslinje:after, .tidslinje *,
.tidslinje *:before, .tidslinje *:after {
   box-sizing: border-box; }
 .tidslinje h2 {
   font-size: 22px;
   line-height: 30px;
   font-weight: bold;
   margin: 0;
   margin-bottom: 10px; }
 @media screen and (min-width: 700px) {
   .tidslinje {
     text-align: center; } }
 .tidslinje__title {
   display: inline-block;
   padding: 20px;
   border: 6px solid #e1e1e1;
   margin: 0 17px !important;
   font-size: 22px;
   font-weight: bold;
   color: #6e6e6e; }
 .tidslinje__section {
   position: relative;
   margin: 0 auto 20px;
   max-width: 670px;
   padding-bottom: 40px;
   padding-left: 40px; }
   @media screen and (min-width: 700px) {
     .tidslinje__section {
       padding-left: 0; } }
   @media screen and (min-width: 980px) {
     .tidslinje__section {
       max-width: 970px; } }
   .tidslinje__section--muted {
     color: #6e6e6e;
     font-style: italic; }
     .tidslinje__section--muted .tidslinje__line {
       background-color: #e1e1e1; }
     .tidslinje__section--muted .tidslinje__year {
       background-color: #e1e1e1; }
       .tidslinje__section--muted .tidslinje__year:before {
         border-bottom-color: #e1e1e1; }
     .tidslinje__section--muted .tidslinje__dot {
       top: 40px;
       width: 30px;
       height: 30px;
       margin-left: -15px;
       border: 6px solid #e1e1e1;
       background-color: #fff; }
 .tidslinje__line {
   position: absolute;
   top: 0;
   bottom: 0;
   margin-left: -3px;
   width: 6px;
   background-color: #bebebe;
   left: 20px; }
   @media screen and (min-width: 700px) {
     .tidslinje__line {
       left: 50%; } }
 .tidslinje__item {
   position: relative;
   padding: 40px 0; }
 .tidslinje__content {
   text-align: left;
   padding-right: 10px; }
   @media screen and (min-width: 700px) {
     .tidslinje__content {
       text-align: right;
       float: left;
       width: 275px;
       padding-right: 0; } }
   @media screen and (min-width: 980px) {
     .tidslinje__content {
       width: 425px; } }

   @media screen and (min-width: 700px) {
     .tidslinje__content--right {
       text-align: left;
       float: right; } }
 .tidslinje__year {
   position: absolute;
   bottom: 0;
   height: 40px;
   line-height: 40px;
   width: 76px;
   border-radius: 4px;
   background-color: #bebebe;
   font-family: Georgia, serif;
   font-weight: bold;
   font-size: 20px;
   text-align: center;
   left: 20px; }
   @media screen and (min-width: 700px) {
     .tidslinje__year {
       margin-left: -38px;
       left: 50%; } }
   .tidslinje__year:before {
     content: "";
     position: absolute;
     display: block; }
     @media screen and (min-width: 700px) {
       .tidslinje__year:before {
         width: 1px;
         height: 1px;
         top: -73px;
         left: 1px;
         border: 37px solid transparent;
         border-bottom-color: #bebebe;
         -webkit-transform: scaleY(0.7);
             -ms-transform: scaleY(0.7);
                 transform: scaleY(0.7);
         -webkit-transform-origin: center bottom;
             -ms-transform-origin: center bottom;
                 transform-origin: center bottom; } }
 .tidslinje__dot {
   position: absolute;
   top: 44px;
   border-radius: 50%;
   width: 16px;
   height: 16px;
   margin-left: -8px;
   background-color: #bebebe;
   left: -20px; }
   @media screen and (min-width: 700px) {
     .tidslinje__dot {
       left: 50%; } }
   .tidslinje__dot--promo {
     background-color: #EC312E; }
 .tidslinje__video {
   display: block;
   padding: 10px;
   background-color: #FFFFFF;
   box-shadow: 0 2px 5px rgba(41, 41, 41, 0.2);
   margin-right: 20px;
   margin-bottom: 20px; }
   @media screen and (min-width: 700px) {
     .tidslinje__video {
       /* margin-left: -20px; */
       margin-right: 0; } }
   .tidslinje__video__caption {
     margin: 0;
     font-size: 14px;
     text-align: left; }
 .tidslinje__image {
   display: block;
   text-align: center;
   margin-bottom: 20px; }
   .tidslinje__image img {
     max-width: 100%; }

.float-right,
[class*="col-"].float-right {
 float: right; }
 @media screen and (min-width: 700px) {
   .float-right--md,
   [class*="col-"].float-right--md {
     float: right; } }
 @media screen and (min-width: 980px) {
   .float-right--lg,
   [class*="col-"].float-right--lg {
     float: right; } }
 @media screen and (min-width: 1280px) {
   .float-right--xl,
   [class*="col-"].float-right--xl {
     float: right; } }

.float-left {
 float: left; }
 @media screen and (min-width: 700px) {
   .float-left--md {
     float: left; } }
 @media screen and (min-width: 980px) {
   .float-left--lg {
     float: left; } }
 @media screen and (min-width: 1280px) {
   .float-left--xl {
     float: left; } }

.text-align--center {
 text-align: center; }

.text-align--right {
 text-align: right; }

.ng-cloak {
 display: none; }

.is-hidden {
 display: none; }
 @media screen and (min-width: 700px) {
   .is-hidden--md {
     display: none; } }
 @media screen and (min-width: 980px) {
   .is-hidden--lg {
     display: none; } }
 @media screen and (min-width: 1280px) {
   .is-hidden--xl {
     display: none; } }

.is-visible {
 display: inherit; }
 .is-visible--md {
   display: none; }
   @media screen and (min-width: 700px) {
     .is-visible--md {
       display: inherit; } }
 .is-visible--lg {
   display: none; }
   @media screen and (min-width: 980px) {
     .is-visible--lg {
       display: inherit; } }
 .is-visible--xl {
   display: none; }
   @media screen and (min-width: 1280px) {
     .is-visible--xl {
       display: inherit; } }
