// Left menu in sidebar
#left-main {
  position: relative;
  width: 220px;
  height: 100%;
  padding: 0 40px 25px 25px;

  a {
    color: @textColor;
  }
}

#left-menu-same-level-folders {
  display: block;
  width: 100%;
  .mixin-tiny;
  letter-spacing: 0.5px;
  padding: 80px 25px 25px 25px;
  width: 100%;
  margin-top: 0;
  margin-left: -40px;
  width: 215px;

  .left-menu-child-selected > a,
  .vrtx-ancestor,
  &.hidden {
    display: none;
  }

  .vrtx-breadcrumb-menu {
    background: #fff;
    margin: -25px;
    width: ~"calc(100% + 25px + 25px)";
    padding: 25px 25px;
  }

  // Remove parent when child is selected/marked
  .left-menu-child-selected {
    ul {
      margin-top: 0;
    }
    .vrtx-child {
      // Remove selected/marked child if "only child" / enebarn (UIOWEB-756)
      &:only-child {
        display: none;
      }
      &:before {
        display: none;
      }
      margin-left: 0;
    }
  }

  ul {
    padding: 0;
    width: 100%;

    ul {
      margin-top: 10px;

      li {
        margin-left: 20px;
        // Avoid text not breaking on zoom (UIOWEB-762)
        hyphens: auto;
        overflow-wrap: break-word;

        &:before {
          display: block;
          content: '\25A0';
          margin-left: 0;
          top: 2.5ex;
        }
      }
    }
  }

  a {
    color: #444;
    display: block;
    padding: 7px 15px;

    .textUnderlineSwitch;
  }

  ul {
    list-style-type: none;
    margin: 0;
  }

  li {
    margin: 0 0 15px 0;

    &:before {
      display: none;
    }

    &:last-child {
      margin-bottom: 0;
    }

    a.vrtx-marked {
      font-weight: bold;
      background-color: #ebedfc;
    }
  }
}

#responsive-submenu {
  display: none;
  .mixin-small;
}

// TODO: cleanup

#left-main.hidden {
  display: block;
}

@media @desktopSmallOnlyBreakpoint {
  #vrtx-frontpage #responsive-submenu {
    margin-left: 0;
    margin-right: 0;
  }
  #responsive-submenu {
    + #vrtx-person-position,
    + * {
      margin-top: 0;
    }
  }
}

@media @desktopSmallBreakpoint {
  #left-menu-same-level-folders,
  #left-main {
    display: none;
  }
  #responsive-submenu {
    display: block;
    margin: 40px 0 45px;

    .vrtx-ancestor {
      display: none;
    }
    .left-menu-child-selected {
      li {
        margin-left: 0;
        &:before {
          display: none;
        }
      }
      > a {
        display: none;
      }
    }

    #toggle-responsive-submenu {
      border: solid 1px #a7a9ac;
      padding: 20px 20px 15px 20px;
      background: #e7e9ed;
      display: block;
      text-decoration: none;

      &:after {
        content: "";
        background: url("@{imagePath}arrows/arrow-thin-down.svg") no-repeat 0 0;
        background-size: auto 100%;
        float: right;
        display: inline-block;
        width: .9em;
        height: 1.1em;
      }

      .textUnderlineSwitch;
    }
    > ul,
    .vrtx-subfolder-menu {
      display: none;
      padding: 10px 20px 0 20px;
      background-color: #e7e9ed;

      li {
        margin-bottom: 15px;
      }

      &:not(.vrtx-subfolder-menu) > li,
      > ul > li {
        margin-left: 0;
        &:before {
          display: none;
        }
        li {
          &:before {
            content: '\25A0';
          }
        }
        a {
          .textUnderlineSwitch;

          &.vrtx-marked {
            font-weight: bold;
            &:before {
              content: '';
              height: 1.3em;
              width: 10px;
              border-left: 2px solid #000;
              display: inline-block;
              position: relative;
              bottom: -.3em;
            }
          }
        }
      }
    }

    > ul {
      margin: 0;
      padding-bottom: 10px;
    }

    &.expanded {
      #toggle-responsive-submenu {
        background-color: #fff;
        &:after {
          .transform(rotate(180deg))
        }
      }
      > ul,
      .vrtx-subfolder-menu {
        display: block;
      }
    }
  }
}

@media @responsiveBreakpoint {
  #vrtx-frontpage #breadcrumbs {
    padding: 0 15px;
  }

  #breadcrumbs {
    .vrtx-breadcrumb-level:not(.vrtx-breadcrumb-before-active) {
      display: none;
    }
  }
}
