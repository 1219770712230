#uio-research-group {

    #main h1 .vrtx-short-title{
        font-size: 5rem;
    }
    #hdr{
        display: flex;
        gap: 20px;
      }
    
    .related-content, .article-body{
        max-width: 690px;
    }
}